const data = [
  {
    sectionLabel: `Katı, sıvı ve sprey kalıp ayırıcılar, kalıp hazırlığı için özel ürünler`,
    tabs: [
      {
        label: "YCL",
        accordions: [
          {
            title: "RTM Elyafları",
            rows: [
              {
                name: "YCL RTM Elyafı 300 * 180 * 300",
                description: "163 cm",
              },
              {
                name: "YCL RTM Elyafı 300 * 250 * 300",
                description: "163 cm",
              },
              {
                name: "YCL RTM Elyafı 450 * 180 * 450",
                description: "163 cm",
              },
              {
                name: "YCL RTM Elyafı 450 * 250 * 450",
                description: "163 cm",
              },
              {
                name: "YCL RTM Elyafı 600 * 180 * 600",
                description: "163 cm",
              },
              {
                name: "YCL RTM Elyafı 600 * 250 * 600",
                description: "163 cm",
              },
              {
                name: "YCL RTM Elyafı 750 * 250 * 750",
                description: "163 cm",
              },
              {
                name: "YCL RTM Elyafı 900 * 180 * 900",
                description: "163 cm",
              },
              {
                name: "YCL RTM Elyafı 900 * 250 * 900",
                description: "163 cm",
              },
            ],
          },
          {
            title: "Toz Bağlayıcı Keçe Elyaflar",
            rows: [
              {
                name: "YCL Keçe 300 g/m toz bağlayıcı",
                description: "",
              },
              {
                name: "YCL Keçe 450 g/m toz bağlayıcı",
                description: "",
              },
              {
                name: "YCL Keçe 600 g/m toz bağlayıcı",
                description: "",
              },
            ],
          },

          {
            title: "Sıvı Bağlayıcı Keçe Elyaflar",
            rows: [
              {
                name: "YCL Keçe 300 g/m sıvı bağlayıcı",
                description: "",
              },
              {
                name: "YCL Keçe 450 g/m sıvı bağlayıcı",
                description: "",
              },
              {
                name: "YCL Keçe 600 g/m sıvı bağlayıcı",
                description: "",
              },
            ],
          },

          {
            title: "Sıvı Bağlayıcı Keçe Elyaflar",
            rows: [
              {
                name: "YCL Keçe 300 g/m sıvı bağlayıcı",
                description: "",
              },
              {
                name: "YCL Keçe 450 g/m sıvı bağlayıcı",
                description: "",
              },
              {
                name: "YCL Keçe 600 g/m sıvı bağlayıcı",
                description: "",
              },
            ],
          },

          {
            title: "Dokuma Ürünler",
            rows: [
              {
                name: "YCL Örgü 200 g/m Dokuma 0/90",
                description: "",
              },
              {
                name: "YCL Örgü 300 g/m Dokuma 0/90",
                description: "",
              },
              {
                name: "YCL Örgü 500 g/m Dokuma 0/90",
                description: "",
              },
              {
                name: "YCL Örgü 800 g/m Dokuma 0/90",
                description: "",
              },
            ],
          },

          {
            title: "Alkali Dayanımlı Elyaflar",
            rows: [
              {
                name: "YCL AR roving",
                description: "",
              },
              {
                name: "YCL AR kırpıntı",
                description: "",
              },
            ],
          },

          {
            title: "Yüzey Tülü",
            rows: [
              {
                name: "YCL Yüzey Tülü J-M 25 g/m",
                description: "",
              },
            ],
          },
        ],
      },

      {
        label: "Lantor",
        accordions: [
          {
            title: "Lantor Coremat Xi",
            rows: [
              { name: "Lantor Coremat Xi 1 mm", description: "" },
              { name: "Lantor Coremat Xi 2 mm", description: "" },
              { name: "Lantor Coremat Xi 3 mm", description: "" },
              { name: "Lantor Coremat Xi 4 mm", description: "" },
              { name: "Lantor Coremat Xi 5 mm", description: "" },
            ],
          },
          {
            title: "Lantor Coremat XM",
            rows: [
              { name: "Lantor Coremat XM 2 mm", description: "" },
              { name: "Lantor Coremat XM 3 mm", description: "" },
              { name: "Lantor Coremat XM 4 mm", description: "" },
              { name: "Lantor Coremat XM 10 mm", description: "" },
            ],
          },
          {
            title: "Lantor Soric LRC",
            rows: [
              { name: "Lantor Soric LRC 1,5 mm", description: "" },
              { name: "Lantor Soric LRC 2 mm", description: "" },
              { name: "Lantor Soric LRC 3 mm", description: "" },
            ],
          },
          {
            title: "Lantor Soric SF",
            rows: [
              { name: "Lantor Soric SF 2 mm", description: "" },
              { name: "Lantor Soric SF 3 mm", description: "" },
            ],
          },
          {
            title: "Lantor Soric TF",
            rows: [
              { name: "Lantor Soric TF 1,5 mm", description: "" },
              { name: "Lantor Soric TF 2 mm", description: "" },
              { name: "Lantor Soric TF 3 mm", description: "" },
            ],
          },
          {
            title: "Lantor Soric XF",
            rows: [
              { name: "Lantor Soric XF 2 mm", description: "" },
              { name: "Lantor Soric XF 3 mm", description: "" },
              { name: "Lantor Soric XF 4 mm", description: "" },
              { name: "Lantor Soric XF 5 mm", description: "" },
              { name: "Lantor Soric XF 6 mm", description: "" },
            ],
          },
          {
            title: "Lantor Finishmat",
            rows: [{ name: "Lantor Finishmat D 7760", description: "" }],
          },
          {
            title: "Condenstop",
            rows: [
              { name: "Condenstop CCM", description: "" },
              { name: "Condenstop CCM UT", description: "" },
            ],
          },
        ],
      },

      {
        label: "Metyx",
        accordions: [
          {
            title: "Genel Liste",
            rows: [
              { name: "Multiaksiyal elyaflar", description: "" },
              { name: "PVC/PU", description: "" },
            ],
          },
        ],
      },

      {
        label: "Şişecam",
        accordions: [
          {
            title: "Toz Bağlayıcı Keçe Elyaflar",
            rows: [
              { name: "MAT1(M) 300 g/m (100 cm)", description: "" },
              { name: "MAT1(M) 450 g/m (100 cm)", description: "" },
              { name: "MAT1(M) 600 g/m (100 cm)", description: "" },
              { name: "MAT-8 300 g/m (100 cm)", description: "" },
              { name: "MAT-8 450 g/m (100 cm)", description: "" },
              { name: "MAT-8 600 g/m (100 cm)", description: "" },
            ],
          },
          {
            title: "Sıvı Bağlayıcı Keçe Elyaflar",
            rows: [
              { name: "E-MAT 300 g/m (100 cm)", description: "" },
              { name: "E-MAT 450 g/m (100 cm)", description: "" },
              { name: "E-MAT 600 g/m (100 cm)", description: "" },
            ],
          },
          {
            title: "Standart Dışı B-GRADE Keçeler",
            rows: [
              { name: "B-GRADE (450 g - 600 g)", description: "" },
              { name: "B-GRADE (300 g - 375 g)", description: "" },
              { name: "Parça Keçe", description: "" },
            ],
          },
          {
            title: "Tek Uçlu Pultrüzyon ve Elyaf Sarma Fitilleri",
            rows: [
              { name: "FWR6-600", description: "" },
              { name: "FWR6-1200", description: "" },
              { name: "FWR6-2400", description: "" },
            ],
          },
          {
            title: "Püskürtme Fitilleri",
            rows: [{ name: "KCR2(M)-2400", description: "" }],
          },
          {
            title: "Boru Uygulaması İçin Kırpılabilir Fitiller",
            rows: [{ name: "KCR5-2400", description: "" }],
          },
          {
            title: "SMC Fitilleri",
            rows: [{ name: "SMC3-2400", description: "" }],
          },
          {
            title: "Kırpılmış Demetler",
            rows: [
              { name: "BMC1-06", description: "" },
              { name: "DE1-6/9/12", description: "Su bazlı prosesler için" },
              { name: "PH2-3/4,5", description: "Fenolik balata üretimi için" },
            ],
          },
        ],
      },
    ],
  },
];

export default data;
