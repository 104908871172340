import React from "react";
import { motion } from "framer-motion";

const VideoComponent = ({ videoUrl, description }) => {
  return (
    <div className="max-w-screen-lg mx-auto my-28 px-4">
      <motion.div
        className="relative overflow-hidden bg-gray-900 rounded-lg shadow-lg flex flex-col sm:flex-row"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
      
        <div className="relative w-full sm:w-full">
          <iframe
            className="w-full content-stretch h-[15rem] sm:h-[40rem]"
            src={videoUrl}
            title="Video Player"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      
      </motion.div>
    </div>
  );
};

export default VideoComponent;
