const data = [
    {
      sectionLabel: "Boytek Ürünleri",
      tabs: [
        {
          label: "Polyester Reçineler",
          accordions: [
            {
                title: "Genel amaçlı CTP",
                rows: [
                  { name: "BRE 310", type: "Ortoftalik", description: "Orta reaktivite, yüksek HDT, ince laminat" },
                  { name: "BRE 314 EE", type: "Ortoftalik", description: "Genel amaçlı, endüstriyel parçalar" },
                  { name: "BRE 318", type: "Ortoftalik", description: "Kalın laminat, kontrollü kürleşme" },
                  { name: "BRE 330 SA", type: "Ortoftalik", description: "Gıda ile temasa uygunluğu onaylanmış" },
                  { name: "BRE 330 SA Beyaz", type: "Ortoftalik", description: "Gıda ile temasa uygunluğu onaylanmış, hidrolitik dayanımlı, su parklarına uygun" },
                  { name: "BRE 365", type: "Ortoftalik", description: "Endüstriyel parçalar, denizcilik, otomotiv, mükemmel mekanik özellikler" },
                  { name: "BRE 452", type: "Ortoftalik", description: "Genel amaçlı, endüstriyel parçalar" },
                  { name: "BRE 455", type: "Ortoftalik", description: "Otomotiv, denizcilik için hızlı kürleşme" }
                ]
            },
            {
                title: "DCPD bazlı CTP",
                rows: [
                  { name: "BRE 3021", type: "DCPD", description: "Düşük stiren, düşük ekzoterm" },
                  { name: "BRE 3030", type: "DCPD", description: "Genel amaçlı" },
                  { name: "BRE 4560", type: "DCPD", description: "Düşük ekzoterm, düşük stiren, düşük çekme, hızlı kürleşme" }
                ]
            },
            {
                title: "Sürekli laminat",
                rows: [
                  { name: "BRE 312", type: "Ortoftalik", description: "Eternit/oluklu levha tipi, yüksek HDT, dış şartlara yüksek dayanım" },
                  { name: "BRE 314 ET", type: "Ortoftalik", description: "Eternit/oluklu levha tipi, yüksek ışık geçirgenliği" },
                  { name: "BRE 360", type: "Ortoftalik", description: "Düz levha ve oluklu levha, yüksek ışık geçirgenliği" },
                  { name: "BRE 362", type: "DCPD", description: "Üstün yüzey kalitesine sahip düz levha üretimi" },
                  { name: "BRE 324", type: "", description: "Düz levha tipi dolgulu uygulamalara uygun" },
                  { name: "BRE 375", type: "", description: "Düz ve oluklu levha tipi" }
                ]
            },
            {
                title: "Döküm (cast)",
                rows: [
                  { name: "Yeni! - BRE 320 AX", type: "Ortoftalik", description: "Mükemmel şeffaf döküm, şeffaf heykel, hediyelik eşya vb." },
                  { name: "BRE 320K", type: "Ortoftalik", description: "Mükemmel renk, suni mermer, oniks" },
                  { name: "BRE 323KX", type: "Ortoftalik", description: "Mükemmel renk, düşük viskozite, yüksek dolgu kapasitesi" },
                  { name: "BRE 325", type: "", description: "Jelkotsuz döküm" },
                  { name: "BRE 450", type: "Ortoftalik", description: "Genel amaçlı döküm, mutfak tezgahları, lavabo" }
                ]
            },
            {
                title: "Düğme tipi",
                rows: [
                  { name: "BRE 320A", type: "Ortoftalik", description: "Merdane döküm, mükemmel şeffaflık, kolay işlenebilme" },
                  { name: "BRE 320 AÇ", type: "Ortoftalik", description: "Çubuk tipi tiksotropik düğme polyesteri" },
                  { name: "BRE 320B", type: "Ortoftalik", description: "Merdane tipi, standart uygulama" },
                  { name: "Yeni! - BRE 823", type: "Ortoftalik", description: "Kırılmayan düğme için esnek tip" }
                ]
            },
            {
                title: "Plastifiyan",
                rows: [
                  { name: "BRE 817", type: "Ortoftalik", description: "Esnek reçine" },
                  { name: "BRE 818", type: "İzoftalik", description: "Esnek reçine" },
                  { name: "BRE 820 Liner", type: "Özel", description: "Santrifüj yöntemi, yüksek esneklik" }
                ]
            },
            {
                title: "SMC, BMC",
                rows: [
                  { name: "BRE 301", type: "Ortoftalik", description: "Hızlı kıvamlaşan SMC-BMC reçinesi" },
                  { name: "BRE 309", type: "Ortoftalik", description: "SMC, kıvamlaşmaya uygun, LS uygulamaları, yüksek reaktivite" },
                  { name: "BRE AS33", type: "PS", description: "SMC-BMC uygulamaları, LS katkısı" },
                  { name: "BRE XP401", type: "Maleik", description: "SMC-BMC, LS-LP uygulamaları, mükemmel yüzey profili" }
                ]
            },
            {
                title: "Pultrüzyon",
                rows: [
                  { name: "BRE 305", type: "İzoftalik/NPG", description: "Kimyasal dayanımlı pultrüzyon reçinesi" },
                  { name: "BRE 307", type: "İzoftalik", description: "Esnek pultrüzyon" },
                  { name: "BRE XP173", type: "İzoftalik", description: "Pultrüzyon, yüksek mekanik özellikler" },
                  { name: "BRE XP175", type: "DCPD", description: "Pultrüzyon, yüksek reaktivite, iyi yüzey profili" },
                  { name: "BRE XP601", type: "Ortoftalik", description: "Standart pultrüzyon uygulamaları" }
                ]
            },
            {
                title: "Kimyasal maddelere dayanıklı",
                rows: [
                  { name: "BRE 311", type: "İzoftalik/NPG", description: "Kimyasal dayanımlı, denizcilik, depolama tankları" },
                  { name: "BRE 315", type: "İzoftalik/Özel", description: "Yüksek kimyasal dayanım, depolama tankları, yüksek HDT" },
                  { name: "Yeni! - BRE 538", type: "İzoftalik", description: "Kimyasal dayanım, denizcilik, depolama tankları" }
                ]
            },
            {
                title: "Alev ilerletmeyen",
                rows: [
                  { name: "BRE 181", type: "Halojensiz", description: "Alev ilerletmeyen halojensiz" },
                  { name: "BRE 326", type: "Kimyasal dayanımlı şeffaf", description: "Kimyasal dayanımlı şeffaf" },
                  { name: "BRE 327", type: "Halojenli", description: "Işık geçirgen, eternit/oluklu levha tipi" },
                  { name: "BRE 328", type: "Halojenli", description: "Dolgulu, UL 94 V-0, yüksek derecede alev ilerletmeyen" },
                  { name: "BRE 329", type: "Halojenli", description: "Dolgulu, UL 94 V-0, orta derecede alev ilerletmeyen" }
                ]
            },
            {
                title: "Mobilya tipi",
                rows: [
                  { name: "BRE 120", type: "Ortoftalik", description: "Mobilya kaplama, çok düşük viskozite" },
                  { name: "BRE 121", type: "Son kat parlak", description: "Son kat parlak" },
                  { name: "BRE 122", type: "Özel", description: "Polyester dolgu verniği" },
                  { name: "BRE 122 B", type: "Özel", description: "Beyaz polyester dolgu verniği" },
                  { name: "BRE 123", type: "Son kat tam mat", description: "Son kat tam mat" }
                ]
            },
            {
                title: "Macun ve yapıştırıcı",
                rows: [
                  { name: "BRE 3010", type: "DCPD", description: "Mermer tamir macunu üretimi, iyi yapışma, uzun ömür" },
                  { name: "BRE 3020", type: "DCPD", description: "Otomotiv tamir macunu üretimi, uzun ömür, esneklik" },
                  { name: "BRE 3023", type: "DCPD", description: "Oto tamir macunu üretimi, galvanize mükemmel yapışma" }
                ]
            },
            {
                title: "Akrilik",
                rows: [
                  { name: "BRE 313RA", type: "Ortoftalik", description: "Akrilik takviye, MEK Peroksit indikatörlü" },
                  { name: "BRE 414", type: "Ortoftalik", description: "Akrilik takviye, dolgulu, MEK Peroksit indikatörlü" },
                  { name: "BRE 415", type: "Ortoftalik", description: "Akrilik takviye, yüksek dolgu oranı, MEK Peroksit indikatörlü" },
                  { name: "Yeni! - BRE 416", type: "Ortoftalik", description: "ABS ve akrilik levhaya mükemmel yapışma, dolgulu" },
                  { name: "BRE 513", type: "Ortoftalik", description: "Akrilik takviye, dolgusuz, yüksek dolgu kaldırma kapasitesi, MEK Peroksit indikatörlü" },
                  { name: "Yeni! - BRE 660", type: "Ortoftalik", description: "ABS ve akrilik levhaya mükemmel yapışma, dolgusuz" }
                ]
            },
            {
                title: "RTM, İnfüzyon",
                rows: [
                  { name: "BRE 322", type: "Ortoftalik", description: "Standart RTM reçinesi" },
                  { name: "BRE 3024", type: "Ortoftalik/DCPD", description: "İyi elyaf ıslatımı, RTM, infüzyon, denizcilik, otomotiv" },
                  { name: "Yeni! - BRE 4982", type: "DCPD", description: "Hızlı kürleşme, RTM, düşük viskozite" }
                ]
            },
            {
                title: "Sandviç panel",
                rows: [
                  { name: "BRE 400", type: "Özel", description: "Sandviç paneller, stiren köpüğünü (styrofoam) etkilemez" }
                ]
            },
            {
                title: "Tam liste",
                rows: [
                  { name: "DERAKANE® Momentum 411-350", type: "Epoksi Vinilester", description: "Epoksi Vinilester reçinesi" },
                  { name: "DERAKANE® Momentum 470-300", type: "Novolac Vinilester", description: "Novolac Vinilester reçinesi" },
                  { name: "BVE 780", type: "Vinilester", description: "Bisfenol A Epoksi Vinil Ester" },
                  { name: "BVE 782", type: "Vinilester", description: "İnfüzyon için Bisfenol A Epoksi Vinil Ester, düşük viskozite" }
                ]
            },
            {
                title: "Genel amaçlı",
                rows: [
                  { name: "BRE 333 F/P", type: "Ortoftalik", description: "Genel amaçlı endüstriyel" },
                  { name: "BRE 333 F/P Beyaz", type: "Ortoftalik", description: "Genel amaçlı, endüstriyel, beyaz" },
                  { name: "BRE 334 F/P", type: "Ortoftalik", description: "Dış şartlara dayanımı arttırılmış, genel amaçlı endüstriyel" },
                  { name: "BRE 334 F/P Beyaz", type: "Ortoftalik", description: "Dış şartlara dayanımı arttırılmış, genel amaçlı endüstriyel, beyaz" }
                ]
            },
            {
                title: "Yüksek dayanımlı",
                rows: [
                  { name: "BRE 315 F/P", type: "İzoftalik/Özel", description: "Yüksek HDT, mükemmel hidrolitik, dış şart ve kimyasal dayanımı" },
                  { name: "BRE 335 F/P", type: "İzoftalik/NPG", description: "Mükemmel hidrolitik ve dış şart dayanımı" },
                  { name: "BRE 335 F/P Beyaz", type: "İzoftalik/NPG", description: "Mükemmel hidrolitik ve dış şart dayanımı, beyaz" },
                  { name: "BRE 336 F/P", type: "İzoftalik/NPG", description: "Akrilik modifiyeli, mükemmel şeffaflık, üstün dayanım" },
                  { name: "BRE 328 F", type: "Alev ilerletmeyen", description: "Şeffaf" },
                  { name: "BRE 328 FD", type: "Alev ilerletmeyen", description: "Dolgulu" }
                ]
            },
            {
                title: "Parlak",
                rows: [
                  { name: "BRE 125 P", description: "Parlak son kat" }
                ]
            },
            {
                title: "Yüksek performans",
                rows: [
                  { name: "Yeni - HYDRATEK EZ", type: "İzoftalik/NPG", description: "Marin" },
                  { name: "Yeni - HYDRATEK PLUS", type: "İzoftalik/NPG", description: "Yüksek performanslı" },
                  { name: "PROMAX 9211", type: "İzoftalik/NPG", description: "Yüksek performanslı" },
                  { name: "PROMAX 3541", type: "İzoftalik", description: "Yüksek performanslı" },
                  { name: "Yeni - RAPITEK", type: "Ortoftalik", description: "Endüstriyel" }
                ]
            },
            {
                title: "Zımparalanabilir",
                rows: [
                  { name: "BRE 337 F/P", type: "Ortoftalik", description: "Zımparalanabilir, gri renk, pinholsüz uygulama" },
                  { name: "Yeni - BRE 338 F/P", type: "Ortoftalik", description: "Zımparalanabilir, gri, esnek, çatlama yapmaz" },
                  { name: "Yeni - BRE 920 F", type: "Özel", description: "Dış şartlarda bekleyen kalıpları koruma amaçlı" }
                ]
            },
            {
                title: "Vakslar",
                rows: [
                  { name: "A VAKS®", description: "Kalıp ayırıcı" }
                ]
            },
            {
                title: "Sıvı kalıp ayırıcılar",
                rows: [
                  { name: "PVA", description: "Sıvı kalıp ayırıcı" },
                  { name: "PVA Kırmızı", description: "Sıvı kalıp ayırıcı, kırmızı" }
                ]
            },
            {
                title: "Sprey kalıp ayırıcılar",
                rows: [
                  { name: "A VAKS Sprey", description: "Sprey kalıp ayırıcı" }
                ]
            },
            {
                title: "Kalıp sistemleri",
                rows: [
                  { name: "Yeni - BRE 910", description: "Sıfır çekmeli polyester" },
                  { name: "Yeni - BRE 390 F/P", description: "Özel kalıp jelkotu" },
                  { name: "Yeni - BRE 790 T", description: "Skin coat reçinesi" }
                ]
            },
            {
                title: "Kalıp temizleyiciler",
                rows: [
                  { name: "Yeni - Kalıp temizleyici", description: "" }
                ]
            },
            {
                title: "Örtücü renkler",
                rows: [
                  { name: "BRE 035", description: "Açık kahverengi" },
                  { name: "BRE 037", description: "Koyu kahverengi" },
                  { name: "BRE 040", description: "Helio yeşil" },
                  { name: "BRE 052", description: "Açık sarı" },
                  { name: "BRE 055", description: "Koyu sarı" },
                  { name: "BRE 057", description: "Oksit sarı" },
                  { name: "BRE 058", description: "Açık turuncu" },
                  { name: "BRE 059", description: "Koyu turuncu" },
                  { name: "BRE 060", description: "Siyah" },
                  { name: "BRE 070", description: "Violet" },
                  { name: "BRE 075", description: "Helio mavi" },
                  { name: "BRE 082", description: "Kırmızı" },
                  { name: "BRE 090", description: "Beyaz" }
                ]
            },
            {
                title: "Şeffaf renkler",
                rows: [
                  { name: "BRE 030", description: "Kızıl kahverengi" },
                  { name: "BRE 040", description: "Helio yeşil" },
                  { name: "BRE 070", description: "Violet" },
                  { name: "BRE 073", description: "Lacivert" },
                  { name: "BRE 075", description: "Helio mavi" },
                  { name: "BRE 081", description: "Açık sarı" },
                  { name: "BRE 083", description: "Borda" },
                  { name: "BRE 500", description: "Koyu sarı" },
                  { name: "BRE 507", description: "Koyu sarı" },
                  { name: "BRE 540", description: "Turuncu" },
                  { name: "BRE 855", description: "Kırmızı" },
                  { name: "RAL ve NCS renkler için en az 1 kg özel üretim mümkündür.", description: "" }
                ]
            },
            {
                title: "Elyaf, dokuma grubu",
                rows: [
                  { name: "VETROTEX 450//600 gr/m2", description: "Cam liflerinden keçe" },
                  { name: "VETROTEX 300 gr/m2", description: "Cam liflerinden keçe" },
                  { name: "İnce lifli elyaf 225 gr/m2", description: "Cam liflerinden keçe" },
                  { name: "Dokuma 300 gr/m2", description: "" },
                  { name: "Dokuma 500 - 800 gr/m2", description: "" },
                  { name: "P207 roving S/P 224", description: "" },
                  { name: "P316 BMC fitili 6-12 mm", description: "" },
                  { name: "P204 SMC Roving 2400 tex", description: "" },
                  { name: "U850 RTM elyaf 300-450 gr/m2", description: "" },
                  { name: "Kortel 30 gr/m2", description: "Yüzey tülü" },
                  { name: "Multicore S300/v180", description: "RTM elyaf" },
                  { name: "Multicore S450/v180", description: "RTM elyaf" },
                  { name: "Multicore S600/v180", description: "RTM elyaf" }
                ]
              }

              
           ]
        },



        {
          label: "Vinilester Reçineler",
          accordions: [
            {
                "title": "Tam liste - Tıklayınız",
                "rows": [
                  { "name": "DERAKANE® Momentum 411-350", "type": "Epoksi Vinilester reçinesi", "description": "" },
                  { "name": "DERAKANE® Momentum 470-300", "type": "Novolac Vinilester reçinesi", "description": "" },
                  { "name": "BVE 780", "type": "Vinilester", "description": "Bisfenol A Epoksi Vinil Ester" },
                  { "name": "BVE 782", "type": "Vinilester", "description": "İnfüzyon için Bisfenol A Epoksi Vinil Ester, düşük viskozite" }
                ]
              }
              
          ]
        },


        {
            label: "Kimyasallar",
            accordions: [
              
            ]
          },


          {
            label: "Kimyasallar",
            accordions: [
              
            ]
          },
      ]
    },
  ];
  
  export default data;
  