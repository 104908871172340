const data = [
    {
      sectionLabel: "Fırça, kama, rulo ürün seçenekleri",
      tabs: [
        {
          label: "Boytek",
          accordions: [
            {
                "title": "Islatma ruloları",
                "rows": [
                  {
                    "name": "Pösteki rulo 10 cm"
                  },
                  {
                    "name": "Pösteki rulo yedek 10 cm"
                  },
                  {
                    "name": "Pösteki rulo 20 cm"
                  },
                  {
                    "name": "Pösteki rulo yedek 20 cm"
                  },
                  {
                    "name": "Parmak rulo"
                  }
                ]
              },
              {
                "title": "Hava alma ruloları - Plastik",
                "rows": [
                  {
                    "name": "Plastik köşe rulo 40-15"
                  },
                  {
                    "name": "Plastik köşe rulo 60-15"
                  },
                  {
                    "name": "Plastik rulo rady. dış 10-40"
                  },
                  {
                    "name": "Plastik rulo rady. dış 10-80"
                  },
                  {
                    "name": "Plastik rulo rady. dış 15-40"
                  },
                  {
                    "name": "Plastik rulo rady. dış 15-80"
                  },
                  {
                    "name": "Plastik rulo rady. dış 22-40"
                  },
                  {
                    "name": "Plastik rulo rady. dış 22-80"
                  },
                  {
                    "name": "Plastik rulo rady. dış 22-120"
                  },
                  {
                    "name": "Plastik rulo rady. dış 30-40"
                  },
                  {
                    "name": "Plastik rulo rady. dış 30-80"
                  },
                  {
                    "name": "Plastik rulo rady. dış 30-120"
                  }
                ]
              },
              {
                "title": "Hava alma ruloları - Alüminyum",
                "rows": [
                  {
                    "name": "Alüminyum rulo 8 cm"
                  },
                  {
                    "name": "Alüminyum rulo 13 cm"
                  },
                  {
                    "name": "Alüminyum köşe rulo 40-15"
                  },
                  {
                    "name": "Alüminyum rulo rady. dış 30-120"
                  },
                  {
                    "name": "Alüminyum rulo rady. dış 8-40"
                  },
                  {
                    "name": "Alüminyum rulo rady. dış 8-80"
                  },
                  {
                    "name": "Alüminyum rulo rady. dış 13-40"
                  },
                  {
                    "name": "Alüminyum rulo rady. dış 13-80"
                  },
                  {
                    "name": "Alüminyum rulo rady. dış 22-40"
                  },
                  {
                    "name": "Alüminyum rulo rady. dış 22-80"
                  },
                  {
                    "name": "Alüminyum rulo rady. dış 22-120"
                  },
                  {
                    "name": "Alüminyum rulo rady. dış 22-200"
                  },
                  {
                    "name": "Alüminyum rulo rady. dış 30-40"
                  },
                  {
                    "name": "Alüminyum rulo rady. dış 30-80"
                  }
                ]
              },
              {
                "title": "Fırçalar",
                "rows": [
                  {
                    "name": "Kıl fırça 1 Numara"
                  },
                  {
                    "name": "Kıl fırça 2 Numara"
                  },
                  {
                    "name": "Kıl fırça 2,5 Numara"
                  },
                  {
                    "name": "Kıl fırça 3 Numara"
                  },
                  {
                    "name": "Kıl fırça 3,5 Numara"
                  },
                  {
                    "name": "Kıl fırça 4 Numara"
                  }
                ]
              },
              {
                "title": "Kamalar - Plastik",
                "rows": [
                  {
                    "name": "Plastik Kama - küçük 100*40*20"
                  },
                  {
                    "name": "Plastik Kama - orta 150*55*20"
                  },
                  {
                    "name": "Plastik Kama - büyük 250*75*30"
                  }
                ]
              },
              {
                "title": "Kamalar - Kestamid",
                "rows": [
                  {
                    "name": "Kestamid Kama - küçük 100*40*20"
                  },
                  {
                    "name": "Kestamid Kama - orta 150*55*20"
                  },
                  {
                    "name": "Kestamid Kama - büyük 250*75*30"
                  }
                ]
              }

            

            
            
          ]
        },






       
      ]
    },







  ];
  
  export default data;
  