const tables = [
  {
    id: 1,
    title: "Polipol 3401",
    data: [
      ["ÖZELLİK", "AÇIKLAMA"],
      ["Ürün", "Polipol 3401"],
      ["Tanım", "CTP tipi polyester"],
      ["Kimyasal Yapı", "Ortoftalik (Orto)"],
      ["Tiksotropi", "-"],
      ["Viskozite (cps)", "500 - 600"],
      ["Monomer Oranı (%)", "36 - 39"],
      ["Eğilme Dayanımı (MPa)", "134"],
      ["Tmax (°C)", "170"],
      ["Barcol Sertliği (934 Barcol)", "40"],
      ["Hacimsel Çekme (%)", "7,4"],
      ["Çekme Dayanımı (MPa)", "71"],
      ["Jel Süresi (min)", "12 - 14"],
      ["Kopma Uzama Çekme (%)", "2,6"],
      ["HDT [ 0,45 MPa ] (°C)", "	66"],
      ["Not", "OT1, OT3"],
      ["Önemli Özellikler", `Hızlı ve kolay elyaf ıslatma,Düşük hacimsel çekme, Optimum yat performans oranı, Lloyd’s Register onaylı`],
      ["Kullanım Alanları", "Tamponlar, çamurluklar, dolaplar, havuz filtreleri, otomotiv parçaları gibi endüstriyel CTP parçalar"],
     

    ],
  },
  {
    id: 2,
    title: "Polipol 3401-A",
    data: [
      ["ÖZELLİK", "AÇIKLAMA"],
      ["Ürün", "Polipol 3401-A"],
      ["Tanım", "CTP tipi hızlandırıcılı polyester"],
      ["Kimyasal Yapı", "Ortoftalik (Orto)"],
      ["Tiksotropi", "-"],
      ["Viskozite (cps)", "500 - 600"],
      ["Monomer Oranı (%)", "36 - 39"],
      ["Eğilme Dayanımı (MPa)", "134"],
      ["Tmax (°C)", "140"],
      ["Barcol Sertliği (934 Barcol)", "40"],
      ["Hacimsel Çekme (%)", "7,4"],
      ["Çekme Dayanımı (MPa)", "71"],
      ["Jel Süresi (min)", "26 - 28"],
      ["Kopma Uzama Çekme (%)", "2,6"],
      ["HDT [ 0,45 MPa ] (°C)", "66"],
      ["Not", "OT1, OT4"],
      ["Önemli Özellikler", "Optimum hızlandırıcı konfigürasyonu, Hızlı ve kolay elyaf ıslatma, Düşük hacimsel çekme, Lloyd’s Register onaylı"],
      ["Kullanım Alanları", "Tamponlar, çamurluklar, dolaplar, havuz filtreleri, otomotiv parçaları gibi endüstriyel CTP parçalar"]
    ]
  },
  
  
  {
    id: 3,
    title: "Polipol 3401-TA-H40",
    data: [
      ["ÖZELLİK", "AÇIKLAMA"],
      ["Ürün", "Polipol 3401-TA-H40"],
      ["Tanım", "CTP tipi tiksotropik hızlandırıcılı polyester"],
      ["Kimyasal Yapı", "Ortoftalik (Orto)"],
      ["Tiksotropi", "Tiksotropik"],
      ["Viskozite (cps)", "500 - 600"],
      ["Monomer Oranı (%)", "43 - 47"],
      ["Eğilme Dayanımı (MPa)", "134"],
      ["Tmax (°C)", "140"],
      ["Barcol Sertliği (934 Barcol)", "40"],
      ["Hacimsel Çekme (%)", "7,4"],
      ["Çekme Dayanımı (MPa)", "71"],
      ["Jel Süresi (min)", "38 - 42"],
      ["Kopma Uzama Çekme (%)", "2,6"],
      ["HDT [ 0,45 MPa ] (°C)", "66"],
      ["Not", "OT1, OT4"],
      ["Önemli Özellikler", "Dik yüzeylerde akma yapmaz, Hızlı ve kolay elyaf ıslatma, Parafinsiz, Peroksit indikatörü, Lloyd’s Register onaylı"],
      ["Kullanım Alanları", "Tamponlar, çamurluklar, dolaplar, havuz filtreleri, otomotiv parçaları gibi endüstriyel CTP parçalar"]
    ]
  },
  {
    id: 4,
    title: "Polipol 3401-TA-LSE-H40",
    data: [
      ["ÖZELLİK", "AÇIKLAMA"],
      ["Ürün", "Polipol 3401-TA-LSE-H40"],
      ["Tanım", "CTP tipi tiksotropik hızlandırıcılı LSE polyester"],
      ["Kimyasal Yapı", "Ortoftalik (Orto)"],
      ["Tiksotropi", "Tiksotropik"],
      ["Viskozite (cps)", "500 - 600"],
      ["Monomer Oranı (%)", "43 - 47"],
      ["Eğilme Dayanımı (MPa)", "134"],
      ["Tmax (°C)", "140"],
      ["Barcol Sertliği (934 Barcol)", "40"],
      ["Hacimsel Çekme (%)", "7,4"],
      ["Çekme Dayanımı (MPa)", "71"],
      ["Jel Süresi (min)", "38 - 42"],
      ["Kopma Uzama Çekme (%)", "2,6"],
      ["HDT [ 0,45 MPa ] (°C)", "66"],
      ["Not", "OT1, OT4"],
      ["Önemli Özellikler", "Düşük stiren emisyonu, Dik yüzeylerde akma yapmaz, Hızlı ve kolay elyaf ıslatma, Lloyd’s Register onaylı"],
      ["Kullanım Alanları", "Tamponlar, çamurluklar, dolaplar, havuz filtreleri, otomotiv parçaları gibi endüstriyel CTP parçalar"]
    ]
  },
  {
    id: 5,
    title: "Polipol 3401-TA-H20",
    data: [
      ["ÖZELLİK", "AÇIKLAMA"],
      ["Ürün", "Polipol 3401-TA-H20"],
      ["Tanım", "CTP tipi tiksotropik hızlandırıcılı polyester"],
      ["Kimyasal Yapı", "Ortoftalik (Orto)"],
      ["Tiksotropi", "Tiksotropik"],
      ["Viskozite (cps)", "500 - 600"],
      ["Monomer Oranı (%)", "43 - 47"],
      ["Eğilme Dayanımı (MPa)", "134"],
      ["Tmax (°C)", "140"],
      ["Barcol Sertliği (934 Barcol)", "40"],
      ["Hacimsel Çekme (%)", "7,4"],
      ["Çekme Dayanımı (MPa)", "71"],
      ["Jel Süresi (min)", "19 - 21"],
      ["Kopma Uzama Çekme (%)", "2,6"],
      ["HDT [ 0,45 MPa ] (°C)", "66"],
      ["Not", "OT1, OT4"],
      ["Önemli Özellikler", "Dik yüzeylerde akma yapmaz, Hızlı ve kolay elyaf ıslatma, Parafin içermez, Peroksit indikatörü, Lloyd’s Register onaylı"],
      ["Kullanım Alanları", "Tamponlar, çamurluklar, kabin, havuz filtreleri, otomotiv parçaları gibi endüstriyel CTP parçalar"]
    ]
  },
  {
    id: 6,
    title: "Polipol 3401-TA-LSE-H20",
    data: [
      ["ÖZELLİK", "AÇIKLAMA"],
      ["Ürün", "Polipol 3401-TA-LSE-H20"],
      ["Tanım", "CTP tipi tiksotropik hızlandırıcılı LSE polyester"],
      ["Kimyasal Yapı", "Ortoftalik (Orto)"],
      ["Tiksotropi", "Tiksotropik"],
      ["Viskozite (cps)", "500 - 600"],
      ["Monomer Oranı (%)", "43 - 47"],
      ["Eğilme Dayanımı (MPa)", "134"],
      ["Tmax (°C)", "140"],
      ["Barcol Sertliği (934 Barcol)", "40"],
      ["Hacimsel Çekme (%)", "7,4"],
      ["Çekme Dayanımı (MPa)", "71"],
      ["Jel Süresi (min)", "19 - 21"],
      ["Kopma Uzama Çekme (%)", "2,6"],
      ["HDT [ 0,45 MPa ] (°C)", "66"],
      ["Not", "OT1, OT4"],
      ["Önemli Özellikler", "Düşük stiren emisyonu, Dik yüzeylerde akma yapmaz, Hızlı ve kolay elyaf ıslatma, Lloyd’s Register onaylı"],
      ["Kullanım Alanları", "Tamponlar, çamurluklar, kabin, havuz filtreleri, otomotiv parçaları gibi endüstriyel CTP parçalar"]
    ]
  }
  
];

export default tables