const data = [
    {
      sectionLabel:
        "Monomer, solvent ve poliüretan ürünler",
      tabs: [
        {
          accordions:[
            {
              title: "İncelticiler, monomerler",
              rows: [
                {
                  name: "Stren Monomer Polyester İncelticisi",
                  description: ""
                },
                {
                  name: "Jelkot İncelticisi",
                  description: ""
                },
                {
                  name: "MMA",
                  description: ""
                }
              ]
            },
            {
              title: "Temizlik solventleri",
              rows: [
                {
                  name: "Aseton Polyester Temizleyici",
                  description: ""
                },
                {
                  name: "Selulozik Tiner",
                  description: ""
                },
                {
                  name: "Kalıp Temizleyici",
                  description: ""
                },
                {
                  name: "Etil Asetat",
                  description: ""
                },
                {
                  name: "Butil Glikol",
                  description: ""
                }
              ]
            },
            {
              title: "Poliüretanlar",
              rows: [
                {
                  name: "Poliol",
                  description: ""
                },
                {
                  name: "İzosiyanat-MDI",
                  description: ""
                }
              ]
            }
          ]
          
        },
     
      ],
    },
  ];
  
  export default data;
  