import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const MemberCard = ({ member, delay }) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5, delay }}
      className="bg-white shadow-lg rounded-lg p-4 flex flex-col items-center"
    >
      <img src={member.image} alt={member.name} className="w-72 h-72 rounded-lg mb-4 object-cover" />
      <h3 className="text-lg font-semibold">{member.name}</h3>
      <p className="text-sm text-gray-600">{member.title}</p> {/* Title for each member */}
      <p className="text-sm text-gray-600">{member.number}</p>
      <p className="text-sm text-gray-600">{member.number2}</p>
    </motion.div>
  );
};

export default MemberCard;
