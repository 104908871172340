import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import data from '../../components/datas/products/kalipurdata'; 
import SliderCards from "../../components/SliderCards";

export default function Kalipur() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [tabValues, setTabValues] = React.useState(data.map(() => 0));

  const handleTabChange = (index, newValue) => {
    setTabValues(prevTabValues => prevTabValues.map((value, i) => i === index ? newValue : value));
  };

  const renderAccordion = (accordions) => (
    accordions.map((accordion, index) => (
      <Accordion key={index} sx={{ maxWidth: '100%', height: '100%', width: '100%' , marginBottom: "2px" ,marginTop:"5px", backgroundColor:"#686D76" , color:"white", borderRadius:'2px'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: "400", alignItems:"center", justifyContent:"center", fontFamily: "Roboto" ,fontSize:"small" }}>
            {accordion.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table" sx={{backgroundColor:"#686D76" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{fontWeight:"bold", color:"#EEEEEE"}} >Ürün</TableCell>
                  <TableCell sx={{fontWeight:"bold",color:"#EEEEEE"}}>Türü</TableCell>
                  <TableCell sx={{fontWeight:"bold",color:"#EEEEEE"}}>Açıklama</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accordion.rows.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell sx={{color:"#EEEEEE",fontSize:"12px"}}>{row.name}</TableCell>
                    <TableCell sx={{color:"#EEEEEE",fontSize:"12px"}}>{row.type}</TableCell>
                    <TableCell sx={{color:"#EEEEEE",fontSize:"12px"}}>{row.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    ))
  );

  const renderSection = (section, sectionIndex) => (
    <Box key={sectionIndex} className="w-full p-4">
      {section.sectionLabel && (
        <Box className="flex flex-col items-center">
          <Typography variant="h3" className="mb-1 mt-2 font-semibold text-center">{section.sectionLabel}</Typography>
          <Box className="border-b border-gray-300">
            <Tabs
              value={tabValues[sectionIndex]}
              onChange={(event, newValue) => handleTabChange(sectionIndex, newValue)}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="tab example"
              sx={{ borderBottom: `1px solid ${theme.palette.divider}`, maxWidth: '100%' }}
              centered
            >
              {section.tabs.map((tab, tabIndex) => (
                <Tab
                  sx={{
                    fontSize: isMobile ? "11px" : "12px",
                    fontWeight: "bold",
                    minWidth: isMobile ? "auto" : "12px",
                    marginTop: 1,
                    fontFamily: "Roboto",
                    backgroundColor: "#EEEEEE",
                    padding: isMobile ? '6px' : '12px',
                  }}
                  label={tab.label}
                  key={tabIndex}
                />
              ))}
            </Tabs>
          </Box>
          {section.tabs.map((tab, tabIndex) => (
            <div role="tabpanel" hidden={tabValues[sectionIndex] !== tabIndex} key={tabIndex}>
              {tabValues[sectionIndex] === tabIndex && (
                <Box p={0} sx={{ width: isMobile ? '100%' : '25rem' }}>
                  {renderAccordion(tab.accordions)}
                </Box>
              )}
            </div>
          ))}
        </Box>
      )}
    </Box>
  );

  return (
    <div>
      <div className="p-4 md:p-8 overflow-y-hidden" style={{ width: '100%', maxWidth: 1600, margin: '0 auto' }}>
        <h1 className="bg-red-500 text-white h-auto px-6 py-2 rounded-2xl text-2xl md:text-3xl text-center flex items-center justify-center md:px-10 lg:px-20 xl:px-32">
        Kalıp Ürünleri
        </h1>
        <Box className="flex flex-col md:flex-row">
          {data.map((section, index) => (
            <Box key={index} className="flex-1 my-3 md:mr-4">
              {renderSection(section, index)}
            </Box>
          ))}
        </Box>
      </div>
  
      <div className='flex justify-center items-center '>
        <SliderCards/>
      </div>
    </div>
  );
}
