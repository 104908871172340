import React from 'react';
import MemberCard from '../components/MemberCard';

const About = () => {
  const teamData = [
    {
      members: [
        { id: 1, name: "Bilge Bıçakçıoğlu", number: "0530 246 48 07", number2: "0232 479 26 60 D 121", image: "/aboutus/yucel-kompozit-ekip-bilge-bicakcioglu-6446.jpg", title: "Genel Müdür" },
        { id: 2, name: "Murat Koç", number: "0532 281 24 22", number2: "0232 479 26 60 D 121", image: "/aboutus/yucel-kompozit-ekip-murat-koc-6472.jpg", title: "Satış Direktörü" },
      ],
    },
    {
      heading: "Merkez",
      members: [
        { id: 3, name: "Cenk Molla", number: "0533 424 17 93", number2: "0232 479 26 60 D 120", image: "/aboutus/yucel-kompozit-ekip-cenk-molla-6420.jpg", title: "Bölge Satış Yönetmeni" },
        { id: 4, name: "Cihangir Özyaman", number: "0542 711 33 05", number2: "0232 479 26 60 D 134", image: "/aboutus/yucel-kompozit-ekip-cihangir-ozyaman-6459.jpg", title: "Satış Temsilcisi" },
        { id: 5, name: "Faruk Çakmak", number: "0549 836 56 29", number2: "0232 479 26 60 D 319", image: "/aboutus/yucel-kompozit-ekip-2021-faruk-cakmak-7228.jpg", title: "Satış Temsilcisi" },
        { id: 6, name: "Özlem Kabaran", number: "0232 479 26 60 D 131", number2: "", image: "/aboutus/yucel-kompozit-ekip-2021-ozlem-kabaran-7253.jpg", title: "Müşteri Temsilcisi" },
      ],
    },
    {
      members: [
        { id: 7, name: "Cengizhan Korkut", number: "0554 817 74 67", number2:"0232 479 26 60 D 126", image: "/aboutus/yucel-kompozit-ekip-cengizhan-korkut-6449.jpg", title: "Teknik Servis ve Depo Şefi" },
        { id: 8, name: "Abdülkadir Güngör", number2: "0232 479 26 60 D 148", image: "/aboutus/yucel-kompozit-ekip-2021-abdulkadir-gungor-7201.jpg", title: "Merkez Depo Sorumlusu" },
      ],
    },
    {
      heading: "",
      members: [
        { id: 9, name: "Çağlar Cingöz", number2: "0232 479 26 60", image: "/aboutus/yucel-kompozit-ekip-2021-caglar-cingoz-7192.jpg", title: "Lojistik Operatörü" },
        { id: 10, name: "Birol Aslan", number2: "0232 479 26 60", image: "/aboutus/yucel-kompozit-ekip-2021-birol-aslan-7188.jpg", title: "Lojistik Operatörü" },
      ],
    },
    {
      members: [
        { id: 11, name: "Emin Korkmaz", number2: "0232 479 26 60", image: "/aboutus/yucel-kompozit-ekip-emin-korkmaz-6442.jpg", title: "Lojistik Operatörü" },
        { id: 12, name: "İsmail Altan", number2: "0232 479 26 60", image: "/aboutus/yucel-kompozit-ekip-ismail-altan-6453.jpg", title: "Lojistik Operatörü" },
      ],
    },
    {
      heading: "Gıda Çarşısı-İzmir",
      members: [
        { id: 13, name: "Feyyaz Arık", number2: "0232 433 60 36", image: "/aboutus/yucel-kompozit-ekip-feyyaz-arik-6414.jpg", title: "Şube Yöneticisi - Gıda Çarşısı" },
        { id: 14, name: "Tuğberk Arslan", number2: "0232 433 60 36", image: "/aboutus/yucel-kompozit-ekip-tugberk-arslan-6417.jpg", title: "Müşteri Hizmetleri - Gıda Çarşısı" },
      ],
    },
    {
      heading: "Bursa",
      members: [
        { id: 15, name: "Gülüm Güler", number: "0533 218 33 62", number2: "0224 216 16 88", image: "/aboutus/yucel-kompozit-ekip-2021-gulum-guler-4699.jpg", title: "Şube Yöneticisi - Bursa" },
        { id: 16, name: "Sedat Yılmaz", number: "0538 545 23 04", number2: "0224 216 16 88", image: "/aboutus/yucel-kompozit-ekip-sedat-yilmaz.jpg", title: "Satış Temsilcisi - Bursa" },
        { id: 17, name: "Burak Canbulat", number: "0549 836 56 28", number2: "0224 216 16 88", image: "/aboutus/yucel-kompozit-ekip-2021-burak-canbulat-rec-20211102.jpg", title: "Satış Temsilcisi - Bursa" },
      ],
    },
  ];

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-4xl bg-red-500 p-4 rounded-xl text-white font-bold text-center">Ekibimiz</h2>

      {teamData.map((team, index) => (
        <div key={index} className="mb-8">
          <h2 className="text-2xl font-bold mb-4 mt-8">{team.heading}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {team.members.map((member, memberIndex) => (
              <MemberCard key={member.id} member={member} delay={memberIndex * 0.2} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default About;
