import React from "react";
import HeaderTable from "../../components/HeaderTable";
import ImageWithHeading from "../../components/ImageWithHeading";
import MultiColumnTable from "../../components/MultiColumnTable";
import img from "../../img/tech/lantor/xm/640-lantor-soric-lrc.jpg";

const Fin = () => {
  const firstColRows = [
    "Kusursuz yüzey",
    "Proses devir süresinde kısalma",
    "Jelkot yerine pigmentli reçine kullanabilme olanağı",
    "İşleme kolaylığı",
    "Reçine içinde yarı saydam (buzlu) görünme",
    "Yüksek uzama kapasitesi",
  ];
  const secondColRows = [
    "Tekne ve yatların gövde ve güverteleri",
    "Otomobil, kamyon, treyler parça ve panelleri",
    "Genel kapalı kalıp uygulamaları",
  ];

  const firstMultiColRows = [
    ["Özellik", "D77 60", "D77 80"],
    ["Kalınlık (mm)", "0,30", "0,40"],
    ["Rulo uzunluğu (m)", "100", "100"],
    ["Rulo genişliği (m)", "çeşitli", "çeşitli"],
    ["Reçine tüketimi (kg/m2)", "0,40", "0,55"],
    ["Kuru ağırlık (g/m2)", "60", "80"],
    ["Bağlayıcı", "iğne delikli", "iğne delikli"],
    ["Lif türü", "PAN", "PAN"],
  ];

  return (
    <div className="container mx-auto p-4">
      <h1 className="bg-red-500 text-white mb-3 h-auto px-6 py-2 rounded-2xl text-2xl md:text-3xl text-center flex items-center justify-center md:px-10 lg:px-20 xl:px-32">
        Lantor Soric ® LRC
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="md:order-2 mt-24">
          <HeaderTable heading="Avantajlar" rows={firstColRows} />
        </div>
        <div className="md:order-1">
          <ImageWithHeading
            heading="Reçine tüketiminin kritik, esnekliğin önemli olduğu ince duvarlı sandviç imalatı için"
            src={img}
            alt="Sample"
          />
        </div>
        <div className="md:order-4 ">
          <HeaderTable heading="Uygulamalar" rows={secondColRows} />
        </div>
        <div className="md:order-3">
          <MultiColumnTable
            heading="Boyut Özellikleri"
            rows={firstMultiColRows}
            description="Reçine tüketimi işlem basıncına göre değişebilir."
          />
        </div>
        <div className="md:order-3 p-4 md:p-8">
          <h1 className="text-lg md:text-xl font-semibold mb-2">
            Drapesi çok yüksek PAN (Poliakrilonitril) tül
          </h1>
          <p className="text-sm md:text-base">
            Finishmat ®, yüksek dökümlü (drape), iğne delikli tüldür. Sıklıkla
            kapalı kalıp işlerinde kullanlıır. Yumuşak Finishmat ®, ozmozu veya
            cam elyaftan gelen yüzey dalgalanmasını önlemek için kalıba ya da
            jelkota karşı uygulanır.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Fin;
