import React from 'react';
import HeaderTable from '../../components/HeaderTable';
import ImageWithHeading from '../../components/ImageWithHeading';
import MultiColumnTable from '../../components/MultiColumnTable';
import img from '../../img/tech/lantor/xm/640-lantor-coremat-xm.jpg'

const Xm = () => {
  const firstColRows = [
    "Açık kalıp uygulamaları için uygun maliyetli malzeme",
    "Kor nüve veya bariyer malzemesi olarak kullanılabilme",
    "Polyester dokumasız yapısıyla polyester, vinilester, fenolik ve epoksi gibi tüm standart reçinelere uyumluluk",
    "El yatırması ve püskürtme uygulamaya uygunluk",
    "Bal peteği yapısıyla daha örtücü",
    "Daha yüksek reçine tasarrufu",
    "mm başına 500 gr/m2 reçine emme kapasitesi",
    "Bal peteği yapısıyla daha örtücü",
];
const secondColRows = [
    "Tekne ve yatların gövde ve güverteleri",
    "Otomobil, kamyon, treyler parça ve panelleri",
    "Polyester dokumasız yapısıyla polyester, vinilester, fenolik ve epoksi gibi tüm standart reçinelere uyumluluk",
    "Otobüs, tren ve hafif raylı sistemlerin iç ve dış gövde ve panelleri",
    "Kano ve sörf tahtaları",
    "Küvet ve havuzlar",
];

  const firstMultiColRows = [
    ["Özellik", "XM 2", "XM 3", "XM 4", "XM 10"],
    ["Kalınlık (mm)", "2,0", "3,0", "4,0", "10,0"],
    ["Rulo uzunluğu (m)", "80", "50", "40", "15"],
    ["Rulo genişliği (m)", "1", "1", "1", "1"],
    ["Reçine emme kapasitesi (kg/m2)", "1,0", "1,5", "2,0", "6,5"],
    ["Kuru ağırlık (g/m2)", "80", "110", "140", "250"],
    ["Emprenye edilmiş dansite (kg/m3)", "540", "540", "540", "540"]
  ];
  const secMultiColRows = [
    ["Mekanik özellik", "Birim", "Değer", "Test metodu"],
    ["Eğilme dayanımı", "MPa", "8,5", "ASTM D790"],
    ["Eğilme modülü", "MPa", "1.250", "ASTM D790"],
    ["Katmanlar arası gerilme dayanımı", "MPa", "4", "ASTM C297"],
    ["Basınç dayanımı (%10 stren)", "MPa", "3", "ISO 844"],
    ["Kopma mukavemeti", "MPa", "25", "ASTM C273-61"],
    ["Kopma modülü", "MPa", "25", "ASTM C273-61"]
  ];

  return (
    <div className="container mx-auto p-4">
         <h1 className="bg-red-500 text-white mb-3 h-auto px-6 py-2 rounded-2xl text-2xl md:text-3xl text-center flex items-center justify-center md:px-10 lg:px-20 xl:px-32">
         Lantor Coremat ® XM
        </h1>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="md:order-2 mt-24">
        <HeaderTable heading="Avantajlar" rows={firstColRows}  />
      </div>
      <div className="md:order-1">
        <ImageWithHeading heading="Bal peteği yapısında yüksek performans ve uygun maliyet" src={img} alt="Sample" />
      </div>
      <div className="md:order-4 ">
        <HeaderTable heading="Uygulamalar" rows={secondColRows} />
      </div>
      <div className="md:order-3">
        <MultiColumnTable heading="Boyut Özellikleri" rows={firstMultiColRows} description="2.500 m2 üzeri siparişlerde isteğe özel genişlikte üretim" />
      </div>
      <div className="md:order-5">
        <MultiColumnTable heading="Doymamış polyester reçine ile emprenye edilmiş Lantor Coremat ® XM’in (XM3) tipik mekanik özellikleri" rows={secMultiColRows} />
      </div>
    </div>
    
  </div>
  );
};

export default Xm;
