import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import tables from "../components/datas/tech/tpoliyadata4"; 

const TableSlider4 = () => {
  const [current, setCurrent] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const breakpoint = 768;

  const handleNextSlide = useCallback(() => {
    if (window.innerWidth < breakpoint) {
      setCurrent((prev) => (prev >= tables.length - 1 ? 0 : prev + 1));
    } else {
      setCurrent((prev) => (prev >= tables.length - 3 ? 0 : prev + 1));
    }
  }, []);

  const handlePrevSlide = useCallback(() => {
    if (window.innerWidth < breakpoint) {
      setCurrent((prev) => (prev === 0 ? tables.length - 1 : prev - 1));
    } else {
      setCurrent((prev) => (prev <= 1 ? tables.length - 3 : prev - 1));
    }
  }, []);

  useEffect(() => {
    const resizeListener = () => {
      const containerWidth = document.querySelector(".slider-container")
        .offsetWidth;
      setCardWidth(
        containerWidth < breakpoint ? containerWidth : containerWidth / 3
      );
    };

    resizeListener();
    window.addEventListener("resize", resizeListener);

    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        handleNextSlide();
      } else if (event.key === "ArrowLeft") {
        handlePrevSlide();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNextSlide, handlePrevSlide]);

  return (
    <div className="relative w-full p-8 max-w-[88rem]  mx-auto h-auto  font-sans overflow-hidden">
     
      <h1 className="text-xl p-4 rounded-xl text-black font-bold mb-6 text-center">
        Polipol 3401 Serisi Ortoftalik Reçineler
      </h1>

      <div className="slider-container relative">
        <button
          onClick={handlePrevSlide}
          className="absolute left-4 md:left-4 z-10 p-2 md:p-3 bg-red-500 text-white top-0  transform -translate-y-1/2 rounded-full shadow-lg hover:bg-orange-700 transition duration-300"
        >
          <FaArrowLeft size={16} />
        </button>
        <button
          onClick={handleNextSlide}
          className="absolute left-16 md:left-20 z-10 p-2 md:p-3 bg-red-500 text-white top-0  transform -translate-y-1/2 rounded-full shadow-lg hover:bg-orange-700 transition duration-300"
        >
          <FaArrowRight size={16} />
        </button>
        <div
          className="flex h-[64rem] transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${
              current * (cardWidth < breakpoint ? cardWidth : cardWidth / 2)
            }px)`,
          }}
        >
          {tables.map((table, index) => (
            <motion.div
              key={table.id}
              className="w-full h-96 mt-6 sm:w-1/2 md:w-1/2 lg:w-1/2 flex-shrink-3 flex items-start justify-center px-1" 
              style={{ width: `${cardWidth}px`, minWidth: `${cardWidth}px` }}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              
            >
              <div className="bg-[#686D76] items-center flex flex-col shadow-black text-white p-3 md:p-4 rounded-3xl shadow-md font-sans w-80"> 
                <h3 className="text-md font-bold mb-3">{table.title}</h3>
                <table className="table-auto w-full text-left">
                  <thead>
                    <tr>
                      {table.data[0].map((header, i) => (
                        <th key={i} className="px-4  py-2">
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="text-sm">
                    {table.data.slice(1).map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex} className="border  px-2 py-1">
                            {cell}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableSlider4;
