import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import data from '../../components/datas/products/makinadata'; 
import SliderCards from "../../components/SliderCards";

const buttons1 = [
  { title: 'Ultramax FIT® (kırpma ve püskürtme)', link: '/button1' },
  { title: 'Ultramax RTM', link: '/button2' },
  { title: 'Ultramax Blok Güçlendirme Çözümleri', link: '/button3' },
  { title: 'Patriot Genel Tanıtım', link: '/button4' },
  { title: 'Patriot Standart Çıkış', link: '/button5' },
  { title: 'Patriot Yüksek Çıkış', link: '/button6' },
];

const buttons2 = [
  { title: 'Genel Tanıtım (sektörel uygulamalar)', link: '/video-magnum-venus-gelcoat-systems' },
];

export default function Makina() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [tabValues] = React.useState(data.map(() => 0));

  const renderAccordion = (accordions) => (
    accordions.map((accordion, index) => (
      <Accordion key={index} sx={{ maxWidth: '100%', height: '100%', width: '100%', marginBottom: "2px", marginTop: "5px", backgroundColor: "#686D76", color: "white", borderRadius: '2px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: "400", alignItems: "center", justifyContent: "center", fontFamily: "Roboto", fontSize: "small" }}>
            {accordion.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table" sx={{ backgroundColor: "#686D76" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", color: "#EEEEEE" }}>Ürün</TableCell>
              
                </TableRow>
              </TableHead>
              <TableBody>
                {accordion.rows.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell sx={{ color: "#EEEEEE", fontSize: "12px" }}>{row.name}</TableCell>
                   
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    ))
  );

  const renderSection = (section, sectionIndex) => (
    <Box key={sectionIndex} className="w-full p-4">
      {section.sectionLabel && (
        <Box className="flex flex-col items-center">
          <Typography variant="h3" className="mb-1 mt-2 font-semibold text-center">{section.sectionLabel}</Typography>
          <Box className="border-b border-gray-300"></Box>
          {section.tabs.map((tab, tabIndex) => (
            <div role="tabpanel" hidden={tabValues[sectionIndex] !== tabIndex} key={tabIndex}>
              {tabValues[sectionIndex] === tabIndex && (
                <Box p={0} sx={{ width: isMobile ? '100%' : '25rem' }}>
                  {renderAccordion(tab.accordions)}
                </Box>
              )}
            </div>
          ))}
        </Box>
      )}
    </Box>
  );

  return (
    <div>
      <div className="p-4 md:p-8 overflow-y-hidden" style={{ width: '100%', maxWidth: 1600, margin: '0 auto' }}>
        <h1 className="bg-red-500 text-white h-auto px-6 py-2 rounded-2xl text-2xl md:text-3xl text-center flex items-center justify-center md:px-10 lg:px-20 xl:px-32">
          Makina ve ekipmanlar
        </h1>
        <Box className="flex flex-col md:flex-row">
          {data.map((section, index) => (
            <Box key={index} className="flex-1 my-3 md:mr-0">
              {renderSection(section, index)}
            </Box>
          ))}
          <Box className="flex-1 my-3 md:mr-4">
            <Typography variant="h3" sx={{ mb: 2, mt: 1 }} className="mb-6 font-semibold text-center">Tanıtım</Typography>
            <Box className="flex flex-col">
              {buttons1.map((button, index) => (
                <Button
                  key={index}
                  variant="contained"
                  color="error"
                  fullWidth
                  sx={{ fontSize: "12px", marginBottom: "2px", height: 47, textAlign: "start", marginTop: "2px", backgroundColor: "#686D76", color: "white", borderRadius: '2px' }}
                  component="a"
                  href={button.link}
                >
                  {button.title}
                </Button>
              ))}
            </Box>
          </Box>
          <Box className="flex-1 my-3 md:mr-4">
            <Typography variant="h3" sx={{ mb: 2, mt: 1 }} className="mb-6 font-semibold text-center">Video</Typography>
            <Box className="flex flex-col">
              {buttons2.map((button, index) => (
                <Button
                  key={index}
                  variant="contained"
                  color="error"
                  fullWidth
                  sx={{ fontSize: "12px", marginBottom: "2px", height: 47, textAlign: "start", marginTop: "2px", backgroundColor: "#686D76", color: "white", borderRadius: '2px' }}
                  component="a"
                  href={button.link}
                >
                  {button.title}
                </Button>
              ))}
            </Box>
          </Box>
        </Box>
      </div>
      <div className="flex justify-center items-center">
        <SliderCards />
      </div>
    </div>
  );
}
