import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FaBars, FaTimes } from "react-icons/fa";
import { MdHome, MdInfo, MdBuild, MdContactMail } from "react-icons/md";
import logo from '../img/1.png';

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleDropdown = (dropdown) => {
    if (isDropdownOpen === dropdown) {
      setIsDropdownOpen(null);
    } else {
      setIsDropdownOpen(dropdown);
    }
  };

  const closeMenu = () => {
    setIsDropdownOpen(null);
    setIsMobileMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown")) {
      setIsDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const dropdownVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: "auto" },
  };

  const menuVariants = {
    hidden: { opacity: 0, x: -200 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <nav className="bg-[#373A40] text-white p-4 font-sans shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <div className="w-44 bg-white rounded-xl font-bold">
          <a href="/">
          <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="md:hidden">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="focus:outline-none"
          >
            {isMobileMenuOpen ? (
              <FaTimes className="w-8 h-8" />
            ) : (
              <FaBars className="w-8 h-8" />
            )}
          </button>
        </div>
        <div className="hidden text-[14px] md:flex md:space-x-4">
          <Link
            to="/"
            className="block px-2 py-2 hover:text-gray-400 flex items-center"
            onClick={closeMenu}
          >
            <MdHome className="mr-2" /> Anasayfa
          </Link>
          <div className="relative dropdown">
            <button
              onClick={() => toggleDropdown("about")}
              className="block px-2 py-2 hover:text-gray-400 focus:outline-none flex items-center"
            >
              <MdInfo className="mr-2" /> Ürünler
            </button>
            <AnimatePresence>
              {isDropdownOpen === "about" && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={dropdownVariants}
                  className="absolute left-0 mt-2 w-[25vh] text-[14px] bg-gray-700  rounded shadow-lg z-10 overflow-hidden"
                >
                  <Link
                    to="/urun-recine"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Reçineler
                  </Link>
                  <Link
                    to="/urun-jelkot"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Jelkotlar
                  </Link>
                  <Link
                    to="/urun-kalip"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Kalıp Ürünleri
                  </Link>
                  <Link
                    to="/urun-vakum"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Vakum İnfüzyon Ürünleri
                  </Link>
                  <Link
                    to="/urun-pigmentpasta"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Pigment Pastalar
                  </Link>
                  <Link
                    to="/urun-elyaf"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Elyaflar
                  </Link>
                  <Link
                    to="/urun-pasta-yapistirici"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Yapıştırıcı Pastalar
                  </Link>
                  <Link
                    to="/urun-hizlandirici-sertlestirici-katki"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Hızlandırıcı, Serleştirici Katkı
                  </Link>
                  <Link
                    to="/urun-inceltici-temizleyici"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    İnceltici ve Temizleyiciler
                  </Link>
                  <Link
                    to="/urun-dolgu-efekt-toz"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Dolgu, Efekt, Toz Pigmentler
                  </Link>
                  <Link
                    to="/urun-uygulama-malzeme"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Uygulama Malzemeleri
                  </Link>
                  <Link
                    to="/urun-makina-ekipman"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Makina ve Ekipman
                  </Link>
                  
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="relative dropdown">
            <button
              onClick={() => toggleDropdown("services")}
              className="block px-2 py-2 hover:text-gray-400 focus:outline-none flex items-center"
            >
              <MdBuild className="mr-2" /> Markalar
            </button>
            <AnimatePresence>
              {isDropdownOpen === "services" && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={dropdownVariants}
                  className="absolute left-0 mt-2 w-[25vh] text-[14px] bg-gray-700 rounded shadow-lg z-10 overflow-hidden"
                >
                  <Link
                    to="/marka-yucel"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    YCL Ürünleri
                  </Link>
                  <Link
                    to="/marka-boytek"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Boytek Ürünleri
                  </Link>
                  <Link
                    to="/marka-diatex"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Diatex Ürünleri
                  </Link>
                  <Link
                    to="/marka-ilkester"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    İlkester Ürünleri
                  </Link>
                  <Link
                    to="/marka-lantor"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Lantor Ürünleri
                  </Link>
                  <Link
                    to="/marka-magnum-venus"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Magnum Venüs Ürünleri
                  </Link>
                  <Link
                    to="/marka-metyx"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >Metyx Ürünleri
                  </Link>
                  <Link
                    to="/marka-poliya"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >Poliya Ürünleri
                  </Link>
                  <Link
                    to="/marka-camelyaf"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Şişecam Ürünleri
                  </Link>
                  <Link
                    to="/marka-tissa"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Tissa Ürünleri
                  </Link>
                  <Link
                    to="/marka-turkuaz"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Turkuaz Polyester Ürünleri
                  </Link>
                  
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="relative dropdown">
            <button
              onClick={() => toggleDropdown("tech")}
              className="block px-2 py-2 hover:text-gray-400 focus:outline-none flex items-center"
            >
              <MdBuild className="mr-2" /> Teknik Bilgiler
            </button>
            <AnimatePresence>
              {isDropdownOpen === "tech" && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={dropdownVariants}
                  className="absolute left-0 mt-2 w-[25vh] text-[14px] bg-gray-700 rounded shadow-lg z-10 overflow-hidden"
                >
                  <Link
                    to="/teknik-ozellik-poliya"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Poliya Teknik Bilgiler
                  </Link>
                  <Link
                    to="/teknik-ozellik-lantor"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Lantor Teknik Bilgiler
                  </Link>
                  <Link
                    to="/teknik-ozellik-magnum-venus"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Magnum Venüs Teknik Bilgiler
                  </Link>
                  <Link
                    to="/teknik-ozellik-tissa"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Tissa Teknik Bilgiler
                  </Link>
               
               
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="relative dropdown">
            <button
              onClick={() => toggleDropdown("log")}
              className="block px-2 py-2 hover:text-gray-400 focus:outline-none flex items-center"
            >
              <MdContactMail className="mr-2" /> Hakkımızda
            </button>
            <AnimatePresence>
              {isDropdownOpen === "log" && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={dropdownVariants}
                  className="absolute left-0 mt-2 w-[25vh] text-[14px] bg-gray-700 rounded shadow-lg z-10 overflow-hidden"
                >
                  <Link
                    to="/hak-yucel-kompozit"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Yücel Kompozit Hakkında
                  </Link>
                  <Link
                    to="/hak-yucel-kompozit-ekip"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Yücel Kompozit Ekibi
                  </Link>
                  <Link
                    to="https://yucelgroup.com/index.html"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Yücel Gurubu Hakkında
                  </Link>
                  
               
               
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <Link
            to="/usage"
            className="block px-2 py-2 hover:text-gray-400 flex items-center"
            onClick={closeMenu}
          >
            <MdContactMail className="mr-2" /> Kullanım Alanları
          </Link>
          <Link
            to="/videos"
            className="block px-2 py-2 hover:text-gray-400 flex items-center"
            onClick={closeMenu}
          >
            <MdContactMail className="mr-2" /> Videolar
          </Link>
          <Link
            to="/download"
            className="block px-2 py-2 hover:text-gray-400 flex items-center"
            onClick={closeMenu}
          >
            <MdContactMail className="mr-2" /> Dökümanlar
          </Link>
          <Link
            to="/contact"
            className="block px-2 py-2 hover:text-gray-400 flex items-center"
            onClick={closeMenu}
          >
            <MdContactMail className="mr-2" /> İletişim
          </Link>
        </div>
      </div>
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={menuVariants}
            className="md:hidden absolute top-0 z-50 left-0 w-full h-[200vh] bg-gray-800 text-white flex flex-col items-center space-y-4 pt-20"
          >
            <button
              onClick={() => setIsMobileMenuOpen(false)}
              className="focus:outline-none text-white absolute top-4 right-4"
            >
              <FaTimes className="w-8 h-8" />
            </button>
            <Link
              to="/"
              className="block px-2 py-2 hover:text-gray-400 flex items-center"
              onClick={closeMenu}
            >
              <MdHome className="mr-2" /> Anasayfa
            </Link>
            <div className="relative dropdown ">
              <button
                onClick={() => toggleDropdown("about")}
                className="block px-2 py-2 hover:text-gray-400 focus:outline-none flex items-center"
              >
                <MdInfo className="mr-2" /> Ürünler
              </button>
              <AnimatePresence>
                {isDropdownOpen === "about" && (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={dropdownVariants}
                    className="bg-gray-700 rounded shadow-lg text-[14px] 0 z-10 overflow-hidden w-full h-full mt-2"
                  >
                    <Link
                      to="/urun-recine"
                      className="block px-2 py-2 hover:bg-gray-600"
                      onClick={closeMenu}
                    >
                      Reçineler
                    </Link>
                    <Link
                      to="/urun-jelkot"
                      className="block px-2 py-2 hover:bg-gray-600"
                      onClick={closeMenu}
                    >
                      Jelkotlar
                    </Link>
                    <Link
                      to="/urun-kalip"
                      className="block px-2 py-2 hover:bg-gray-600"
                      onClick={closeMenu}
                    >
                      Kalıp Ürünleri
                    </Link>
                    <Link
                      to="/urun-vakum"
                      className="block px-2 py-2 hover:bg-gray-600"
                      onClick={closeMenu}
                    >
                      Vakum İnfüzyon Ürünleri
                    </Link>
                    <Link
                      to="/urun-pigmentpasta"
                      className="block px-2 py-2 hover:bg-gray-600"
                      onClick={closeMenu}
                    >
                      Pigment Pastalar
                    </Link>
                    <Link
                      to="/urun-elyaf"
                      className="block px-2 py-2 hover:bg-gray-600"
                      onClick={closeMenu}
                    >
                      Elyaflar
                    </Link>
                    <Link
                      to="/urun-pasta-yapistirici"
                      className="block px-2 py-2 hover:bg-gray-600"
                      onClick={closeMenu}
                    >
                      Yapıştırıcı Pastalar
                    </Link>
                    <Link
                      to="/urun-hizlandirici-sertlestirici-katki"
                      className="block px-2 py-2 hover:bg-gray-600"
                      onClick={closeMenu}
                    >
                      Hızlandırıcı, Serleştirici Katkı
                    </Link>
                    <Link
                      to="/urun-inceltici-temizleyici"
                      className="block px-2 py-2 hover:bg-gray-600"
                      onClick={closeMenu}
                    >
                      İnceltici ve Temizleyiciler
                    </Link>
                    <Link
                      to="/urun-dolgu-efekt-toz"
                      className="block px-2 py-2 hover:bg-gray-600"
                      onClick={closeMenu}
                    >
                      Dolgu, Efekt, Toz Pigmentler
                    </Link>
                    <Link
                      to="/urun-uygulama-malzeme"
                      className="block px-2 py-2 hover:bg-gray-600"
                      onClick={closeMenu}
                    >
                      Uygulama Malzemeleri
                    </Link>
                    <Link
                      to="/urun-makina-ekipman"
                      className="block px-2 py-2 hover:bg-gray-600"
                      onClick={closeMenu}
                    >
                      Makina ve Ekipman
                    </Link>
                    
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div className="relative dropdown">
              <button
                onClick={() => toggleDropdown("services")}
                className="block px-2 py-2 hover:text-gray-400 focus:outline-none flex items-center"
              >
                <MdBuild className="mr-2" /> Markalar
              </button>
              <AnimatePresence>
                {isDropdownOpen === "services" && (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={dropdownVariants}
                    className="bg-gray-700 rounded text-[14px] 0 shadow-lg z-10 overflow-hidden w-full mt-2"
                  >
                    <Link
                    to="/marka-yucel"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    YCL Ürünleri
                  </Link>
                  <Link
                    to="/marka-boytek"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Boytek Ürünleri
                  </Link>
                  <Link
                    to="/marka-diatex"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Diatex Ürünleri
                  </Link>
                  <Link
                    to="/marka-ilkester"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    İlkester Ürünleri
                  </Link>
                  <Link
                    to="/marka-lantor"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Lantor Ürünleri
                  </Link>
                  <Link
                    to="/marka-magnum-venus"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Magnum Venüs Ürünleri
                  </Link>
                  <Link
                    to="/marka-metyx"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >Metyx Ürünleri
                  </Link>
                  <Link
                    to="/marka-poliya"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >Poliya Ürünleri
                  </Link>
                  <Link
                    to="/marka-camelyaf"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Şişecam Ürünleri
                  </Link>
                  <Link
                    to="/marka-tissa"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Tissa Ürünleri
                  </Link>
                  <Link
                    to="/marka-turkuaz"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Turkuaz Polyester Ürünleri
                  </Link>
                    
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div className="relative dropdown">
              <button
                onClick={() => toggleDropdown("tech")}
                className="block px-2 py-2 hover:text-gray-400  focus:outline-none flex items-center"
              >
                <MdBuild className="mr-2" /> Teknik Bilgiler
              </button>
              <AnimatePresence>
                {isDropdownOpen === "tech" && (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={dropdownVariants}
                    className="bg-gray-700 rounded  shadow-lg z-10 text-[14px] 0 overflow-hidden w-full mt-2"
                  >
                    <Link
                    to="/teknik-ozellik-poliya"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Poliya Teknik Bilgiler
                  </Link>
                  <Link
                    to="/teknik-ozellik-lantor"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Lantor Teknik Bilgiler
                  </Link>
                  <Link
                    to="/teknik-ozellik-magnum-venus"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Magnum Venus Teknik Bilgiler
                  </Link>
                  <Link
                    to="/teknik-ozellik-tissa"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Tissa Teknik Bilgiler
                  </Link>
                  
                    
                  </motion.div>
                )}
              </AnimatePresence>
            </div>



            <div className="relative dropdown">
              <button
                onClick={() => toggleDropdown("log")}
                className="block px-2 py-2 hover:text-gray-400  focus:outline-none flex items-center"
              >
                <MdContactMail className="mr-2" /> Hakkımızda
              </button>
              <AnimatePresence>
                {isDropdownOpen === "log" && (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={dropdownVariants}
                    className="bg-gray-700 rounded  shadow-lg z-10 text-[14px] 0 overflow-hidden w-full mt-2"
                  >
                    <Link
                    to="/hak-yucel-kompozit"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Yücel Kompozit Hakkında
                  </Link>
                  <Link
                    to="/hak-yucel-kompozit-ekip"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Yücel Kompozit Ekibi
                  </Link>
                  <Link
                    to="https://yucelgroup.com/index.html"
                    className="block px-2 py-2 hover:bg-gray-600"
                    onClick={closeMenu}
                  >
                    Yücel Gurubu Hakkında
                  </Link>
                  
                  </motion.div>
                )}
              </AnimatePresence>
            </div>






            <Link
              to="/usage"
              className="block px-2 py-2 hover:text-gray-400 flex items-center"
              onClick={closeMenu}
            >
              <MdContactMail className="mr-2" /> Kullanım Alanları
            </Link>
            <Link
              to="/videos"
              className="block px-2 py-2 hover:text-gray-400 flex items-center"
              onClick={closeMenu}
            >
              <MdContactMail className="mr-2" /> Videolar
            </Link>
            <Link
              to="/download"
              className="block px-2 py-2 hover:text-gray-400 flex items-center"
              onClick={closeMenu}
            >
              <MdContactMail className="mr-2" /> Dökümanlar
            </Link>
            <Link
              to="/contact"
              className="block px-2 py-2 hover:text-gray-400 flex items-center"
              onClick={closeMenu}
            >
              <MdContactMail className="mr-2" /> İletişim
            </Link>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
