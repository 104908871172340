import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import image1 from '../img/pdf/images/420-mvp-kapali-kalip-uygulamalari-brosuru.jpg';
import image2 from '../img/pdf/images/420-mvp-urun-katalogu.jpg';
import image3 from '../img/pdf/images/420-mvp-havasiz-ekipman-guvenlik-rehberi.jpg';
import image4 from '../img/pdf/images/420-mvp-mph-vph-4250-guc-kafasi-kullanma-kilavuzu.jpg';
import image5 from '../img/pdf/images/420-mvp-pompa-sistemi-test-ayar-kilavuzu.jpg';
import image6 from '../img/pdf/images/420-mvp-cpc-cpg-cpw-cpp-klasik-pro-tabanca-kullanma-kilavuzu.jpg';
import image7 from '../img/pdf/images/420-mvp-vhpc-1000-dozaj-pompasi-kullanma-kilavuzu.jpg';
import image8 from '../img/pdf/images/420-vls-4600-sivi-bolumu-kullanma-kilavuzu.jpg';
import image9 from '../img/pdf/images/420-mvp-vrc-1000-2000-3000-pro-super-pro-kullanma-tamir-kilavuzu.jpg';
import image10 from '../img/pdf/images/420-camelyaf-ctp-teknolojisi.jpg';

const cardGroups = [
  {
    title: "Ürünler, demolar, teknik bilgiler, sunumlar",
    cards: [
      {
        id: 1,
        title: `MVP® Kapalı Kalıp Uygulamaları`,
        content: `Kapalı kalıp uygulamaları ile ilgili faydalı genel bilgiler \n \n 6 sayfa, Türkçe, 655 KB`,
        image: image1,
        link: "/pdf/magnum-venus-kapali-kaliplama-brosuru-v4-02a.pdf"
      },
      {
        id: 2,
        title: `MVP® Ürün Kataloğu`,
        content: `CPR serisi tabancaların bakım ve onarımı için temel bilgiler`,
        image: image2,
        link: "/pdf/magnum-venus-urun-katalog.pdf"
      },
      {
        id: 3,
        title: `MVP® Havasız Ekipman Güvenlik kılavuzu`,
        content: `Klasik Pro serisi tabancaların bakım ve onarımı için temel bilgiler`,
        image: image3,
        link: "/pdf/magnum-venus-havasiz-ekipman-guvenlik-kilavuz.pdf"
      },
      {
        id: 4,
        title: `MVP® Güç Kafası kullanma kılavuzu`,
        content: `Internal Mix (Dahili Karışım) serisi Talon tabancaların bakım ve onarımı için temel bilgiler`,
        image: image4,
        link: "/pdf/magnum-venus-mph-vph-4250-guc-kafasi-kilavuz.pdf"
      },
      {
        id: 5,
        title: `Magnum Venus Products® External Mix Serisi bakım, onarım`,
        content: `External Mix (Harici Karışım) serisi Talon tabancaların bakım ve onarımı için temel bilgiler`,
        image: image5,
        link: "/pdf/magnum-venus-mvp-pompa-sistemi-test-ayar-kilavuz.pdf"
      },
      {
        id: 6,
        title: `Coremat® Xi - XM üç boyutlu uygulama`,
        content: `Coremat® Xi - XM açık kalıp uygulamalarında üç boyutlu geometriye uyum kalitesini izleyin.`,
        image: image6,
        link: "/pdf/magnum-venus-cpc-cpg-cpw-cpp-klasik-pro-tabanca-kilavuz.pdf"
      },
      {
        id: 7,
        title: `Coremat® Xi - XM kolay birleşme`,
        content: `Coremat® Xi - XM açık kalıp uygulamalarında uygulama kolaylığı ve birleşme kusursuzluğunu izleyin.`,
        image: image7,
        link: "/pdf/magnum-venus-vhpc-1000-dozaj-pompasi-kilavuz.pdf"
      },
      {
        id: 8,
        title: `Soric® ile vakum infüzyon`,
        content: `Soric® ailesinin vakum infüzyondaki benzersiz performansını izleyin.`,
        image: image8,
        link: "/pdf/magnum-venus-vls-4600-sivi-bolumu-kilavuz.pdf"
      },
      {
        id: 9,
        title: `Coremat® Uygulama Rehberi 2022`,
        content: `Coremat® ile çalışmanın bütün aşamalarını ve ipuçlarını izleyin.`,
        image: image9,
        link: "/pdf/magnum-venus-vrc-1000-2000-3000-pro-super-pro-kullanma-tamir-kilavuz.pdf"
      },
      {
        id: 10,
        title: `Soric® Türk Kompozit Zirvesi 2017`,
        content: `Soric® ailesinin ayrıntılı teknik bilgilerini ve demonstrasyonlarını Türk Kompozit Zirvesi etkinliğimizde Ward Steijn’den izleyin.`,
        image: image10,
        link: "/pdf/camelyaf-ctp-teknolojisi.pdf"
      }
    ],
  },
];

const Subpage3 = () => {
  return (
    <div className="mt-2 relative cursor-default w-full">
      <div className="container mx-auto mt-10 px-4">
        {cardGroups.map((group, index) => (
          <div key={index} className="mb-16">
            <h2 className="text-2xl bg-red-500 p-4 rounded-xl text-white font-bold mb-6 text-center">
              {group.title}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {group.cards.map((card) => (
                <Card key={card.id} card={card} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};



const Card = ({ card }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  

  return (
    <motion.a
      ref={ref}
      href={card.link}
      target="_blank"
      initial="hidden"
      animate={controls}
      variants={cardVariants}
      className="text-white w-[26rem] h-auto bg-red-400  rounded-3xl p-4 mt-9 shadow-xl shadow-slate-800  transform transition duration-300 hover:scale-105"
    >
      <img
        src={card.image}
        alt={card.title}
        className="w-96 h-80 shadow-black shadow-lg object-fill rounded-2xl mb-3"
      />
      <h3 className="text-xl font-bold mb-2">{card.title}</h3>
      <p className="text-base mb-7">
        {card.content.split('\n').map((str, index) => (
          <React.Fragment key={index}>
            {str}
            <br />
          </React.Fragment>
        ))}
      </p>
    </motion.a>
  );
};

export default Subpage3;
