const data = [
  {
    sectionLabel: "Metyx Ürünleri",
    tabs: [
      {
        
        accordions: [
          {
            title: "Takviye Ürünler",
            rows: [
              {
                name: "RTM 300*180*300 / 127 cm",
              },
              {
                name: "RTM 300*180*300 / 163 cm",
              },
              {
                name: "RTM 450*180*450 / 163 cm",
              },
              {
                name: "RTM 600*180*600 / 95 cm",
              },
              {
                name: "RTM 600*180*600 / 120 cm",
              },
              {
                name: "RTM 600*180*600 / 136 cm",
              },
              {
                name: "RTM 600*180*600 / 163 cm",
              },
              {
                name: "RTM 600*180*600 / 210 cm",
              },
              {
                name: "RTM 750*180*750 / 163 cm",
              },
            ],
          },
        ],
      },
    ],
  },
];

export default data;
