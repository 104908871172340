import React from 'react';
import { Link } from 'react-router-dom';
import image from '../../img/tech/440-brand-magnum-venus-products.jpg'
import { motion } from 'framer-motion';


const Tmagnum = () => {
  const headings = [
    { title: 'Ultramax® Serisi', buttons: [
        { name: 'FIT Kırpma ve Püskürtme Serisi', link: '/makina-magnum-venus-ultramax-fit' },
        { name: 'RTM Sistem', link: '/makina-magnum-venus-ultramax-rtm' },
        { name: 'Blok Güçlendirme çözümleri', link: '/makina-magnum-venus-ultramax-blok' },
    ]
    },
    { title: 'Patriot® Sistemi', buttons: [
        { name: 'Patriot Sistemi Genel Bilgiler', link: '/makina-magnum-venus-patriot-yuksek' },
        { name: 'Patriot Standart Çıkış Sistemi', link: '/makina-magnum-venus-patriot-standart' },
        { name: 'Patriot Yüksek Çıkış Sistemi', link: '/makina-magnum-venus-patriot-yuksek' },
    
    
      ],
    },
    { title: 'Kataloglar ve Kılavuzlar', buttons: [
        { name: 'Magnum Venus Kullanma Kılavuzları ve Katalog', link: '/download' },
      

      ],
    },
    
  ];
  const pageVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <motion.div
    className="container mx-auto p-4"
    initial="hidden"
    animate="visible"
    transition={{ duration: 0.5 }}
  >
    <motion.header className="text-center" variants={pageVariants}>
      <motion.h1
        className="text-2xl bg-red-500 p-4 rounded-xl text-white font-bold mb-6 text-center"
        variants={pageVariants}
      >
        Teknik Bilgiler İndeksi - Lantor ürünleri
      </motion.h1>
      <motion.img
        src={image}
        alt="Logo"
        className="mx-auto mb-8 w-auto h-40 rounded-2xl"
        variants={pageVariants}
      />
    </motion.header>
    <motion.div
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
      initial="hidden"
      animate="visible"
      transition={{ staggerChildren: 0.2 }}
    >
      {headings.map((heading, index) => (
        <motion.div
          key={index}
          className="p-4 border rounded shadow-lg"
          variants={pageVariants}
        >
          <motion.h2 className="text-2xl font-semibold mb-4" variants={pageVariants}>
            {heading.title}
          </motion.h2>
          <motion.div className="space-y-2" variants={pageVariants}>
            {heading.buttons.map((button, btnIndex) => (
              <motion.div key={btnIndex} variants={pageVariants}>
                <Link
                  to={button.link}
                  className="block text-center py-2 px-4 bg-[#686D76] text-white rounded hover:bg-red-500"
                >
                  {button.name}
                </Link>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      ))}
    </motion.div>
  </motion.div>
  );
};
export default Tmagnum;
