const data = [
  {
    sectionLabel: "İlkester Ürünleri",
    tabs: [
      {
        label: "Polyester Reçineler",
        accordions: [
          {
            title: "Genel amaçlı",
            rows: [
              {
                name: "P-192",
                type: "Ortoftalik doymamış polyester reçine",
                description: "CTP uygulamaları",
              },
              {
                name: "P-192 TA",
                type: "Ortoftalik damlamayan doymamış polyester reçine",
                description: "CTP uygulamaları",
              },
              {
                name: "P-1920",
                type: "Ortoftalik doymamış polyester reçine",
                description: "CTP uygulamaları",
              },
              {
                name: "P-116",
                type: "Ortoftalik doymamış polyester reçine",
                description: "CTP seri üretim",
              },
              {
                name: "P-116",
                type: "Ortoftalik yüksek mukavemetli doymamış polyester reçine",
                description: "CTP seri üretim",
              },
              {
                name: "P-1016",
                type: "Ortoftalik doymamış polyester reçine",
                description: "CTP seri üretim",
              },
              {
                name: "P-1017 ETPS",
                type: "Tiksotropik düşük stren emisyonlu",
                description: "CTP uygulamaları",
              },
              {
                name: "P-192 BS",
                type: "Ortoftalik doymamış polyester reçine",
                description: "Kafes uygulamaları",
              },
              {
                name: "P-920",
                type: "Ortoftalik doymamış polyester reçine",
                description: "Modüler üretim",
              },
            ],
          },
          {
            title: "Döküm (cast) tipi",
            rows: [
              {
                name: "P-068",
                type: "Ortoftalik doymamış polyester reçine",
                description: "Döküm (cast) uygulamaları",
              },
              {
                name: "P-060",
                type: "Ortoftalik doymamış polyester reçine",
                description: "Döküm (cast) uygulamaları",
              },
              {
                name: "P-053",
                type: "Ortoftalik doymamış polyester reçine",
                description: "Döküm (cast) uygulamaları",
              },
              {
                name: "P-6817",
                type: "Ortoftalik doymamış polyester reçine",
                description: "Döküm (cast) uygulamaları",
              },
              {
                name: "P-104",
                type: "Ortoftalik doymamış polyester reçine",
                description: "Şeffaf döküm (cast) uygulamaları",
              },
            ],
          },
          {
            title: "Kimyasallara dayanıklı",
            rows: [
              {
                name: "IR-100",
                type: "İzoftalik doymamış polyester reçine",
                description: "CTP uygulamaları",
              },
              {
                name: "IR-100 T",
                type: "İzoftalik tiksotropik doymamış polyester reçine",
                description: "CTP uygulamaları",
              },
              {
                name: "IR-110 T",
                type: "İzoftalik tiksotropik doymamış polyester reçine",
                description: "CTP uygulamaları",
              },
              {
                name: "PV-300",
                type: "Bisfenol-A epoksi vinilester reçine",
                description: "CTP uygulamaları",
              },
              {
                name: "PV-300 T",
                type: "Bisfenol-A epoksi vinilester tiksotropik reçine",
                description: "CTP uygulamaları",
              },
              {
                name: "PV-200",
                type: "Bisfenol-A epoksi vinilester reçine",
                description: "CTP uygulamaları",
              },
              {
                name: "PV-290 PS",
                type: "Bisfenol-A epoksi vinilester reçine",
                description: "Kafes uygulamaları",
              },
              {
                name: "IR-105 LMR",
                type: "İzoftalik doymamış polyester reçine",
                description: "RTM uygulamaları",
              },
              {
                name: "IR-106 LMR",
                type: "İzoftalik doymamış polyester reçine",
                description: "Şeffaf uygulamalar",
              },
              {
                name: "IR-3020 BS",
                type: "İzoftalik doymamış polyester reçine",
                description: "Kafes uygulamaları",
              },
              {
                name: "IR-355 LMR",
                type: "İzoftalik doymamış polyester reçine",
                description: "Pultruzyon uygulamaları",
              },
            ],
          },
          {
            title: "RTM",
            rows: [
              {
                name: "P-141 LMR",
                type: "Ortoftalik doymamış polyester reçine",
                description: "RTM uygulamaları",
              },
              {
                name: "P-4150 LMR",
                type: "Ortoftalik doymamış polyester reçine",
                description: "RTM uygulamaları",
              },
              {
                name: "P-2150 LMR",
                type: "İzoftalik doymamış polyester reçine",
                description: "RTM uygulamaları",
              },
              {
                name: "IR-105 LMR",
                type: "İzoftalik doymamış polyester reçine",
                description: "RTM uygulamaları",
              },
              {
                name: "IR-106 LMR",
                type: "İzoftalik doymamış polyester reçine",
                description: "Şeffaf RTM uygulamaları",
              },
            ],
          },
          {
            title: "SMC - BMC",
            rows: [
              {
                name: "P-1158",
                type: "Ortoftalik BMC tipi doymamış polyester reçine",
              },
              {
                name: "P-1186 PLUS",
                type: "Ortoftalik SMC - BMC tipi doymamış polyester reçine",
              },
              {
                name: "P-1158",
                type: "Ortoftalik SMC - BMC tipi doymamış polyester reçine",
              },
            ],
          },
          {
            title: "Akrilik destek (backup)",
            rows: [
              {
                name: "P-470",
                type: "Ortoftalik tiksotropik doymamış polyester reçine",
                description: "Akrilik destek uygulamaları",
              },
              {
                name: "P-475",
                type: "Ortoftalik tiksotropik dolgulu doymamış polyester reçine",
                description: "Akrilik destek uygulamaları",
              },
            ],
          },

          {
            title: "Pultruzyon",
            rows: [
              {
                name: "P-755",
                type: "Ortoftalik doymamış polyester reçine",
                description: "Pultruzyon uygulamaları",
              },
              {
                name: "P-475",
                type: "İzoftalik doymamış polyester reçine",
                description: "Pultruzyon destek uygulamaları",
              },
            ],
          },
          {
            title: "Alev geciktirici",
            rows: [
              {
                name: "FR-40 (EN 45545-2)",
                type: "Spesifik reçine bazlı tiksotropik halojensiz doymamış polyester",
                description: "EN 45545-2 (HL2) uyumlu uygulamalar",
              },
              {
                name: "FR-40 (DIN 5510-2)",
                type: "Spesifik reçine bazlı tiksotropik halojensiz doymamış polyester",
                description: "EN 45545-2 (S4, SR2, ST2) uyumlu uygulamalar",
              },
              {
                name: "FR-50",
                type: "Spesifik reçine bazlı tiksotropik halojensiz doymamış polyester",
                description: "Standart alev geciktirici uygulamalar",
              },
            ],
          },
          {
            title: "Düğme tipi",
            rows: [
              {
                name: "P-104D",
                type: "Ortoftalik doymamış polyester reçine",
                description: "Santrifüjlü döküm (cast) düğme üretimi",
              },
              {
                name: "P-405D",
                type: "Ortoftalik doymamış polyester reçine",
                description: "Santrifüjlü döküm (cast) düğme üretimi",
              },
              {
                name: "P-128D",
                type: "Ortoftalik doymamış polyester reçine",
                description: "Santrifüjlü döküm (cast) düğme üretimi",
              },
              {
                name: "P-1004D",
                type: "Ortoftalik tiksotropik doymamış polyester reçine",
                description: "Çubuk tip döküm (cast) düğme üretimi",
              },
              {
                name: "P-1006D",
                type: "Ortoftalik tiksotropik doymamış polyester reçine",
                description: "Çubuk tip döküm (cast) düğme üretimi",
              },
              {
                name: "P-4004D",
                type: "Ortoftalik tiksotropik doymamış polyester reçine",
                description: "Çubuk tip döküm (cast) düğme üretimi",
              },
            ],
          },
          {
            title: "Ahşap kaplama",
            rows: [
              {
                name: "P-1020",
                type: "Ortoftalik yüksek reaktif doymamış polyester reçine",
                description: "İlk kat vernik üretimi",
              },
              {
                name: "P-1002",
                type: "Doymamış polyester reçine bazlı dolgu verniği",
                description: "İlk kat vernik üretimi",
              },
              {
                name: "P-2002",
                type: "Doymamış polyester reçine bazlı beyaz astar",
                description: "İlk kat beyaz astar",
              },
            ],
          },
          {
            title: "Abrasiv - Akemi",
            rows: [
              {
                name: "PM-220",
                type: "Ortoftalik abrasiv tip doymamış polyester reçine",
                description: "Abrasiv taş imalatı",
              },
              {
                name: "PM-245",
                type: "Ortoftalik doymamış polyester reçine",
                description: "Mermer için yapıştırıcı pasta imalatı",
              },
              {
                name: "PM-246",
                type: "Ortoftalik doymamış polyester reçine",
                description: "Mermer için yapıştırıcı pasta imalatı",
              },
            ],
          },
          {
            title: "Spesifik",
            rows: [
              {
                name: "LAK-1030",
                type: "Parlak doymamış polyester reçine",
                description:
                  "Hava kurutmalı finisaj, çatlak, delik kapatma, parlak cila",
              },
              {
                name: "LAK-1030 T",
                type: "Tiksotropik parlak doymamış polyester reçine",
                description:
                  "Hava kurutmalı parlak CTP cila, mobilya finisaj, metal yüzey boşluk doldurma",
              },
              {
                name: "RP-92",
                type: "Ortoftalik plastikleştirici tip doymamış polyester",
                description:
                  "Polyester reçine ve jelkotlar için esnekleştirici",
              },
              {
                name: "PVM-300",
                type: "Spesifik name",
              },
            ],
          },

          {
            title: "Vinilester",
            rows: [
              {
                name: "PV-300",
                type: "Bisfenol-A epoksi vinilester reçine",
                description: "CTP uygulamaları",
              },
              {
                name: "PV-300 T",
                type: "Bisfenol-A epoksi vinilester tiksotropik reçine",
                description: "CTP uygulamaları",
              },
              {
                name: "PV-200",
                type: "Bisfenol-A epoksi vinilester reçine",
                description: "CTP uygulamaları",
              },
              {
                name: "PV-290 PS",
                type: "Bisfenol-A epoksi vinilester reçine",
                description: "Kafes uygulamaları",
              },
              {
                name: "PV-290 PS",
                type: "Bisfenol-A epoksi vinilester reçine",
                description: "Kimyasal dübel (ankoraj) uygulamaları",
              },
            ],
          },
          {
            title: "Genel amaçlı jelkotlar",
            rows: [
              {
                name: "G-7",
                type: "Ortoftalik jelkot",
                description: "Genel elyaf püstürtme uygulamaları",
              },
              {
                name: "G-307",
                type: "Ortoftalik jelkot",
                description: "Genel fırça uygulamaları",
              },
            ],
          },
          {
            title: "Performans tipi jelkotlar",
            rows: [
              {
                name: "GN-417",
                type: "Ortoftalik / NPG akrilik modifiye jelkot",
                description: "Elyaf püstürtme uygulamaları",
              },
              {
                name: "GN-317",
                type: "Ortoftalik / NPG akrilik modifiye jelkot",
                description: "Genel fırça uygulamaları",
              },
            ],
          },
          {
            title: "Yüksek performans tipi jelkotlar",
            rows: [
              {
                name: "GIN-4013",
                type: "İzoftalik / NPG akrilik modifiye jelkot",
                description: "Elyaf püstürtme uygulamaları",
              },
              {
                name: "GIN-3013",
                type: "İzoftalik / NPG akrilik modifiye jelkot",
                description: "Fırça uygulamaları",
              },
              {
                name: "GIN-413",
                type: "İzoftalik / NPG akrilik modifiye jelkot",
                description: "Elyaf püskürtme uygulamaları",
              },
              {
                name: "GIN-313",
                type: "İzoftalik / NPG akrilik modifiye jelkot",
                description: "Fırça uygulamaları",
              },
            ],
          },
          {
            title: "Kimyasallara dirençli jelkotlar",
            rows: [
              {
                name: "GVE-5",
                type: "Epoksi vinilester jelkot",
                description: "Elyaf püstürtme uygulamaları",
              },
              {
                name: "GVE-305",
                type: "Epoksi vinilester jelkot",
                description: "Fırça uygulamaları",
              },
              {
                name: "GIN-3013",
                type: "İzoftalik / NPG akrilik modifiye jelkot",
                description: "Fırça uygulamaları",
              },
              {
                name: "GIN-4013",
                type: "İzoftalik / NPG akrilik modifiye jelkot",
                description: "Elyaf püskürtme uygulamaları",
              },
              {
                name: "GIN-313",
                type: "İzoftalik / NPG akrilik modifiye jelkot",
                description: "Fırça uygulamaları",
              },
              {
                name: "GIN-413",
                type: "İzoftalik / NPG akrilik modifiye jelkot",
                description: "Elyaf püskürtme uygulamaları",
              },
            ],
          },
          {
            title: "Zımparalanabilir son kat jelkotlar",
            rows: [
              {
                name: "G-18 Renkli",
                type: "Ortoftalik zımparalanabilir sonkat jelkot",
                description: "Püstürtme uygulamaları",
              },
              {
                name: "G-318 Renkli",
                type: "Ortoftalik zımparalanabilir sonkat jelkot",
                description: "Fırça uygulamaları",
              },
            ],
          },

          {
            title: "Alev geciktirici jelkotlar",
            rows: [
              {
                name: "GFR-4 Renkli",
                type:
                  "Özel reçine bazlı halojensiz alev geciktirici jelkot",
                description: "Püstürtme uygulamaları",
              },
              {
                name: "GFR-40 Renkli",
                type:
                  "Özel reçine bazlı halojensiz alev geciktirici jelkot",
                description: "Fırça uygulamaları",
              },
              {
                name: "GFR-445 Renkli",
                type:
                  "Özel reçine bazlı halojensiz alev geciktirici jelkot",
                description: "Püstürtme uygulamaları",
              },
              {
                name: "GFR-345 Renkli",
                type:
                  "Özel reçine bazlı halojensiz alev geciktirici jelkot",
                description: "Fırça uygulamaları",
              },
            ],
          },
          {
            title: "Bariyerkotlar",
            rows: [
              {
                name: "GVE-5",
                type: "Epoksi vinilester bariyerkot",
                description: "Püstürtme uygulamaları",
              },
              {
                name: "GVE-305",
                type: "Epoksi vinilester bariyerkot",
                description: "Fırça uygulamaları",
              },
              {
                name: "GVE-344",
                type: "Epoksi vinilester bariyerkot",
                description: "Fırça uygulamaları",
              },
            ],
          },
          {
            title: "Yapıştırıcı pastalar",
            rows: [
              {
                name: "TK-501BP",
                type:
                  "Mikronize cam elyaf takviyeli yüksek performans yapıştırıcı pasta",
              },
              {
                name: "TIX-081",
                type: "Cam elyaf takviyeli sıvı laminat yapıştırıcı pasta",
              },
              {
                name: "TK-TIX",
                type: "Ortoftalik doymamış polyester reçine bazlı tix",
              },
            ],
          },
          {
            title: "Opak pigment pastalar",
            rows: [
              {
                name: "ILK-100",
                type: "Oksit sarı",
              },
              {
                name: "ILK-103",
                type: "Krom sarı",
              },
              {
                name: "ILK-217",
                type: "Portakal rengi",
              },
              {
                name: "ILK-315",
                type: "Bayrak kırmızı",
              },
              {
                name: "ILK-500",
                type: "Helio mavi",
              },
              {
                name: "ILK-600",
                type: "Helio yeşil",
              },
              {
                name: "ILK-726",
                type: "Siyah",
              },
              {
                name: "ILK-800",
                type: "Koyu kahverengi",
              },
              {
                name: "ILK-801",
                type: "Açık kahverengi",
              },
              {
                name: "ILK-912",
                type: "Kar beyaz",
              },
              {
                name: "ILK-926",
                type: "Beyaz",
              },
            ],
          },
          {
            title: "Şeffaf pigment pastalar",
            rows: [
              {
                name: "ILK-1001",
              },
              {
                name: "ILK-1002",
              },
              {
                name: "ILK-1003",
              },
              {
                name: "ILK-2001",
              },
              {
                name: "ILK-2002",
              },
              {
                name: "ILK-2003",
              },
              {
                name: "ILK-3001",
              },
              {
                name: "ILK-3002",
              },
              {
                name: "ILK-3003",
              },
              {
                name: "ILK-3005",
              },
              {
                name: "ILK-3006",
              },
              {
                name: "ILK-3007",
              },
              {
                name: "ILK-3008",
              },
              {
                name: "ILK-3009",
              },
              {
                name: "ILK-3010",
              },
              {
                name: "ILK-4001",
              },
              {
                name: "ILK-4002",
              },
              {
                name: "ILK-4003",
              },
              {
                name: "ILK-5001",
              },
              {
                name: "ILK-5002",
              },
            ],
          },
          {
            title: "Yüksek performans pigment pastalar",
            rows: [
              {
                name: "ILK-509",
                type: "Ada mavisi",
              },
              {
                name: "ILK-510",
                type: "Ultramarin mavi",
              },
              {
                name: "ILK-530",
                type: "Gece mavisi",
              },
              {
                name: "ILK-532",
                type: "Signal mavi",
              },
              {
                name: "ILK-536",
                type: "Soluk mavi",
              },
              {
                name: "ILK-538",
                type: "Pastel mavi",
              },
              {
                name: "ILK-548",
                type: "Açık mavi",
              },
              {
                name: "ILK-552",
                type: "Kobalt mavi",
              },
              {
                name: "ILK-553",
                type: "Trafik mavi",
              },
              {
                name: "ILK-563",
                type: "Gök mavisi",
              },
              {
                name: "ILK-566",
                type: "Yeşil mavi",
              },
            ],
          },
          
            {
              title: "Spesifik pigment pastalar (SMC - BMC üretim)",
              rows: [
                {
                  description: "Sarı, turuncu, kırmızı, mavi, yeşil, kahverengi, gri renklerdeki 28 adet özel SMC - BMC pigment pasta için lütfen bilgi alınız."
                }
              ]
            },
            {
              title: "Spesifik pigment pastalar (Yüzme havuzu üretimi)",
              rows: [
                {
                  description: "Ftalat, solvent ve monomer içermeyen; ısı, UV ve kimyasallara yüksek dayanımı olan, en çok açok mavi ve beyaz renkleri tercih edilen spesifik pigment pastalar için lütfen bilgi alınız."
                }
              ]
            },
            {
              title: "Uluslararası katalog renkleri",
              rows: [
                {
                  description: "Tüm uluslararası renk kataloglarından seçim yapabileceğiniz; seçtiğiniz renge %100 uyumlu üretilen; 8 - 10 yıl raf ömrü olan; ftalat, solvent ve monomer içermeyen pigment pastalar için lütfen bilgi alınız."
                }
              ]
            }
          
          
        ],
      },










      {
        label: "Vinilester Reçineler",
        accordions: [
          {
            title: "Tam liste - Tıklayınız",
            rows: [
              {
                name: "DERAKANE® Momentum 411-350",
                type: "Epoksi Vinilester reçinesi",
                description: "",
              },
              {
                name: "DERAKANE® Momentum 470-300",
                type: "Novolac Vinilester reçinesi",
                description: "",
              },
              {
                name: "BVE 780",
                type: "Vinilester",
                description: "Bisfenol A Epoksi Vinil Ester",
              },
              {
                name: "BVE 782",
                type: "Vinilester",
                description:
                  "İnfüzyon için Bisfenol A Epoksi Vinil Ester, düşük viskozite",
              },
            ],
          },
        ],
      },

      {
        label: "Kimyasallar",
        accordions: [],
      },

      {
        label: "Kimyasallar",
        accordions: [],
      },
    ],
  },
];

export default data;
