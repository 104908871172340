import React from "react";
import Carousel from "../components/Carousel";
import SliderCards from "../components/SliderCards";
import SliderNews from "../components/SliderNews";
import SliderBrands from '../components/SliderBrands'

const Home = () => {
  return (
    <div>
      
      <div>
        <Carousel />
      </div>
      <div  className='flex justify-center items-center '>
        <SliderNews />
      </div>
      
      <div  className='flex justify-center items-center '>
        <SliderCards />
      </div>
      <div  className='flex justify-center items-center '>
        <SliderBrands />
      </div>
    </div>
  );
};

export default Home;
