import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

import image1 from "../img/brands/1.jpg";
import image2 from "../img/brands/2.jpg";
import image3 from "../img/brands/3.jpg";
import image4 from "../img/brands/4.jpg";
import image5 from "../img/brands/5.jpg";
import image6 from "../img/brands/6.jpg";
import image7 from "../img/brands/7.jpg";
import image8 from "../img/brands/8.jpg";
import image9 from "../img/brands/9.jpg";
import image10 from "../img/brands/10.jpg";
import image11 from "../img/brands/11.jpg";

const cards = [
  {
    id: 1,
    image: image1,
    link: "/marka-yucel",
  },
  {
    id: 2,
    image: image2,
    link: "/marka-boytek",
  },
  {
    id: 3,
    image: image3,
    link: "/marka-yucel",
  },
  {
    id: 4,
    image: image4,
    link: "/marka-ilkester",
  },
  {
    id: 5,
    image: image5,
    link: "/marka-lantor",
  },
  {
    id: 6,
    image: image6,
    link: "/marka-magnum-venus",
  },
  {
    id: 7,
    image: image7,
    link: "/marka-metyx",
  },
  {
    id: 8,
    image: image8,
    link: "/marka-yucel",
  },
  {
    id: 9,
    image: image9,
    link: "/marka-camelyaf",
  },
  {
    id: 10,
    image: image10,
    link: "/marka-turkuaz",
  },
  {
    id: 11,
    image: image11,
    link: "/marka-tissa",
  },
];

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const SliderCards = () => {
  const [current, setCurrent] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const breakpoint = 768;

  const handleNextSlide = useCallback(() => {
    if (window.innerWidth < breakpoint) {
      setCurrent((prev) => (prev >= cards.length - 2 ? 0 : prev + 1));
    } else {
      setCurrent((prev) => (prev >= cards.length - 5 ? 0 : prev + 1));
    }
  }, []);

  const handlePrevSlide = useCallback(() => {
    if (window.innerWidth < breakpoint) {
      setCurrent((prev) => (prev === 0 ? cards.length - 2 : prev - 1));
    } else {
      setCurrent((prev) => (prev === 0 ? cards.length - 5 : prev - 1));
    }
  }, []);

  useEffect(() => {
    const resizeListener = () => {
      const containerWidth = document.querySelector(".slider-container")
        .offsetWidth;
      setCardWidth(
        containerWidth < breakpoint ? containerWidth : containerWidth / 5
      );
    };

    resizeListener();
    window.addEventListener("resize", resizeListener);

    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        handleNextSlide();
      } else if (event.key === "ArrowLeft") {
        handlePrevSlide();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNextSlide, handlePrevSlide]);

  return (
    <div className="relative w-full p-8 max-w-[88rem] mx-auto h-full font-sans overflow-hidden">
      <h1 className="text-3xl m-6 font-bold border-[#373A40] border-b-4 text-[#686D76]">
        Markalarımız
      </h1>

      <div className="slider-container relative">
        <button
          onClick={handlePrevSlide}
          className="absolute left-4 md:left-4 z-10 p-2 md:p-3 bg-red-500 text-white top-0  transform -translate-y-1/2 rounded-full shadow-lg hover:bg-orange-700 transition duration-300"
        >
          <FaArrowLeft size={16} />
        </button>
        <button
          onClick={handleNextSlide}
          className="absolute left-16 md:left-20 z-10 p-2 md:p-3 bg-red-500 text-white top-0  transform -translate-y-1/2 rounded-full shadow-lg hover:bg-orange-700 transition duration-300"
        >
          <FaArrowRight size={16} />
        </button>
        <div
          className="flex h-full transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${
              current * (cardWidth < breakpoint ? cardWidth : cardWidth / 3)
            }px)`,
          }}
        >
          {cards.map((card) => (
            <Link
              to={card.link}
              key={card.id}
              className="w-full mt-6 sm:w-1/3 md:w-1/4 lg:w-1/4 flex-shrink-0 flex items-start  justify-center px-2"
              style={{ width: `${cardWidth}px`, minWidth: `${cardWidth}px` }}
              onClick={scrollToTop}
            >
              <motion.div
                className="items-center flex flex-col shadow-black text-white p-3 md:p-4 rounded-3xl shadow-md font-sans w-72 mx-2"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.05 }}
              >
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-[20rem] h-auto object-fill drop-shadow-4xl shadow-black shadow-lg rounded-3xl"
                />
              </motion.div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SliderCards;
