import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import SliderCards from "../../components/SliderCards";

const Video = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const variants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 md:p-8 lg:p-16">
        <h2 className="text-2xl bg-red-500 p-4 rounded-xl text-white font-bold mb-6 text-center">
          Magnum Venus Products Genel Tanıtım
        </h2>
      <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
        <main className="p-4 md:p-8">
          <motion.div
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={variants}
            className="space-y-6 text-gray-700"
          >
            <div className="relative pt-[56.25%] mb-8">
              <iframe
                className="absolute top-0 left-0 w-full h-full rounded-lg"
                src="/video/video-magnum-venus-gelcoat-systems.mp4"
                title="Video"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </motion.div>
          <motion.div
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={variants}
            className="space-y-9 text-gray-700"
          >
            <p className="text-sm md:text-md">
              Yücel Kompozit, Magnum Venus Products (MVP) Türkiye distribütörü
              olarak kompozit sektörüne dünya standartlarında makina, ekipman ve
              yedek parça desteği sunuyor.
            </p>
            <p className="text-sm md:text-md">
              1938 yılında otomotiv kaplama ve sprey ekipmanları üretmek üzere
              kurulan, 1950’lerde Amerika’nın önde gelen püskürtme ekipmanı
              üreticilerinden biri haline gelen Magnum Industries, 1970’lerden
              itibaren CTP sektörüne yönelik yatırımlarıyla dünya çapında ününü
              sürdürdü.
            </p>
            <p className="text-sm md:text-md">
              2000 yılında Amerika’nın ünlü CTP hidrolik enjeksiyon sistemleri
              üreticisi Venus Products ile gerçekleşen birleşme sonucu Magnum
              Venus Products doğdu.
            </p>
            <p className="text-sm md:text-md">
              CTP sektörüne birçok özgün, patentli uygulama ürünü ile önemli
              yenilikler getiren MVP, dünyanın önde gelen üreticilerinden
              biridir.
            </p>
            <p className="text-sm md:text-md">
              Dünyanın lider CTP üreticileri tarafından tercih edilen MVP
              ürünleri Türkiye’de Yücel Kompozit tarafından temsil ediliyor..
            </p>
            
          </motion.div>
        </main>
      </div>
      <div className="flex justify-center items-center">
        <SliderCards />
      </div>
    </div>
  );
};

export default Video;
