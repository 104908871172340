// src/components/TTissaPage.jsx
import React from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import SliderCards from "../../components/SliderCards";


import img1 from "../../img/ttissa/640-tissa-matcut-capture-02.jpg";
import img2 from "../../img/ttissa/640-tissa-matcut-general-01.jpg";
import img3 from "../../img/ttissa/640-tissa-matcut-combined-01.jpg";



const StyledContainer = styled(Container)({
  backgroundColor: "#f9f9f9",
  padding: "2rem",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const animationVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const AnimatedComponent = ({ children }) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={animationVariants}
    >
      {children}
    </motion.div>
  );
};

const TTissaPage = () => {
  return (
    <div className="mt-3">
      <StyledContainer>
        <AnimatedComponent>
          <h1 className="text-2xl bg-red-500 p-4 rounded-xl mt-2  text-white font-bold mb-6 text-center">
            TISSA MATCUT 700 Cam elyaf rulo kesim makinası
          </h1>
        </AnimatedComponent>
        <div className="flex flex-col gap-8 mb-8">
          <AnimatedComponent>
            <div className="flex flex-col md:flex-row gap-8">
              <img
                src={img2}
                alt="Placeholder 1"
                className="rounded-lg w-[25rem] shadow-lg md:w-1/2"
              />
              <TableContainer component={Paper} className="md:w-1/2">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Avantajlar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Kestirilmiş malzemeyi stoklamaya gerek kalmaması
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Stoklama yapmamadan doğan maliyet avantajı
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        50 mm’ye kadar her istenen kalınlıkta kesim yapabilme
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Optimize edilmiş genişlik dolayısıyla zamandan tasarruf
                      </TableCell>
                    </TableRow>
                    {/* Add more rows as needed */}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </AnimatedComponent>
          <AnimatedComponent>
            <Typography variant="h4" component="h2" className="my-4">
              İstediğiniz zaman, istediğiniz genişlikte kesim yapın
            </Typography>
          </AnimatedComponent>
          <AnimatedComponent>
            <Typography  variant="body1" component="p" className="mb-8">
              Cam elyafı ruloları, kırpılmış elyaf keçeleri, dokunmuş
              malzemeler, emprenye malzemeler, sentetik malzemeler genel olarak
              standart genişlikte rulolar halinde teslim ediliyor.
            </Typography>
          </AnimatedComponent>
          <AnimatedComponent>
            <Typography variant="body1" component="p" className="mb-8">
              Oysa kompozit üreticileri genellikle özel genişlikte siparişler
              alıyor ve imalatlar yapıyorlar.
            </Typography>
          </AnimatedComponent>
          <AnimatedComponent>
            <Typography variant="body1" component="p" className="mb-8">
              MATCUT 700 rulların elle kesilmesi sırasında ortaya çıkan zaman
              kaybı ve hassasiyet sorunlarını gideriyor.
            </Typography>
          </AnimatedComponent>
          <AnimatedComponent>
            <Typography variant="body1" component="p" className="mb-8">
              Saniyeler içinde, büyük kolaylıkla yapılan kesim tam gereken
              boyutta elde ediliyor.
            </Typography>
          </AnimatedComponent>
          <AnimatedComponent>
            <div className="flex flex-col md:flex-row-reverse gap-8">
              <img 
                src={img3}
                alt="Placeholder 2"
                className="rounded-lg shadow-lg  md:w-[70rem]  md:h-[40rem]"
              />
              <TableContainer component={Paper} className="md:w-1/2">
                <Table >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={3} sx={{ p: 0 }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "24px",
                            textAlign: "center",
                            borderBottom: "2px solid #000",
                            padding: "16px 0",
                            backgroundColor: "#f0f0f0",
                          }}
                          variant="h6"
                          id="tableTitle"
                          component="div"
                        >
                          Teknik Özellikler
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                      >
                        Özellik
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                      >
                        Açıklama
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{fontWeight : "500" }}>Kesim süresi</TableCell>
                      <TableCell sx={{fontWeight : "500" }}>
                        Rulo çapına ve kora bağlı olarak 30 - 120 saniye
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight : "500" }}>Minimum genişlik</TableCell>
                      <TableCell sx={{fontWeight : "500" }}>50 mm</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight : "500" }}>Minimum genişlik</TableCell>
                      <TableCell sx={{fontWeight : "500" }}>Hassas kesim ünitesiyle 50 mm</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight : "500" }}>Kalite koşulları</TableCell>
                      <TableCell sx={{fontWeight : "500" }}>
                        Dar kesimde rulo sertliği önemlidir. Daha sert rulolarda
                        daha yüksek kesim kalitesi elde edilir.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight : "500" }}>Maksimum çap</TableCell>
                      <TableCell sx={{fontWeight : "500" }}>
                        700 mm Rulo uzunluğu konfigürasyona göre değişebilir.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight : "500" }}>Boyutlar</TableCell>
                      <TableCell sx={{fontWeight : "500" }}>127 (d) x 85 (g) x 132 (y) cm</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight : "500" }}>Önemli</TableCell>
                      <TableCell sx={{fontWeight : "500" }}>
                        Makine kesim yaparken duvardan en az 75 cm uzakta
                        olmalıdır
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight : "500" }}>Elektrik bilgisi</TableCell>
                      <TableCell sx={{fontWeight : "500" }}>
                        380 V (trifaze) - 1 kW. Makina 220 V gerilimle de
                        çalıştırılabilir.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight : "500" }}>Minimum genişlik</TableCell>
                      <TableCell sx={{fontWeight : "500" }}>50 mm</TableCell>
                    </TableRow>
                    {/* Add more rows as needed */}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </AnimatedComponent>
          <AnimatedComponent>
          <h1 className="text-xl bg-red-500 p-4 rounded-xl mt-2  text-white font-bold mb-6 text-start">
            Kesim Yaparken
          </h1>
        </AnimatedComponent>
          <AnimatedComponent>
            <div className="flex flex-col md:flex-row gap-8">
              <img
                src={img1}
                alt="Placeholder 3"
                className="rounded-lg shadow-lg md:w-1/3 object-fill"
              />
              
            </div>
          </AnimatedComponent>
        </div>
      </StyledContainer>
      <SliderCards />
    </div>
  );
};

export default TTissaPage;
