import React from "react";
import img from "../img/1.png";

const Footer = () => {
  return (
    <footer className="bg-gray-800 py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
     
        <div className="flex items-center justify-center sm:col-span-1 lg:col-span-1">
          <img src={img} alt="Logo" className="h-12 w-auto bg-white rounded-3xl" />
        </div>

        <div className="text-white sm:col-span-1 lg:col-span-1">
          <h3 className="text-lg font-semibold mb-1">Merkez - İzmir Kemalpaşa</h3>
          <p className="text-gray-300 text-sm">Kemalpaşa Caddesi No:276 Pınarbaşı, 35060 İzmir - Türkiye</p>
          <p className="text-gray-300 text-sm">☎ 0232 479 26 60</p>
        </div>

        <div className="text-white sm:col-span-1 lg:col-span-1">
          <h3 className="text-lg font-semibold mb-1">İzmir İnşaatçılar Çarşısı</h3>
          <p className="text-gray-300 text-sm">Atatürk Ticaret Merkezi İnşaatçılar Çarşısı 1202/1 Sokak No:13 Yenişehir, 35110 İzmir - Türkiye</p>
          <p className="text-gray-300 text-sm">☎ 0232 433 60 36 - 458 71 92</p>
        </div>

        <div className="text-white sm:col-span-1 lg:col-span-1">
          <h3 className="text-lg font-semibold mb-1">Bursa Osmangazi</h3>
          <p className="text-gray-300 text-sm">Veysel Karani Mahallesi Eski Gemlik Yolu Caddesi No:236-C Osmangazi, 16250 Bursa - Türkiye</p>
          <p className="text-gray-300 text-sm">☎ 0224 216 16 88</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
