// src/components/RTM.jsx
import React from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import SliderCards from "../../components/SliderCards";

import img2 from "../../img/tpolia/patci/rtm/400-logo-ultramax (1).jpg";
import img1 from "../../img/tpolia/patci/rtm/600-ultramax-rtm-01.jpg";
import img3 from "../../img/tpolia/patci/rtm/600-ultramax-rtm-aksesuar-01.jpg";

const StyledContainer = styled(Container)({
  backgroundColor: "#f9f9f9",
  padding: "2rem",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const animationVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const AnimatedComponent = ({ children }) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={animationVariants}
    >
      {children}
    </motion.div>
  );
};

const RTM = () => {
  return (
    <div className="mt-3">
      <StyledContainer>
        <AnimatedComponent>
          <h1 className="text-2xl bg-red-500 p-4 rounded-xl mt-2  text-white font-bold mb-6 text-center">
          UltraMAX™ RTM sistem
          </h1>
        </AnimatedComponent>
        <div className="flex flex-col gap-8 mb-8">
          <AnimatedComponent>
            <div className="flex flex-col md:flex-row gap-8">
              <img
                src={img1}
                alt="Placeholder 1"
                className="rounded-lg w-[25rem] object-fill shadow-lg md:w-1/2"
              />
              <TableContainer component={Paper} className="md:w-1/2">
                <img
                  src={img2}
                  alt="Placeholder 1"
                  className="rounded-lg w-[36rem] h-32 shadow-lg ml-0 md:ml-36 md:w-1/2"
                />
                <Table sx={{ height: "24px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{fontWeight: ""}} >Özellik</TableCell>
                      <TableCell>Açıklama</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{fontWeight: ""}} >Çıkış kapasitesi</TableCell>
                      <TableCell>
                      0,9 - 5,4 kg/dakika (Reçine özellikleri ve ortam sıcaklığına göre değişir)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight: ""}} >MEK-P kapasitesi</TableCell>
                      <TableCell>
                      Hacim olarak %0,75 - %3 (Çalışma ve ortam sıcaklığına göre)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight: ""}} >Hava tüketimi</TableCell>
                      <TableCell>170 l / dakika</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight: ""}} >Pompa oranları</TableCell>
                      <TableCell>6 : 1 (Standart) 11 : 1 (Opsiyonel)</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight: ""}} >Hortum uzunluğu</TableCell>
                      <TableCell>9 m</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </AnimatedComponent>
          
          <AnimatedComponent>
            <Typography variant="body1" component="p" className="mb-8">
            Magnum Venus Plastech RTM Ünitesi, UltraMAX™ pompa sistemiyle kesintisiz bir reçine - katalizör karışım olanağı sunar. Hatasız MEK-P oranlayıcı ve patentli karışım haznesi mükemmel karışımı gerçekleştirerek en uygun kürleşmeyi sağlar. Bu sayede üretim süresi kısalır, üretim miktarı artar.
            </Typography>
          </AnimatedComponent>
          <AnimatedComponent>
            <div className="flex flex-col md:flex-row-reverse gap-8">
              <img
                src={img3}
                alt="Placeholder 2"
                className="rounded-lg shadow-lg  md:w-[70rem]  md:h-[40rem]"
              />
              <TableContainer component={Paper} className="md:w-1/2">
                <Table>
                  <TableHead>
                    <TableRow>
                      
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                      >
                        Özellik
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                      >
                        Yararlar
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>
                      Kolay kullanım ve bakım
                      </TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>
                      UltraMAX™ RTM ünitesinin tabancası az sayıdaki parçasıyla kullanıcı dostudur.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>
                      Kesintisiz reçine akışı
                      </TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>
                      Reçine ve MEK-P pompaları sürekli materyal akışı sağlar. Titreşimsiz pompa yapısı basıncı düzenler. Sabit basınç sayesinde kalıp deformasyonu olmaz, ürün kalitesinde süreklilik sağlanır. 
                       </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>
                      Büyük parçalar için ideal
                      </TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>
                      High volume reçine pompası, düşük viskoziteli reçine kullanılan hızlı, sürekli ve düşük basınçlı enjeksiyon gerektiren geniş kalıp uygulamaları için uygundur. Takviyelendirilmiş ek parçaları ile dolgulu uygulamalar yapılabilir.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>
                      Direkt reçine emiş pistonu
                      </TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>
                      Sistemin hava pistonu varil veya konteynırdan seçine emer. Pompa pistonu ve reçine girişi tamamen reçine içine girerek hava oluşmasını engeller.
                      </TableCell>
                    </TableRow>
                    
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </AnimatedComponent>

          
        </div>
      </StyledContainer>
      <SliderCards />
    </div>
  );
};

export default RTM;
