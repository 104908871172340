import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import images from "../../img/slider-yucel-kompozit-stoktan-teslim-1888.jpg";

const YCLabout = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <div>
      <motion.div
        initial="hidden"
        animate={controls}
        variants={sectionVariants}
        className="relative"
      >
        <img
          src={images}
          alt="Resim açıklaması"
          className="w-full h-64 sm:h-96 md:h-full object-cover"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <motion.div
            initial="hidden"
            animate={controls}
            variants={sectionVariants}
            className="text-center bg-black bg-opacity-50 p-4 sm:p-8 text-white"
          >
            <h1 className="text-2xl sm:text-4xl font-bold mb-3">
              Kompozit Sektörüne
            </h1>
            <h2 className="text-xl sm:text-3xl">Sürekli Destek</h2>
          </motion.div>
        </div>
      </motion.div>

      <div className="bg-gray-100 flex flex-col items-center py-12">
        <motion.h1
          initial="hidden"
          animate={controls}
          variants={sectionVariants}
          className="text-4xl bg-red-500 p-4 rounded-xl text-white font-bold text-center"
        >
          Hakkımızda...
        </motion.h1>
      </div>

      <div className="bg-gray-100 min-h-screen flex flex-col items-center py-12 px-4 sm:px-8">
        <div ref={ref} className="bg-white max-w-4xl w-full shadow-lg rounded-lg p-4 sm:p-8">
          <motion.div
            initial="hidden"
            animate={controls}
            variants={sectionVariants}
            className="mb-16 mt-24"
          >
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              İlk günkü ruhla…
            </h2>
            <p className="text-gray-600 text-lg leading-relaxed">
              Kurucumuzun 1949’lardaki yenilikçi adımları bugün de grubumuz ve Yücel Kompozit bünyesinde kesintiye uğramadan sürüyor.
              1983’e kadar özellikle pazarlama alanına odaklanan yapımızla Türk kompozit sektörünün hemen hemen tüm tarihçesi içinde yer almaktan büyük mutluluk duyuyoruz.
            </p>
          </motion.div>

          <motion.div
            initial="hidden"
            animate={controls}
            variants={sectionVariants}
            className="mb-8"
          >
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">Misyonumuz</h2>
            <p className="text-gray-600 text-lg leading-relaxed">
              Misyonumuz, müşteri odaklı bir yaklaşımla hizmetlerimizi sürekli geliştirmek ve sektörde güvenilir bir marka
              olarak yerimizi korumaktır. Toplumun ve çevrenin yararına çalışmalar yaparak, sürdürülebilir bir gelecek
              için katkıda bulunmayı amaçlıyoruz.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default YCLabout;
