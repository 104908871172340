import React from 'react';
import AnimatedSection from './AnimatedSection';

const MultiColumnTable = ({ heading, rows, description }) => {
  return (
    <AnimatedSection>
      <div className="bg-white shadow-md rounded p-4 mb-8">
        <h2 className="text-base font-bold mb-4 text-start">{heading}</h2>
        <table className="w-full border-collapse">
          <tbody>
            {rows.map((row, index) => (
              <tr key={index} className="border-t border-gray-200">
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} className="p-2 border-y text-xs border-gray-200 text-start">
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <p className="text-xs font-semibold ml-3 mt-2">{description}</p>
      </div>
    </AnimatedSection>
  );
};

export default MultiColumnTable;
