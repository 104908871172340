const data = [
    {
      sectionLabel:
        "Oda ısısı ve yüksek sıcaklık için ürünler",
      tabs: [
        {
          accordions:[
            {
              title: "Hızlandırıcılar - Kobalt Oktoat",
              rows: [
                {
                  name: "Kobalt %1'lik",
                  description: "%1 Kobalt oktoat çözeltisi"
                },
                {
                  name: "Kobalt %6'lik",
                  description: "%6 Kobalt oktoat çözeltisi"
                },
                {
                  name: "Kobalt %8'lik",
                  description: "%8 Kobalt oktoat çözeltisi"
                }
              ]
            },
            {
              title: "Hızlandırıcılar - Şeffaf",
              rows: [
                {
                  name: "Hızlandırıcı 101",
                  description: "Şeffaf döküm uygulama için %1'lik hızlandırıcı"
                }
              ]
            },
            {
              title: "Hızlandırıcılar - DMA",
              rows: [
                {
                  name: "Pergaquick A200",
                  description: "Dimetil anilin"
                }
              ]
            },
            {
              title: "Sertleştiriciler - Oda sıcaklığı",
              rows: [
                {
                  name: "Yücel MKP-60",
                  description: "MEK-P - metil etil keton peroksit"
                },
                {
                  name: "Butanox M-50",
                  description: "MEK-P - metil etil keton peroksit"
                },
                {
                  name: "Butanox M-60",
                  description: "MEK-P - metil etil keton peroksit"
                },
                {
                  name: "Luperox K10",
                  description: "MEK-P - metil etil keton peroksit"
                },
                {
                  name: "Akperox A-60",
                  description: "MEK-P - metil etil keton peroksit"
                },
                {
                  name: "Luperox K1G",
                  description: "MEK-P - Ftalatsız düğme için metil etil keton peroksit"
                },
                {
                  name: "Trigonox 63",
                  description: "MEK-P + AAP karışımı - metil etil keton peroksit + asetilaseton peroksit"
                },
                {
                  name: "Luperox Z11-G",
                  description: "Ftalatsız MEK-P + AAP karışımı"
                },
                {
                  name: "Trigonox 44-B",
                  description: "AAP - Asetilaseton peroksit"
                },
                {
                  name: "Luperox K3",
                  description: "AAP - Asetilaseton peroksit"
                }
              ]
            },
            {
              title: "Sertleştiriciler - Oda ve yüksek sıcaklık",
              rows: [
                {
                  name: "Trigonox 239",
                  description: "KHP - Kümil hidroperoksit"
                },
                {
                  name: "Peroxan BP Paste 50PF",
                  description: "BPO Pasta - Dibenzoil peroksit"
                }
              ]
            },
            {
              title: "Sertleştiriciler - Yüksek sıcaklık (sıcak kalıplama sistemleri)",
              rows: [
                {
                  name: "Trigonox C",
                  description: "TBPO - terbütilperoksibenzoat"
                },
                {
                  name: "Trigonox 29-C50",
                  description: "DTBP + TMSH Karışımı - 1,1-D(terbutilperoksit) + 3,3,5 trimetilsiklohekzan"
                },
                {
                  name: "Peroxan PK-295V",
                  description: "DTBP + TMSH Karışımı - 1,1-D(terbutilperoksit) + 3,3,5 trimetilsiklohekzan"
                }
              ]
            },
            {
              title: "Sıcak kalıplama sistemleri için katkılar",
              rows: [
                {
                  name: "LSA",
                  description: "Sıcak kalıplama sistemleri için polistiren çözeltisi"
                },
                {
                  name: "LSB",
                  description: "Sıcak kalıplama sistemleri için PVA çözeltisi"
                },
                {
                  name: "LSC",
                  description: "Sıcak kalıplama sistemleri için PVA çözeltisi"
                },
                {
                  name: "LSU",
                  description: "Sıcak kalıplama sistemleri için doymuş polyester"
                }
              ]
            },
            {
              title: "Katkılar (aditifler)",
              rows: [
                {
                  name: "Yardımcı 2-20",
                  description: "İnhibitör Cozeltisi (%20)"
                },
                {
                  name: "Yardımcı 21",
                  description: "UV Stabilizator"
                },
                {
                  name: "Yardımcı 22",
                  description: "UV Stabilizator"
                },
                {
                  name: "Yardımcı 46",
                  description: "Köpük kesici"
                },
                {
                  name: "Yardımcı 50",
                  description: "Yüzey katkısı"
                },
                {
                  name: "Yardımcı 81",
                  description: "Reolojik katkı"
                },
                {
                  name: "Yardımcı A-8",
                  description: "Promotör"
                },
                {
                  name: "Yardımcı T-12",
                  description: "Reolojik katkı"
                },
                {
                  name: "Parafin Çözeltisi",
                  description: "Parafin çözeltisi (%10)"
                },
                {
                  name: "BRI C10",
                  description: "İnhibitör çözeltisi"
                },
                {
                  name: "BRI P10",
                  description: "SMC, BMC ve Pultrüzyon için inhibitör"
                }
              ]
            }
          ]
          
          
        },
     
      ],
    },
  ];
  
  export default data;
  