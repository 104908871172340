import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

import image1 from '../img/videos/img/420-video-index-magnum-venus-at-serisi-tabanca-bakim.jpg';
import image2 from '../img/videos/img/420-video-index-magnum-venus-cpr-serisi-tabanca-bakim.jpg';
import image3 from '../img/videos/img/420-video-index-magnum-venus-klasik-pro-tabanca-bakim.jpg';
import image4 from '../img/videos/img/420-video-index-magnum-venus-talon-internal-tabanca-bakim.jpg';
import image5 from '../img/videos/img/420-video-index-magnum-venus-talon-external-tabanca-bakim.jpg';
import image6 from '../img/videos/img/420-video-index-lantor-coremat-3d-uygulama-2018.jpg';
import image7 from '../img/videos/img/420-video-index-lantor-coremat-kolay-birlesme-2018.jpg';
import image8 from '../img/videos/img/420-video-index-lantor-soric-vakum-infuzyon-2018.jpg';
import image9 from '../img/videos/img/420-video-index-lantor-coremat-uygulama-rehberi-2022.jpg';
import image10 from '../img/videos/img/420-video-index-lantor-turk-kompozit-zirvesi-sunum-2017.jpg';
import image11 from '../img/videos/img/420-video-index-magnum-venus-uygulama.jpg';

const cardGroups = [
  {
    title: "Ürünler, demolar, teknik bilgiler, sunumlar",
    cards: [
      {
        id: 1,
        title: `Magnum Venus Products® AT Serisi bakım, onarım`,
        content: `ATC ve ATG serisi tabancaların bakım ve onarımı için temel bilgiler`,
        image: image1,
        link: "/video-yucel-kompozit-magnum-venus-at-tabanca-bakim"
      },
      {
        id: 2,
        title: `Magnum Venus Products® CPR Serisi bakım, onarım`,
        content: `CPR serisi tabancaların bakım ve onarımı için temel bilgiler`,
        image: image2,
        link: "/video-yucel-kompozit-magnum-venus-cpr-tabanca-bakim"
      },
      {
        id: 3,
        title: `Magnum Venus Products® Klasik Pro Serisi bakım, onarım`,
        content: `Klasik Pro serisi tabancaların bakım ve onarımı için temel bilgiler`,
        image: image3,
        link: "/video-yucel-kompozit-magnum-venus-klasik-pro-tabanca-bakim"
      },
      {
        id: 4,
        title: `Magnum Venus Products® Internal Mix Serisi bakım, onarım`,
        content: `Internal Mix (Dahili Karışım) serisi Talon tabancaların bakım ve onarımı için temel bilgiler`,
        image: image4,
        link: "/video-yucel-kompozit-magnum-venus-talon-dahili-tabanca-bakim"
      },
      {
        id: 5,
        title: `Magnum Venus Products® External Mix Serisi bakım, onarım`,
        content: `External Mix (Harici Karışım) serisi Talon tabancaların bakım ve onarımı için temel bilgiler`,
        image: image5,
        link: "/video-yucel-kompozit-magnum-venus-talon-harici-tabanca-bakim"
      },
      {
        id: 6,
        title: `Coremat® Xi - XM üç boyutlu uygulama`,
        content: `Coremat® Xi - XM açık kalıp uygulamalarında üç boyutlu geometriye uyum kalitesini izleyin.`,
        image: image6,
        link: "/video-yucel-kompozit-lantor-coremat-xm-xi-3-boyutlu-demo"
      },
      {
        id: 7,
        title: `Coremat® Xi - XM kolay birleşme`,
        content: `Coremat® Xi - XM açık kalıp uygulamalarında uygulama kolaylığı ve birleşme kusursuzluğunu izleyin.`,
        image: image7,
        link: "/video-yucel-kompozit-lantor-coremat-xm-xi-kolay-birlesme"
      },
      {
        id: 8,
        title: `Soric® ile vakum infüzyon`,
        content: `Soric® ailesinin vakum infüzyondaki benzersiz performansını izleyin.`,
        image: image8,
        link: "/video-yucel-kompozit-lantor-soric-vakum-infuzyon"
      },
      {
        id: 9,
        title: `Coremat® Uygulama Rehberi 2022`,
        content: `Coremat® ile çalışmanın bütün aşamalarını ve ipuçlarını izleyin.`,
        image: image9,
        link: "/video-yucel-kompozit-lantor-coremat-uygulama-rehberi-2022"
      },
      {
        id: 10,
        title: `Soric® Türk Kompozit Zirvesi 2017`,
        content: `Soric® ailesinin ayrıntılı teknik bilgilerini ve demonstrasyonlarını Türk Kompozit Zirvesi etkinliğimizde Ward Steijn’den izleyin.`,
        image: image10,
        link: "/video-yucel-kompozit-lantor-soric-sunum"
      },
      {
        id: 11,
        title: `Magnum Venus® jelkot sistemleri`,
        content: `Magnum Venus® ürünlerinin genel tanıtımını izleyin.`,
        image: image11,
        link: "/video-magnum-venus-gelcoat-systems"
      },
    ],
  },
];

const Subpage2 = () => {
  return (
    <div className="mt-2 relative cursor-default w-full">
      <div className="container  mx-auto mt-10 px-4">
        {cardGroups.map((group, index) => (
          <div key={index} className="mb-16 ">
            <h2 className="text-2xl bg-red-500 p-4 rounded-xl text-white  font-bold mb-6 text-center">{group.title}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 ">
              {group.cards.map((card) => (
                <Card key={card.id} card={card} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Card = ({ card }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={cardVariants}
      className=" text-white w-[26rem] h-auto bg-red-400 shadow-xl shadow-slate-800 rounded-3xl p-4 mt-9  transform transition duration-300 hover:scale-105"
    >
      <Link to={card.link}>
        <img
          src={card.image}
          alt={card.title}
          className="w-96 h-80 shadow-black shadow-lg object-fill rounded-2xl mb-3"
        />
        <h3 className="text-xl  font-bold mb-2">{card.title}</h3>
        <p className="text-base mb-7">
          {card.content.split('\n').map((str, index) => (
            <React.Fragment key={index}>
              {str}
              <br />
            </React.Fragment>
          ))}
        </p>
      </Link>
    </motion.div>
  );
};

export default Subpage2;
