import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

// Resimler
import image1 from '../img/caro/12.jpg';
import image2 from '../img/caro/13.gif';
import image3 from '../img/caro/14.jpg';
import image4 from '../img/caro/1.jpg';
import image5 from '../img/caro/2.jpg';
import image6 from '../img/caro/33.jpg';
import image7 from '../img/caro/4.jpg';
import image8 from '../img/caro/5.jpg';
import image9 from '../img/caro/6.jpg';
import image10 from '../img/caro/7.jpg';
import image11 from '../img/caro/8.jpg';
import image12 from '../img/caro/9.jpg';
import image13 from '../img/caro/10.jpg';
import image14 from '../img/caro/11.jpg';

const images = [
  { src: image1, title1: 'İzlemek için Tıklayın',title2: '',  link: '/video-yucel-kompozit-lantor-coremat-uygulama-rehberi-2022' },
  { src: image2, title1: 'Lider markalar birarada', title2: '', link: '/marka-yucel' },
  { src: image3, title1: 'Teknik Destek', title2: 'Kapalı Kalıp Uygulamaları bilgileri için tıklayınız...', link: '/download' },
  { src: image4, title1: 'Üreticilere toptan,', title2: 'kullanıcılara perakende', link: '/usage' },
  { src: image5, title1: 'YCL kimyasallarımız bir tık ötenizde', title2: 'Alt Başlık 5', link: '/marka-yucel' },
  { src: image6, title1: 'Teknik Destek', title2: 'Türkçe kılavuzlar, rehberler için tıklayın', link: '/download' },
  { src: image7, title1: 'Ürünlerin teknik bilgileri', title2: 'bir tık ötenizde', link: '/link7' },
  { src: image8, title1: 'Magnum Venus', title2: 'tabanca bakım ve onarım videoları tıklayın...', link: '/videos' },
  { src: image9, title1: 'Kaçırmayın !..', title2: 'videolar için tıklayın', link: '/videos' },
  { src: image10, title1: 'CTP kullanım alanları için ', title2: 'tıklayın...', link: '/usage' },
  { src: image11, title1: 'e-tahsilat için...', title2: 'tıklamanız yeterli', link: '/contact' },
  { src: image12, title1: 'tüm ihtiyaçlarınız için', title2: 'güçlü stoklar', link: '/contact' },
  { src: image13, title1: 'makina ve ekipmanda dünya markalarısatış,', title2: 'destek ve bakım hizmetleri', link: '/contact' },
  { src: image14, title1: 'ekibimize kolayca ulaşın', title2: '', link: '/hak-yucel-kompozit-ekip' },
];

const carouselVariants = {
  enter: direction => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: direction => ({
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
};

const titleVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const Carousel = () => {
  const [[current, direction], setCurrent] = useState([0, 0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent([current === images.length - 1 ? 0 : current + 1, 1]);
    }, 8000);
    return () => clearInterval(interval);
  }, [current]);

  const nextSlide = () => {
    setCurrent([current === images.length - 1 ? 0 : current + 1, 1]);
  };

  const prevSlide = () => {
    setCurrent([current === 0 ? images.length - 1 : current - 1, -1]);
  };

  return (
    <div className="relative w-auto mx-auto  h-[70vh] overflow-hidden">
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={current}
          custom={direction}
          variants={carouselVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: 2 }}
          className="absolute w-full h-full"
        >
          <Link to={images[current].link}>
            <img src={images[current].src} alt="" className="w-full h-full object-fill" />
            <motion.div
              className="absolute inset-0 flex flex-col items-center justify-center text-white"
              initial="hidden"
              animate="visible"
              transition={{ delay: 1, duration: 2 }}
              variants={titleVariants}
            >
              <h1 className="text-2xl sm:text-3xl md:text-4xl rounded-md font-semibold bg-red-500 p-3 ">{images[current].title1}</h1>
              <h2 className="text-lg sm:text-xl md:text-2xl mt-2 rounded-md font-semibold bg-slate-500 p-3">{images[current].title2}</h2>
            </motion.div>
          </Link>
        </motion.div>
      </AnimatePresence>
      <button
        onClick={prevSlide}
        className="absolute left-2 sm:left-4 top-1/2 transform -translate-y-1/2 bg-[#686D76] text-white rounded-full p-2"
      >
        ❮
      </button>
      <button
        onClick={nextSlide}
        className="absolute right-2 sm:right-4 top-1/2 transform -translate-y-1/2 bg-[#686D76] text-white rounded-full p-2"
      >
        ❯
      </button>
    </div>
  );
};

export default Carousel;
