const data = [
    {
      sectionLabel: "Katı, sıvı ve sprey kalıp ayırıcılar, kalıp hazırlığı için özel ürünler ",
      tabs: [
        {
          label: "Boytek",
          accordions: [
            {
              title: "Vakslar",
              rows: [
                {
                  name: "A VAKS®",
                  description: "Kalıp ayırıcı"
                }
              ]
            },
            {
              title: "Sıvı kalıp ayırıcılar",
              rows: [
                {
                  name: "PVA",
                  description: "Sıvı kalıp ayırıcı"
                },
                {
                  name: "PVA Kırmızı",
                  description: "Sıvı kalıp ayırıcı, kırmızı"
                }
              ]
            },
            {
              title: "Sprey kalıp ayırıcılar",
              rows: [
                {
                  name: "A VAKS Sprey",
                  description: "Sprey kalıp ayırıcı"
                }
              ]
            },
            {
              title: "Kalıp sistemleri",
              rows: [
                {
                  name: "Yeni - BRE 910",
                  description: "Sıfır çekmeli polyester"
                },
                {
                  name: "Yeni - BRE 390 F/P",
                  description: "Özel kalıp jelkotu"
                },
                {
                  name: "Yeni - BRE 790 T",
                  description: "Skin coat reçinesi"
                }
              ]
            },
            {
              title: "Kalıp temizleyiciler",
              rows: [
                {
                  name: "Yeni - Kalıp temizleyici",
                  description: ""
                }
              ]
            }
            
            

            

            
            
          ]
        },
        
        

        {
          label: "Poliya",
          accordions: [
            {
              title: "Vakslar",
              rows: [
                {
                  name: "Polivaks MAX-9",
                  description: "Kalıp ayırıcı maksimum"
                },
                {
                  name: "Polivaks SV-6",
                  description: "Kalıp ayrıcı süper"
                },
                {
                  name: "Polivaks SV-8",
                  description: "Kalıp ayırıcı kokusuz süper"
                },
                {
                  name: "Polivaks Ekonomik",
                  description: "Kalıp ayırıcı profesyonel"
                },
                {
                  name: "Polivaks N",
                  description: "Kalıp ayırıcı PVA sistem"
                }
              ]
            },
            {
              title: "Sıvı kalıp ayırıcılar",
              rows: [
                {
                  name: "Polivaks SIVI PVA",
                  description: "PVA Sistem yüksek performans"
                },
                {
                  name: "Polivaks EKO PVA",
                  description: "PVA Sistem performans"
                },
                {
                  name: "Polivaks Mat Sıvı Kalıp Ayırıcı",
                  description: "Mat sıvı"
                }
              ]
            },
            {
              title: "Sprey kalıp ayırıcılar",
              rows: [
                {
                  name: "Polivaks PV-7",
                  description: "Yüksek performans kalıp ayırıcı sprey vaks"
                }
              ]
            },
            {
              title: "Kalıp sistemleri",
              rows: [
                {
                  name: "Polijel 220",
                  type: "İzoftalik NPG",
                  description: "Kalıp yapım jelkotu"
                },
                {
                  name: "Polijel 240",
                  type: "Allilik",
                  description: "Parlak topkot"
                },
                {
                  name: "Polijel 272",
                  type: "Vinilester",
                  description: "Yüksek performans vinilester kalıp yapım jelkotu"
                },
                {
                  name: "Polijel 291",
                  type: "BPA",
                  description: "Yüksel ısıl ve kimyasal dayanımlı jelkot"
                },
                {
                  name: "Polipol 320-TA",
                  type: "Ortoftalik",
                  description: "Tiksotropik hızlandırıcılı kalıp yapım polyesteri"
                },
                {
                  name: "Polipol 321-ZERO",
                  type: "İzoftalik",
                  description: "ZERO Sistem, sıfır cekmeli kalıp yapım polyesteri"
                },
                {
                  name: "Polives 701-TA",
                  type: "Vinilester",
                  description: "CTP tipi tiksotropik hızlandırıcılı performans vinilester"
                },
                {
                  name: "Polimodel Macunu",
                  description: "Polimodel macun"
                }
              ]
            }
          ]
        },






        {
          label: "Turkuaz",
          accordions: [
            {
              title: "Vakslar",
              rows: [
                {
                  name: "T-Wax Gold",
                  description: "Kalıp ayırıcı, parlatıcı",
                  type: "800 g"
                },
                {
                  name: "T-Wax Silver",
                  description: "Kalıp ayırıcı, parlatıcı",
                  "packaging": "800 g"
                }
              ]
            }
            
          ]
        }
      ]
    },







  ];
  
  export default data;
  