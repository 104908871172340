// Mapone.jsx
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import markerShadowPng from 'leaflet/dist/images/marker-shadow.png';

const locations = [
  { id: 1, lat: 38.43841065150637, lng: 27.246983513490775, name: 'Yücel Kompozit Merkez - İzmir', address: `Kemalpaşa Caddesi No:276 Pınarbaşı, 35060`, phone: '☎ 0232 479 26 60' },
  { id: 2, lat: 38.42709927387416, lng: 27.16905975344334, name: 'Yücel Kompozit Yenişehir - İzmir', address: `Atatürk Ticaret Merkezi İnşaatçılar Çarşısı 1202/1 Sokak No:13 Yenişehir, 35110`, phone: '☎ 0232 433 60 36 - 458 71 92' },
  { id: 3, lat: 40.20942641933859, lng: 29.0819187711244, name: 'Yücel Kompozit Bursa', address: `Veysel Karani Mahallesi Eski Gemlik Yolu Caddesi No:236-C Osmangazi, 16250`, phone: '☎ 0224 216 16 88' }
];

const Mapone = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {locations.map(location => (
        <div key={location.id} className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-2 text-center">{location.name}</h2>
          <p className="mb-4 text-center">{location.address}</p>
          <p className="mb-4 text-center">{location.phone}</p>
          <div className="h-64 md:h-80">
            <MapContainer center={[location.lat, location.lng]} zoom={12} className="h-full z-0 w-full">
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker
                position={[location.lat, location.lng]}
                icon={L.icon({ iconUrl: markerIconPng, shadowUrl: markerShadowPng })}
              >
                <Popup>{location.name}</Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Mapone;
