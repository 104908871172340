const data = [
    {
      sectionLabel: "Ürünler",
      tabs: [
        {
          label: "Ürünler",
          accordions: [
            
                {
                  title: "Ultramax Sistemleri",
                  rows: [
                    { name: "MVP-ULTRAMAX 11:1 (CHP)" },
                    { name: "MVP-ULTRAMAX 11:1 (RTM)" },
                    { name: "MVP-ULTRAMAX 11:1 (GEC)" },
                    { name: "MVP-ULTRAMAX 15:1 (CHP)" },
                    { name: "MVP-ULTRAMAX HV 12:1 (CHP)" },
                    { name: "MVP-ULTRAMAX HV 12:1 (RTM)" },
                    { name: "MVP-ULTRAMAX-PUTYY:2000" },
                    { name: "MVP-ULTRAMAX-MULTICOLOR (2)" },
                    { name: "MVP-ULTRAMAX-MULTICOLOR (3)" },
                    { name: "MVP-ULTRAMAX-MULTICOLOR (4)" }
                  ]
                },
                {
                  title: "Patriot Sistemleri",
                  rows: [
                    { name: "MVP-PATRIOT 7:1 (CHP)" },
                    { name: "MVP-PATRIOT 7:1 (RTM)" },
                    { name: "MVP-PATRIOT 7:1 (GEC)" },
                    { name: "MVP-PATRIOT 15:1 (CHP)" },
                    { name: "MVP-PATRIOT 15:1 (RTM)" },
                    { name: "MVP-PATRIOT 15:1 (GEC)" },
                    { name: "MVP-PATRIOT INNOVATOR" },
                    { name: "MVP-PATRIOT-MULTICOLOR (3)" },
                    { name: "MVP-PATRIOT 30:1 (GEC)" }
                  ]
                },
                {
                  title: "Diğer",
                  rows: [
                    { name: "MVP-EPO-2000 UNIT" },
                    { name: "MVP-GRANIT UNIT" }
                  ]
                }
              
          ]
        },
        
        

      ]
    },







  ];
  
  export default data;
  