import React from "react";
import { motion } from "framer-motion";
import Mapone from "../components/Mapone";
import Submit from "../components/datas/Submit";

const Contact = () => {
  return (
    <div>
      <h1 className="text-2xl bg-red-500 p-4 rounded-xl mt-8  text-white font-bold mb-6 text-center">
        İletişim
      </h1>
      <div className="min-h-full bg-gray-100 flex  items-center justify-center">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white p-6 rounded-lg shadow-lg"
        >
          <Mapone />
        </motion.div>
      </div>

      <div className="flex flex-col lg:flex-row min-h-full bg-gray-100 lg:pl-16 items-center justify-center p-4 lg:p-0">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white p-6 rounded-lg shadow-lg mb-6 lg:mb-0 lg:mr-6 w-full lg:w-auto"
        >
          <h2 className="text-xl font-bold mb-4">Bize ulaşın</h2>
          <p className="mb-4 font-light">
            Ürünlerimiz, hizmetlerimiz, temsil ettiğimiz veya hizmet verdiğimiz
            markalar hakkındaki her türlü sorunuzu cevaplamaktan büyük mutluluk
            duyuyoruz.
          </p>
          <p className="mb-4 font-medium">
            Bize ulaştıracağınız iletişim bilgilerinizin tümünü özenle koruyor
            ve üçüncü kişi ve kurumlarla paylaşmıyoruz.
          </p>
          <p className="mb-4 font-semibold">
            Yücel Kompozit Malzemeleri
            <br />
            Pazarlama ve Ticaret A.Ş.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full lg:w-auto"
        >
          <Submit />
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
