const data = [
    {
      sectionLabel: "YCL Ürünleri",
      tabs: [
        {
          label: "Takviye Ürünleri",
          accordions: [
            {
                title: "Toz bağlayıcı keçe elyaflar",
                rows: [
                  { name: "YCL Keçe 300 g/m toz bağlayıcı" },
                  { name: "YCL Keçe 450 g/m toz bağlayıcı" },
                  { name: "YCL Keçe 600 g/m toz bağlayıcı" }
                ]
            },
              
            {
                title: "Sıvı bağlayıcı keçe elyaflar",
                rows: [
                  { name: "YCL Keçe 300 g/m sıvı bağlayıcı" },
                  { name: "YCL Keçe 450 g/m sıvı bağlayıcı" },
                  { name: "YCL Keçe 600 g/m sıvı bağlayıcı" }
                ]
            },
            {
                title: "Dokuma ürünler",
                rows: [
                  { name: "YCL Örgü 200 g/m Dokuma 0/90" },
                  { name: "YCL Örgü 300 g/m Dokuma 0/90" },
                  { name: "YCL Örgü 500 g/m Dokuma 0/90" },
                  { name: "YCL Örgü 800 g/m Dokuma 0/90" }
                ]
            },
            {
                title: "Yüzey tülü",
                rows: [
                  { name: "YCL Yüzey Tülü J-M 25g/m" }
                ]
            },

              
              
            
  
            
           ]
        },
        {
          label: "Kimyasallar",
          accordions: [
            {
                title: "Hidroksietil Akrilat (HEA) - Yüksek Kalite ",
                rows: [
                  { type: "Formül", description: "C5H8O3" },
                  { type: "Kullanım", description: "Boya reçineleri ve plastiklerde komonomer. Cam elyafı, bağlayıcı ve kayganlaştırıcılar için değiştirici ajan" },
                  { type: "Saflık", description: "Minimum 97 % (Gaz kromotografisinde)" },
                  { type: "Asit İçeriği", description: "Maksimum 0,3 % (Araşidonik Asit (AA) olarak)" },
                  { type: "Su İçeriği", description: "Maksimum 0,15 % (K.Fischer)" },
                  { type: "Renk", description: "Maksimum 15 (Pt-Co)" },
                  { type: "Stabilizasyon", description: "200 ± 40ppm MEHQ" },
                  { type: "Görünüm", description: "Berrak, renksiz sıvı" },
                  { type: "Molekül Ağırlığı", description: "116,13 g/mol" },
                  { type: "Dansite", description: "1,106 g/cm³ (25°C)" },
                  { type: "Kırma İndisi (Refraktif İndeks)", description: "1,480 (25°C)" },
                  { type: "Kaynama Noktası", description: "82°C (5mmHg)" },
                  { type: "Parlama Noktası", description: "98°C" },
                  { type: "Çözünebilirlik", description: "Organik solventlerle, suyla" },
                  { type: "Ambalaj", description: "Plastik veya çelik varilde 200 kg net. IBC çelik tankta 1.000 kg" },
                  { type: "Saklama koşulları", description: "Serin, gölgelik, iyi havalandırılan yerde, ateşten uzakta saklanmalıdır. Maksimum saklama ortam ısısı 30°C, Maksimum saklama süresi sevkiyattan itibaren 6 ay" }
                ]
              },
              {
               title: "Hidroksietil Metakrilat (HEMA) - Yüksek Kalite ",
                rows: [
                  { type: "Formül", description: "C6H10O3" },
                  { type: "Kullanım", description: "Boya reçineleri, plastikler ve keçelerde komonomer. Cam elyafı, kağıt, bağlayıcı ve kayganlaştırıcılar için değiştirici ajan" },
                  { type: "Ester Saflığı", description: "Minimum 98,50 % (Gaz kromotografisinde)" },
                  { type: "Asit İçeriği", description: "Maksimum 0,4 % (Metakrilik Asit (MAA) olarak)" },
                  { type: "Su İçeriği", description: "Maksimum 0,1 % (K.Fischer)" },
                  { type: "Renk", description: "Maksimum 10 (Pt-Co)" },
                  { type: "Stabilizasyon", description: "200 ± 40ppm MEHQ" },
                  { type: "Görünüm", description: "Berrak, renksiz sıvı" },
                  { type: "Molekül Ağırlığı", description: "130,14 g/mol" },
                  { type: "Dansite", description: "1,074 g/cm³ (25°C)" },
                  { type: "Kırma İndisi (Refraktif İndeks)", description: "1,450 (25°C)" },
                  { type: "Kaynama Noktası", description: "95°C (1,33kPa)" },
                  { type: "Parlama Noktası", description: "108°C" },
                  { type: "Çözünebilirlik", description: "Organik solventlerle, suyla" },
                  { type: "Ambalaj", description: "Plastik veya çelik varilde 200 kg net. IBC çelik tankta 1.000 kg" },
                  { type: "Saklama koşulları", description: "Serin, gölgelik, iyi havalandırılan yerde, ateşten uzakta saklanmalıdır. Maksimum saklama ortam ısısı 30°C, Maksimum saklama süresi sevkiyattan itibaren 6 ay" }
                ]
              },
              {
                title: "Hidroksipropil Akrilat (HPA) - Birinci Kalite ",
                rows: [
                  { type: "Formül", description: "C6H10O3" },
                  { type: "Kullanım", description: "Boya reçineleri ve plastiklerde komonomer. Cam elyafı, bağlayıcı ve kayganlaştırıcılar için değiştirici ajan" },
                  { type: "Saflık", description: "Minimum 93,5 % (Gaz kromotografisinde)" },
                  { type: "Akrilik Asit", description: "Maksimum 0,25 %" },
                  { type: "Su İçeriği", description: "Maksimum 0,25 % (K.Fischer)" },
                  { type: "Renk", description: "Maksimum 25 (Pt-Co)" },
                  { type: "İnhibitör", description: "200 ± 40ppm MEHQ" },
                  { type: "Görünüm", description: "Berrak, renksiz sıvı" },
                  { type: "Molekül Ağırlığı", description: "130,14 g/mol" },
                  { type: "Dansite", description: "1,044 g/cm³ (25°C)" },
                  { type: "Kırma İndisi (Refraktif İndeks)", description: "1,445 (25°C)" },
                  { type: "Kaynama Noktası", description: "77°C (5mmHg)" },
                  { type: "Parlama Noktası", description: "89,4°C" },
                  { type: "Çözünebilirlik", description: "Organik solventlerle, suyla" },
                  { type: "Ambalaj", description: "Plastik veya çelik varilde 200 kg net. IBC çelik tankta 1.000 kg" },
                  { type: "Saklama koşulları", description: "Serin, gölgelik, iyi havalandırılan yerde, ateşten uzakta saklanmalıdır. Maksimum saklama ortam ısısı 30°C, Maksimum saklama süresi sevkiyattan itibaren 6 ay" }
                ]
              },
              {
                title: "Hidroksipropil Metakrilat (HPMA) - Birinci Kalite",
                rows: [
                  { type: "Formül", description: "C7H12O3" },
                  { type: "Kullanım", description: "Boya reçineleri ve plastiklerde komonomer. Elyaf, kağıt ve bağlayıcılar için değiştirici ajan" },
                  { type: "Saflık", description: "Minimum 97,00 % (Gaz kromotografisinde)" },
                  { type: "Asit İçeriği", description: "Maksimum 0,2 % (Metakrilik Asit (MAA) olarak)" },
                  { type: "Su İçeriği", description: "Maksimum 0,2 % (K.Fischer)" },
                  { type: "Renk", description: "Maksimum 20 (Pt-Co)" },
                  { type: "Stabilizasyon", description: "200 ± 40ppm MEHQ" },
                  { type: "Görünüm", description: "Berrak, renksiz sıvı" },
                  { type: "Molekül Ağırlığı", description: "144,7 g/mol" },
                  { type: "Dansite", description: "1,066 g/cm³ (25°C)" },
                  { type: "Kırma İndisi (Refraktif İndeks)", description: "1,447 (25°C)" },
                  { type: "Kaynama Noktası", description: "92°C (1,2kPa)" },
                  { type: "Parlama Noktası", description: "96°C" },
                  { type: "Çözünebilirlik", description: "Organik solventlerle, suyla" },
                  { type: "Ambalaj", description: "Plastik veya çelik varilde 200 kg net. IBC çelik tankta 1.000 kg" },
                  { type: "Saklama koşulları", description: "Serin, gölgelik, iyi havalandırılan yerde, ateşten uzakta saklanmalıdır. Maksimum saklama ortam ısısı 30°C, Maksimum saklama süresi sevkiyattan itibaren 6 ay" }
                ]
              }
          ]
        },
      ]
    },
  ];
  
  export default data;
  