import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Subpage1 from './pages/Subpage1';
import Subpage2 from './pages/Subpage2';
import Subpage3 from './pages/Subpage3';


// Ürünler

import Recine from './pages/subpages/Recine';
import Jelkod from './pages/subpages/Jelkod';
import Kalipur from './pages/subpages/Kalipur';
import Vakumin from './pages/subpages/Vakumin';
import Pigment from './pages/subpages/Pigment';
import Elyaf from './pages/subpages/Elyaf';
import Yapistirici from './pages/subpages/Yapistirici';
import Hizlandir from './pages/subpages/Hizlandir';
import Inceltici from './pages/subpages/Inceltici';
import Dolgu from './pages/subpages/Dolgu';
import Makina from './pages/subpages/Makina';
import Uygulama from './pages/subpages/Uygulama';

//Markalar

import YCL from './pages/markalar/YCL'
import Boytek from './pages/markalar/Boytek';
// import Diatex from './pages/markalar/Diatex';
 import Ilkester from './pages/markalar/Ilkester';
import Lantor from './pages/markalar/Lantor';
import Magnum from './pages/markalar/Magnum';
import Metyx from './pages/markalar/Metyx';
// import Poliya from './pages/markalar/Poliya';
import Sisecam from './pages/markalar/Sisecam';
import Tissa from './pages/markalar/Tissa';
//import Turkuaz from './pages/markalar/Turkuaz';
import Tpoliya from './pages/teknik/Tpoliya';
import Tlantor from './pages/teknik/Tlantor';
import Tmagnum from './pages/teknik/Tmagnum';
import Ttissa from './pages/teknik/Ttissa';
import Footer from './components/Footer';

//Videos

import ATSerisi from './pages/videos/ATSerisi'
import ClassicSerisi from './pages/videos/ClassicSerisi';
import Coreamat3d from './pages/videos/Coreamat3d';
import CorematKolay from './pages/videos/CorematKolay';
import CorematUygulama from './pages/videos/CorematUygulama';
import CPRSerisi from './pages/videos/CPRSerisi';
import ExternalSerisi from './pages/videos/ExternalSerisi';
import InternalSerisi from './pages/videos/InternalSerisi';
import JelkodSeri from './pages/videos/JelkodSeri';
import SoricVakum from './pages/videos/SoricVakum';
import SoricZirve from './pages/videos/SoricZirve';
import Video from './pages/only/Video';
import YCLabout from './pages/only/YCLabout';
import ScrollToTop from './components/ScrollToTop';
import ElyafYatmasi from './pages/Tpoliya/ElyafYatmasi';
import Xi from './pages/Lantor/Xi';
import Xm from './pages/Lantor/Xm';
import Sf from './pages/Lantor/Sf';
import Xf from './pages/Lantor/Xf';
import Tf from './pages/Lantor/Tf';
import LRC from './pages/Lantor/LRC';
import Fin from './pages/Lantor/Fin';


//Tpoliya



//Ttissa

import TTissaPage from './pages/TTissa/TTissaPage' 
import Ultrafit from './pages/Tmagnum/Ultrafit';
import RTM from './pages/Tmagnum/RTM';
import PatriotHi from './pages/Tmagnum/PatriotHi';
import PatriotSt from './pages/Tmagnum/PatriotSt';
import Blok from './pages/Tmagnum/Blok';

//Fin

function App() {
  return (
  <div className='bg-[#EEEEEE] font-sans'>
    <ScrollToTop />
    <Router  >
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/usage" element={<Subpage1 />} />
        <Route path="/videos" element={<Subpage2 />} />
        <Route path="/download" element={<Subpage3 />} />
        <Route path="/contact" element={<Contact />} />
     

        {/* Ürünler */}
        <Route path="/urun-recine" element={<Recine />} />
        <Route path="/urun-jelkot" element={<Jelkod />} />
        <Route path="/urun-kalip" element={<Kalipur />} />
        <Route path="/urun-vakum" element={<Vakumin />} />
        <Route path="/urun-pigmentpasta" element={<Pigment />} />
        <Route path="/urun-elyaf" element={<Elyaf />} />
        <Route path="/urun-pasta-yapistirici" element={<Yapistirici />} />
        <Route path="/urun-hizlandirici-sertlestirici-katki" element={<Hizlandir />} />
        <Route path="/urun-inceltici-temizleyici" element={<Inceltici />} />
        <Route path="/urun-dolgu-efekt-toz" element={<Dolgu />} />
        <Route path="/urun-uygulama-malzeme" element={<Uygulama />} />
        <Route path="/urun-makina-ekipman" element={<Makina />} />

        {/* Markalar */}

        <Route path="/marka-yucel" element={<YCL />} />
        <Route path="/marka-boytek" element={<Boytek />} />
        <Route path="/marka-diatex" element={<YCL />} />
        <Route path="/marka-ilkester" element={<Ilkester />} />
        <Route path="/marka-lantor" element={<Lantor />} />
        <Route path="/marka-magnum-venus" element={<Magnum />} />
        <Route path="/marka-metyx" element={<Metyx />} />
        <Route path="/marka-poliya" element={<YCL />} />
        <Route path="/marka-camelyaf" element={<Sisecam />} />
        <Route path="/marka-tissa" element={<Tissa />} />
        <Route path="/marka-turkuaz" element={<YCL />} />

        {/* Teknik Bilgiler */}

        <Route path="/teknik-ozellik-poliya" element={<Tpoliya />} />
        <Route path="/teknik-ozellik-lantor" element={<Tlantor />} />
        <Route path="/teknik-ozellik-magnum-venus" element={<Tmagnum />} />
        <Route path="/teknik-ozellik-tissa" element={<Ttissa />} />




        {/* Tissa Teknik Bilgiler */}
        
        <Route path="/teknik-tissa-matcut-700" element={<TTissaPage />} />        
        
        {/* Fin */}

        {/* Magnum Venüs Teknik Bilgiler */}
        
        <Route path="/makina-magnum-venus-ultramax-fit" element={<Ultrafit />} />        
        <Route path="/makina-magnum-venus-ultramax-rtm" element={<RTM />} />        
        <Route path="/makina-magnum-venus-patriot-yuksek" element={<PatriotHi />} />        
        <Route path="/makina-magnum-venus-patriot-standart" element={<PatriotSt />} />        
        <Route path="/makina-magnum-venus-ultramax-blok" element={< Blok />} />        
        
        {/* Fin */}

        {/*  */}

        <Route path="/video-magnum-venus-gelcoat-systems" element={<Video />} />

        {/*  */}

        <Route path="/hak-yucel-kompozit" element={<YCLabout />} />
        <Route path="/hak-yucel-kompozit-ekip" element={<About />} />


        {/* Tpoliya */}

        <Route path="/teknik-ozellik-poliya-polyester-el-yatirmasi" element={<ElyafYatmasi />} />



        {/* TLantor */}
        <Route path="/teknik-lantor-coremat-xi" element={<Xi />} />
        <Route path="/teknik-lantor-coremat-xm" element={<Xm />} />
        <Route path="/teknik-lantor-soric-sf" element={<Sf/>} />
        <Route path="/teknik-lantor-soric-xf" element={<Xf />} />
        <Route path="/teknik-lantor-soric-tf" element={<Tf />} />
        <Route path="/teknik-lantor-soric-lrc" element={<LRC />} />
        <Route path="/teknik-lantor-finishmat-d77" element={<Fin />} />



        {/* TLantor */}

        <Route path="/video-yucel-kompozit-magnum-venus-at-tabanca-bakim" element={<ATSerisi />} />
        <Route path="/video-yucel-kompozit-magnum-venus-cpr-tabanca-bakim" element={<CPRSerisi />} />
        <Route path="/video-yucel-kompozit-magnum-venus-klasik-pro-tabanca-bakim" element={<ClassicSerisi />} />
        <Route path="/video-yucel-kompozit-magnum-venus-talon-dahili-tabanca-bakim" element={<InternalSerisi />} />
        <Route path="/video-yucel-kompozit-magnum-venus-talon-harici-tabanca-bakim" element={<ExternalSerisi />} />
        <Route path="/video-yucel-kompozit-lantor-coremat-xm-xi-3-boyutlu-demo" element={<Coreamat3d />} />
        <Route path="/video-yucel-kompozit-lantor-coremat-xm-xi-kolay-birlesme" element={<CorematKolay />} />
        <Route path="/video-yucel-kompozit-lantor-soric-vakum-infuzyon" element={<SoricVakum />} />
        <Route path="/video-yucel-kompozit-lantor-coremat-uygulama-rehberi-2022" element={<CorematUygulama />} />
        <Route path="/video-yucel-kompozit-lantor-soric-sunum" element={<SoricZirve />} />
        <Route path="/video-magnum-venus-gelcoat-systems" element={<JelkodSeri />} />
      </Routes>
      <Footer />
    </Router>
    </div>
  );
}

export default App;
