// src/components/Ultrafit.jsx
import React from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import SliderCards from "../../components/SliderCards";

import img2 from "../../img/tpolia/patci/rtm/400-logo-ultramax (1).jpg";
import img1 from "../../img/tpolia/patci/600-ultramax-fit-01.jpg";
import img3 from "../../img/tpolia/patci/600-ultramax-fit-tech-01.jpg";
import img4 from "../../img/tpolia/patci/600-ultramax-fit-super-pro-2-tabanca-01.jpg";

const StyledContainer = styled(Container)({
  backgroundColor: "#f9f9f9",
  padding: "2rem",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const animationVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const AnimatedComponent = ({ children }) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={animationVariants}
    >
      {children}
    </motion.div>
  );
};

const Ultrafit = () => {
  return (
    <div className="mt-3">
      <StyledContainer>
        <AnimatedComponent>
          <h1 className="text-2xl bg-red-500 p-4 rounded-xl mt-2  text-white font-bold mb-6 text-center">
            UltraMAX™ FIT® kırpma ve püskürtme sistemi
          </h1>
        </AnimatedComponent>
        <div className="flex flex-col gap-8 mb-8">
          <AnimatedComponent>
            <div className="flex flex-col md:flex-row gap-8">
              <img
                src={img1}
                alt="Placeholder 1"
                className="rounded-lg w-[25rem] shadow-lg md:w-1/2"
              />
              <TableContainer component={Paper} className="md:w-1/2">
                <img
                  src={img2}
                  alt="Placeholder 1"
                  className="rounded-lg w-[36rem] h-32 shadow-lg ml-0 md:ml-36 md:w-1/2"
                />
                <Table sx={{ height: "24px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{fontWeight: ""}} >Özellik</TableCell>
                      <TableCell>Açıklama</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{fontWeight: ""}} >Çıkış kapasitesi</TableCell>
                      <TableCell>
                        3,63 - 4,54 kg/dakika (Reçine özellikleri ve ortam
                        sıcaklığına göre değişir)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight: ""}} >MEK-P kapasitesi</TableCell>
                      <TableCell>
                        Hacim olarak %0,75 - %3 (Çalışma ve ortam sıcaklığına
                        göre)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight: ""}} >Hava tüketimi</TableCell>
                      <TableCell>510 l / dakika</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight: ""}} >Pompa oranları</TableCell>
                      <TableCell>6 : 1 (Standart) 11 : 1 (Opsiyonel)</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight: ""}} >Hortum uzunluğu</TableCell>
                      <TableCell>9 m</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{fontWeight: ""}} >Opsiyonlar</TableCell>
                      <TableCell>
                        VRC - 100 Kırpıcı sistemi 50090 - 1 RTM sistemi
                        (hortumlu transfer) 52300 - 1 RTM sistemi (sabit
                        transfer) VPA - 1000 Jelkot sistemi
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </AnimatedComponent>
          
          <AnimatedComponent>
            <Typography variant="body1" component="p" className="mb-8">
            UltraMAX™ pompası çok amaçlı bir sistemdir. Kendi sınıfında en düşük basınçla en yüksek reçine transfer kapasitesini sağlar. Kırpmanın yanısıra farklı üretim teknikleri (Jelkot püskürtme, RTM uygulaması vb.) teknikleri ile de kullanılabilir. Düşük basınçla çalıştığı için daha uzun ömürlüdür.
            </Typography>
          </AnimatedComponent>
          <AnimatedComponent>
            <div className="flex flex-col md:flex-row-reverse gap-8">
              <img
                src={img3}
                alt="Placeholder 2"
                className="rounded-lg shadow-lg  md:w-[70rem]  md:h-[40rem]"
              />
              <TableContainer component={Paper} className="md:w-1/2">
                <Table>
                  <TableHead>
                    <TableRow>
                      
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                      >
                        Özellik
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                      >
                        Yararlar
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>
                      Düşük basınç
                      </TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>
                      UltraMAX™ pompa sistemiyle MVP FIT kırpıcı ünitesi, piyasadaki en düşük basınçlı elyaf kırpıcılı polyester püskürtme makinasıdır (15 - 30 psi).
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>
                      Düşük emisyon
                      </TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>Sistem, en gelişmiş emisyon teknolojisi olan FIT (Fluid Impingment Technology) ile en düşük emisyon oranına sahiptir.</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>
                      Ürün kapasitesinde süreklilik
                      </TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>
                      FIT Chopper ünitesi, düşük basınçla hiç sıçratmadan daha güçlü ve sürekli laminasyon sağlar.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>
                      Düzgün püskürtme
                      </TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>
                      FIT teknolojisi, gelişmiş yapısı sayesinde, püskürtme esnasında oluşan tozlaşmaları ortadan kaldırarak sürekli standart püskürtme sağlar.
                      </TableCell>
                    </TableRow>
                    
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </AnimatedComponent>

          <AnimatedComponent>
            <div className="flex flex-col md:flex-row gap-8">
              <img
                src={img4}
                alt="Placeholder 3"
                className="rounded-lg shadow-lg md:w-1/3 object-fill"
              />
            </div>
          </AnimatedComponent>
        </div>
      </StyledContainer>
      <SliderCards />
    </div>
  );
};

export default Ultrafit;
