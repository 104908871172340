import React from 'react';
import { Link } from 'react-router-dom';
import image from '../../img/tech/440-brand-poliya.jpg'
import { motion } from 'framer-motion';






const Tpoliya = () => {
  const headings = [
    { title: 'Polyester Reçineler', buttons: [
        { name: 'El Yatırması Elyaf Püskürtme Reçineleri', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Döküm (Cast) Reçineleri', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'RTM, İnfüzyon,Vakum Enjeksiyon Reçineleri', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Sıcak-Soğuk Press Reçineleri', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Pultrüzyon Reçineleri', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Fitil Sarma Reçineleri', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Sürekli Çekme Reçineleri', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Akrilik Arkası Reçineler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Düşük Stiren Emisyonlu Reçineler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Kimyasal Maddelere Dayanıklı Reçineler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Alev İlerletmeyen Reçineler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Macun Tipi Reçineler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Düğme Tipi Reçineler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Mühendislik Dökümleri için Reçineler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
      ],
    },
    { title: 'Vinilester Reçineler', buttons: [
        { name: 'El Yatırması Elyaf Püskürtme Reçineleri', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'RTM, İnfüzyon,Vakum Enjeksiyon Reçineleri', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Pultrüzyon Reçineleri', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Kimyasal Maddelere Dayanıklı Reçineler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Alev İlerletmeyen Reçineler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Özel Amin Hızlandırıcılı Reçineler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
      ],
    },
    { title: 'Jelkotlar', buttons: [
        { name: 'Topkotlar', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Zımparalanabilir ve Astar Jelkodlar', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Standart ve Genel Amaçlı Jelkodlar', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Performans ve Yüksek Jelkodlar', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Kimyasal Maddelere Dayanıklı Jelkodlar', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Bariyerkodlar', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Alev İlerletmeyen Jelkodlar', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
      ],
    },
    { title: 'Pigmentler', buttons: [
        { name: 'Örtücü Açık Renkler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Örtücü Pro Renkler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Şeffaf Renkler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Tam Şeffaf Düğme Renkleri', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
        { name: 'Özel Metalik ve Sedefli Renkler', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
      ],
    },
    { title: 'Kalıp Ürünleri ve Yapıştırıcı Pastalar', buttons: [
      { name: 'Kalıp Sistemleri', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
      { name: 'Yapıştırıcı Pastalar', link: '/teknik-ozellik-poliya-polyester-el-yatirmasi' },
     
    ],
  },
  ];



  const pageVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <motion.div
      className="container mx-auto p-4"
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.5 }}
    >
      <motion.header className="text-center" variants={pageVariants}>
        <motion.h1
          className="text-2xl bg-red-500 p-4 rounded-xl text-white font-bold mb-6 text-center"
          variants={pageVariants}
        >
          Teknik Bilgiler İndeksi - Poliya ürünleri
        </motion.h1>
        <motion.img
          src={image}
          alt="Logo"
          className="mx-auto mb-8 w-auto h-40 rounded-2xl"
          variants={pageVariants}
        />
      </motion.header>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4"
        initial="hidden"
        animate="visible"
        transition={{ staggerChildren: 0.2 }}
      >
        {headings.map((heading, index) => (
          <motion.div
            key={index}
            className="p-4 border rounded shadow-lg"
            variants={pageVariants}
          >
            <motion.h2 className="text-2xl font-semibold mb-4" variants={pageVariants}>
              {heading.title}
            </motion.h2>
            <motion.div className="space-y-2" variants={pageVariants}>
              {heading.buttons.map((button, btnIndex) => (
                <motion.div key={btnIndex} variants={pageVariants}>
                  <Link
                    to={button.link}
                    className="block text-center py-2 px-4 bg-[#686D76] text-white rounded hover:bg-red-500"
                  >
                    {button.name}
                  </Link>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Tpoliya;
