import React from 'react';
import AnimatedSection from './AnimatedSection';

const ImageWithHeading = ({ heading, src, alt }) => {
  return (
    <AnimatedSection>
      <div className="bg-white shadow-md rounded p-4 mb-8">
        <h2 className="text-2xl bg-gray-600 h-24 rounded-2xl  text-white font-bold mb-4 content-center text-center">{heading}</h2>
        <img src={src} alt={alt} className="w-[37rem] h-[30rem]  rounded" />
      </div>
    </AnimatedSection>
  );
};

export default ImageWithHeading;
