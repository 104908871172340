import React from "react";
import HeaderTable from "../../components/HeaderTable";
import ImageWithHeading from "../../components/ImageWithHeading";
import MultiColumnTable from "../../components/MultiColumnTable";
import img from "../../img/tech/lantor/xm/640-lantor-soric-tf.jpg";

const Tf = () => {
  const firstColRows = [
    "Kapalı kalıp uygulamaları için uygun maliyetli malzeme",
    "Kor malzemesi veya infüzyon ortamı olarak kullanılabilme",
    "Basınç altında dengeli polyester dokumasız yapısıyla polyester, vinilester, fenolik ve epoksi gibi tüm standart reçinelere uyumluluk",
    "Ağır ve hafif RTM, infüzyon ve kapalı kalıp uygulamalarına uygun yapı",
    "Reçine akışını dengeleyen yüzey kalitesi",
  ];
  const secondColRows = [
    "Tekne ve yatların gövde ve güverteleri",
    "Otomobil, kamyon, treyler parça ve panelleri",
    "Otobüs, tren ve hafif raylı sistemlerin iç ve dış gövde ve panelleri",
    "Kano ve sörf tahtaları",
    "Küvet ve havuzlar",
    "Duvar kaplama panelleri, fanlar, konteynerler ve tanklar",
    "Rüzgar tribünü kaportaları (nasel kapakları), spiner’lar",
  ];

  const firstMultiColRows = [
    ["Özellik", "TF 1,5", "TF 2", "TF 3"],
    ["Kalınlık (mm)", "1,5", "2,0", "3,0"],
    ["Rulo uzunluğu (m)", "120", "80", "50"],
    ["Rulo genişliği (m)", "1,27", "1,27", "1,27"],
    ["0,8 barda kalınlık kaybı (%)", "< 25", "< 25", "< 25"],
    ["Maksimum işleme sıcaklığı (°C)", "170", "170", "170"],
    ["Reçine tüketimi (kg/m2)", "0,8", "1,0", "1,4"],
    ["Kuru ağırlık (g/m2)", "90", "120", "160"],
    ["Emprenye edilmiş dansite (kg/m3)", "700", "700", "700"],
  ];

  const secMultiColRows = [
    ["Mekanik özellik", "Birim", "Değer", "Test metodu"],
    ["Eğilme dayanımı", "MPa", "19", "ASTM D790"],
    ["Eğilme modülü", "MPa", "1.500", "ASTM D790"],
    ["Katmanlar arası gerilme dayanımı", "MPa", "7", "ASTM C297"],
    ["Basınç dayanımı (%10 stren)", "MPa", "4", "ISO 844"],
    ["Kopma mukavemeti", "MPa", "7", "ASTM C273-61"],
    ["Kopma modülü", "MPa", "34", "ASTM C273-61"],
  ];

  return (
    <div className="container mx-auto p-4">
      <h1 className="bg-red-500 text-white mb-3 h-auto px-6 py-2 rounded-2xl text-2xl md:text-3xl text-center flex items-center justify-center md:px-10 lg:px-20 xl:px-32">
        Lantor Soric ® TF
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="md:order-2 mt-24">
          <HeaderTable heading="Avantajlar" rows={firstColRows} />
        </div>
        <div className="md:order-1">
          <ImageWithHeading
            heading="Kusursuz yüzey kalitesi ve kozmetik ihtiyaçlar için ideal"
            src={img}
            alt="Sample"
          />
        </div>
        <div className="md:order-4 ">
          <HeaderTable heading="Uygulamalar" rows={secondColRows} />
        </div>
        <div className="md:order-3">
          <MultiColumnTable
            heading="Boyut Özellikleri"
            rows={firstMultiColRows}
            description="Geçirgenlik - reçine akışı	standart"
          />
        </div>
        <div className="md:order-5">
          <MultiColumnTable
            heading="Doymamış polyester reçine ile emprenye edilmiş Lantor Soric ® TF 2’nin tipik mekanik özellikleri"
            rows={secMultiColRows}
          />
        </div>
      </div>
    </div>
  );
};

export default Tf;
