import React from 'react';
import VideoComponent from '../../components/VideoComponent';
import SliderCards from '../../components/SliderCards';

const Coreamat3d = () => {
  const videoUrl = '/video/video-lantor-coremat-xm-xi-3d-demo.mp4';

  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">

        <h2 className="text-2xl bg-red-500 p-4 rounded-xl text-white  font-bold mb-6 text-center">Yücel Kompozit - Magnum Venus Products® AT Serisi Tabancaların Bakım ve Onarımı</h2>
        <div className="max-w-screen-lg mx-auto">
          <VideoComponent videoUrl={videoUrl} />
        </div>
      </div>

      <SliderCards />
    </div>
  );
};

export default Coreamat3d;
