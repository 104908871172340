import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

import image1 from "../img/urun/420-kompozit-poly.jpg";
import image2 from "../img/urun/420-yucel-jelkot.jpg";
import image3 from "../img/urun/420-yucel-kalip.jpg";
import image4 from "../img/urun/420-yucel-pigment.jpg";
import image5 from "../img/urun/420-yucel-cam.jpg";
import image6 from "../img/urun/420-yucel-yapistirici.jpg";
import image7 from "../img/urun/420-yucel-kompozit-hızlandırıcı.jpg";
import image8 from "../img/urun/420-yucel-inceltici.jpg";
import image9 from "../img/urun/420-yucel-mikronize-dolgu-granit-efekt.jpg";
import image10 from "../img/urun/420-yucel-kompozit-uygulama.jpg";
import image11 from "../img/urun/420-yucel-magnum.jpg";

const cards = [
  {
    id: 1,
    title: "Polyester ve vinilester reçineler",
    content:
      "El yatırması, döküm, RTM, infüzyon, vakum enjeksiyon, soğuk ve sıcak pres, pultrüzyon, fitil sarma gibi temel kullanımlardan özel uygulamalara kadar tüm reçineler",
    image: image1,
    link: "/urun-recine",
  },
  {
    id: 2,
    title: "Jelkotlar",
    content:
      "Standart tiplerden eco serilerine, yüksek performanslı, ısı ve kimyasallara dayanıklı, alev ilerletmeyen, bariyer tipi ürünlere kadar geniş renk seçenekleri ile tüm jelkotlar",
    image: image2,
    link: "/urun-jelkot",
  },
  {
    id: 3,
    title: "Kalıp ayırıcılar, kalıp sistemleri",
    content:
      "Katı, sıvı ve sprey kalıp ayırıcılar, kalıp hazırlığı için özel ürünler",
    image: image3,
    link: "/urun-kalip",
  },
  {
    id: 4,
    title: "Pigment pastalar",
    content:
      "Örtücü, şeffaf, yüksek performanslı, metalik ve sedef renkler, özel yapım RAL® ve Pantone® seçenekleri",
    image: image4,
    link: "/urun-pigmentpasta",
  },
  {
    id: 5,
    title: "Elyaflar",
    content:
      "YCL, Lantor, Şişecam ve Metyx gibi tanınmış markalardan genel ve özel amaçlı elyaflar, keçeler, fitiller, kırpılmış demetler, vakum malzemeleri, yüzey tülleri",
    image: image5,
    link: "/urun-elyaf",
  },
  {
    id: 6,
    title: "Yapıştırıcı pastalar",
    content:
      "Tekne ve treyler gövdelerinden rüzgar tribünü ve duvar paneli uygulamalarına her alan için standart, esnek, performans tipi ve takviyeli yapıştırıcı pastalar",
    image: image6,
    link: "/urun-pasta-yapistirici",
  },
  {
    id: 7,
    title: "Hızlandırıcı, sertleştirici ve katkılar",
    content:
      "Hızlandırıcılar, oda ısısı ve yüksek sıcaklık için sertleştiriciler, katalizörler, aditifler",
    image: image7,
    link: "/urun-hizlandirici-sertlestirici-katki",
  },
  {
    id: 8,
    title: "İnceltici ve temizleyiciler",
    content: "İncelticiler, monomerler, temizlik solventleri, poliüretanlar",
    image: image8,
    link: "/urun-inceltici-temizleyici",
  },
  {
    id: 9,
    title: "Dolgu, efekt ve toz pigmentler",
    content:
      "Suni doğal taş imalatı için tek renkli, çok renkli, altın ve gümüş efektler, kalsit, dolomit, talk vb. mikronize dolgular, toz pigmentler",
    image: image9,
    link: "/urun-dolgu-efekt-toz",
  },
  {
    id: 10,
    title: "Uygulama malzemeleri",
    content:
      "Fırçalar, plastik ve kestamid kamalar, pösteki rulolar, alüminyum ve plastik rulolar ve her türlü uygulama malzemesi",
    image: image10,
    link: "/urun-uygulama-malzeme",
  },
  {
    id: 11,
    title: "Makina ve ekipman",
    content: "Magnum Venus Products® ürünleri",
    image: image11,
    link: "/urun-makina-ekipman",
  },
];

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const SliderCards = () => {
  const [current, setCurrent] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const breakpoint = 768;

  const handleNextSlide = useCallback(() => {
    if (window.innerWidth < breakpoint) {
      setCurrent((prev) => (prev >= cards.length - 2 ? 0 : prev + 1));
    } else {
      setCurrent((prev) => (prev >= cards.length - 5 ? 0 : prev + 1));
    }
  }, []);

  const handlePrevSlide = useCallback(() => {
    if (window.innerWidth < breakpoint) {
      setCurrent((prev) => (prev === 0 ? cards.length - 2 : prev - 1));
    } else {
      setCurrent((prev) => (prev === 0 ? cards.length - 5 : prev - 1));
    }
  }, []);

  useEffect(() => {
    const resizeListener = () => {
      const containerWidth = document.querySelector(".slider-container")
        .offsetWidth;
      setCardWidth(
        containerWidth < breakpoint ? containerWidth : containerWidth / 5
      );
    };

    resizeListener();
    window.addEventListener("resize", resizeListener);

    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        handleNextSlide();
      } else if (event.key === "ArrowLeft") {
        handlePrevSlide();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNextSlide, handlePrevSlide]);

  return (
    <div className="relative w-full p-8 max-w-[88rem] mx-auto h-full font-sans overflow-hidden">
      <h1 className="text-3xl m-6 font-bold border-[#373A40] border-b-4 text-[#686D76]">
        Ürünler
      </h1>

      <div className="slider-container relative">
        <button
          onClick={handlePrevSlide}
          className="absolute left-4 md:left-4 z-10 p-2 md:p-3 bg-red-500 text-white top-0  transform -translate-y-1/2 rounded-full shadow-lg hover:bg-orange-700 transition duration-300"
        >
          <FaArrowLeft size={16} />
        </button>
        <button
          onClick={handleNextSlide}
          className="absolute left-16 md:left-20 z-10 p-2 md:p-3 bg-red-500 text-white top-0  transform -translate-y-1/2 rounded-full shadow-lg hover:bg-orange-700 transition duration-300"
        >
          <FaArrowRight size={16} />
        </button>
        <div
          className="flex h-full transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${
              current * (cardWidth < breakpoint ? cardWidth : cardWidth / 3)
            }px)`,
          }}
        >
          {cards.map((card, index) => (
            <Link               to={card.link}
            key={card.id}
            className="w-full mt-6 sm:w-1/3 md:w-1/4 lg:w-1/4 flex-shrink-0 flex items-start  justify-center px-2"
            style={{ width: `${cardWidth}px`, minWidth: `${cardWidth}px` }}
            onClick={scrollToTop}
          >
            <motion.div
              className="bg-[#686D76] items-center flex flex-col shadow-black text-white p-3 md:p-4 rounded-3xl shadow-md font-sans w-72 mx-2"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.05 }}
            >
              <img
                src={card.image}
                alt={card.title}
                className="w-[10rem] h-auto object-fill drop-shadow-2xl shadow-black shadow-xl rounded-3xl"
              />
              <div className="px-1 py-1 mt-6  shadow-lg rounded-lg">
                <h2 className="text-md md:text-lg text-center rounded-md text-white  font-medium mb-1 ">
                  {card.title}
                </h2>
                <p className="text-sm md:text-sm text-white text-center font-sm mt-3">
                  {card.content}
                </p>
              </div>
            </motion.div>
          </Link>
        ))}
      </div>
    </div>
  </div>
);
};

export default SliderCards;

             
