const data = [
  {
    sectionLabel: "Şişecam Ürünleri",
    tabs: [
      {
        label: "Polyester Reçineler",
        accordions: [
          {
            title: "El yatırması, elyaf püskürtme",
            rows: [
              {
                name: "CE 92 N8",
                type: "Ortoftalik",
                description: "CTP",
              },
              {
                name: "CE 188 N8",
                type: "Ortoftalik",
                description: "CTP",
              },
              {
                name: "CE 266 N12",
                type: "İzoftalik",
                description: "CTP",
              },
            ],
          },
          {
            title: "Döküm (cast)",
            rows: [
              {
                name: "CE 70 N4",
                type: "Ortoftalik",
                description: "Döküm",
              },
              {
                name: "CE 80",
                type: "",
                description: "Döküm",
              },
            ],
          },
          {
            title: "SMC-BMC, Sıcak pres",
            rows: [
              {
                name: "CE BV8",
                type: "Ortoftalik",
                description: "SMC-BMC",
              },
            ],
          },
          {
            title: "Fitil sarma",
            rows: [
              {
                name: "CE 66 N4 (dökme)",
                type: "",
                description: "Fitil sarma",
              },
              {
                name: "CE 95",
                type: "rPet",
                description: "CTP boru tipi rPET",
              },
            ],
          },
          {
            title: "Akrilik arkası",
            rows: [
              {
                name: "CE 28-DTA-35 Yenilenmiş Varil",
                description: "Akrilik, dolgulu, hızlandırıcılı",
              },
              {
                name: "CE 28-DTA-40 Yenilenmiş Varil",
                description: "Akrilik, dolgulu, hızlandırıcılı",
              },
            ],
          },
          {
            title: "Standart uygulama için genel amaçlı",
            rows: [
              {
                name: "CE Optima SF",
                type: "Ortoftalik",
                description: "Genel amaçlı",
              },
              {
                name: "CE Optima SP",
                type: "Ortoftalik",
                description: "Genel amaçlı",
              },
            ],
          },
          {
            title: "Beyaz renkli",
            rows: [
              {
                name: "CE Optima BF",
                type: "Ortoftalik",
                description: "Genel amaçlı, beyaz",
              },
              {
                name: "CE Optima BP",
                type: "Ortoftalik",
                description: "Genel amaçlı, beyaz",
              },
            ],
          },
          {
            title: "Örtücü ana renkler",
            rows: [
              {
                name: "CE Siyah sıvı pigment",
                Ambalaj: "0,5 kg",
              },
              {
                name: "CE Beyaz sıvı pigment",
                Ambalaj: "1 kg",
              },
            ],
          },
        ],
      },
      {
        label: "Jelkotlar",
        accordions: [
          {
            title: "Standart uygulama için genel amaçlı",
            rows: [
              {
                name: "CE Optima SF",
                type: "Ortoftalik",
                description: "Genel amaçlı",
              },
              {
                name: "CE Optima SP",
                type: "Ortoftalik",
                description: "Genel amaçlı",
              },
            ],
          },
          {
            title: "Beyaz renkli",
            rows: [
              {
                name: "CE Optima BF",
                type: "Ortoftalik",
                description: "Genel amaçlı, beyaz",
              },
              {
                name: "CE Optima BP",
                type: "Ortoftalik",
                description: "Genel amaçlı, beyaz",
              },
            ],
          },
        ],
      },
      {
        label: "Pigment",
        accordions: [
          {
            title: "Örtücü ana renkler",
            rows: [
              {
                name: "CE Siyah sıvı pigment",
                description: "0,5 kg",
              },
              {
                name: "CE Beyaz sıvı pigment",
                description: "1 kg",
              },
            ],
          },
        ],
      },
    ],
  },
];

export default data;
