const data = [
    {
      sectionLabel: "Polyester reçine gurubu ",
      tabs: [
        {
          label: "Boytek",
          accordions: [
            {
              title: "Genel amaçlı CTP",
              rows: [
                { name: "BRE 310", type: "Ortoftalik", description: "Orta reaktivite, yüksek HDT, ince laminat" },
                { name: "BRE 314 EE", type: "Ortoftalik", description: "Genel amaçlı, endüstriyel parçalar" },
                { name: "BRE 318", type: "Ortoftalik", description: "Kalın laminat, kontrollü kürleşme" },
                { name: "BRE 330 SA", type: "Ortoftalik", description: "Gıda ile temasa uygunluğu onaylanmış" },
                { name: "BRE 330 SA Beyaz", type: "Ortoftalik", description: "Gıda ile temasa uygunluğu onaylanmış, hidrolitik dayanımlı, su parklarına uygun" },
                { name: "BRE 365", type: "Ortoftalik", description: "Endüstriyel parçalar, denizcilik, otomotiv, mükemmel mekanik özellikler" },
                { name: "BRE 452", type: "Ortoftalik", description: "Genel amaçlı, endüstriyel parçalar" },
                { name: "BRE 455", type: "Ortoftalik", description: "Otomotiv, denizcilik için hızlı kürleşme" }
              ]
            },
            {
              title: "DCPD bazlı CTP",
              rows: [
                
                  { name: "BRE 3021", type: "DCPD", description: "Düşük stiren, düşük ekzoterm" },
                  { name: "BRE 3030", type: "DCPD", description: "Genel amaçlı" },
                  { name: "BRE 4560", type: "DCPD", description: "Düşük ekzoterm, düşük stiren, düşük çekme, hızlı kürleşme" }
              
              
              ]
            },
            {
              title: "Sürekli laminat",
              rows: [
                
                  { name: "BRE 312", type: "Ortoftalik", description: "Eternit/oluklu levha tipi, yüksek HDT, dış şartlara yüksek dayanım" },
                  { name: "BRE 314 ET", type: "Ortoftalik", description: "Eternit/oluklu levha tipi, yüksek ışık geçirgenliği" },
                  { name: "BRE 360", type: "Ortoftalik", description: "Düz levha ve oluklu levha, yüksek ışık geçirgenliği" },
                  { name: "BRE 362", type: "DCPD", description: "Üstün yüzey kalitesine sahip düz levha üretimi" },
                  { name: "BRE 324", type: "", description: "Düz levha tipi dolgulu uygulamalara uygun" },
                  { name: "BRE 375", type: "", description: "Düz ve oluklu levha tipi" }
              
              
              ]
            },
              {
                title: "Döküm (cast)",
                rows: [
                  { name: "BRE 320 AX", type: "Ortoftalik", description: "Mükemmel şeffaf döküm, şeffaf heykel, hediyelik eşya vb." },
                  { name: "BRE 320K", type: "Ortoftalik", description: "Mükemmel renk, suni mermer, oniks" },
                  { name: "BRE 323KX", type: "Ortoftalik", description: "Mükemmel renk, düşük viskozite, yüksek dolgu kapasitesi" },
                  { name: "BRE 325", type: "", description: "Jelkotsuz döküm" },
                  { name: "BRE 450", type: "Ortoftalik", description: "Genel amaçlı döküm, mutfak tezgahları, lavabo" }
                ]
              },
              {
                title: "Düğme tipi",
                rows: [
                  { name: "BRE 320A", type: "Ortoftalik", description: "Merdane döküm, mükemmel şeffaflık, kolay işlenebilme" },
                  { name: "BRE 320 AÇ", type: "Ortoftalik", description: "Çubuk tipi tiksotropik düğme polyesteri" },
                  { name: "BRE 320B", type: "Ortoftalik", description: "Merdane tipi, standart uygulama" },
                  { name: "BRE 823", type: "Ortoftalik", description: "Kırılmayan düğme için esnek tip" }
                ]
              },
              {
                title: "Plastifiyan",
                rows: [
                  { name: "BRE 817", type: "Ortoftalik", description: "Esnek reçine" },
                  { name: "BRE 818", type: "İzoftalik", description: "Esnek reçine" },
                  { name: "BRE 820 Liner", type: "Özel", description: "Santrifüj yöntemi, yüksek esneklik" }
                ]
              },
              {
                title: "SMC, BMC",
                rows: [
                  { name: "BRE 301", type: "Ortoftalik", description: "Hızlı kıvamlaşan SMC-BMC reçinesi" },
                  { name: "BRE 309", type: "Ortoftalik", description: "SMC, kıvamlaşmaya uygun, LS uygulamaları, yüksek reaktivite" },
                  { name: "BRE AS33", type: "PS", description: "SMC-BMC uygulamaları, LS katkısı" },
                  { name: "BRE XP401", type: "Maleik", description: "SMC-BMC, LS-LP uygulamaları, mükemmel yüzey profili" }
                ]
              }, {
                title: "Pultrüzyon",
                rows: [
                  { name: "BRE 305", type: "İzoftalik/NPG", description: "Kimyasal dayanımlı pultrüzyon reçinesi" },
                  { name: "BRE 307", type: "İzoftalik", description: "Esnek pultrüzyon" },
                  { name: "BRE XP173", type: "İzoftalik", description: "Pultrüzyon, yüksek mekanik özellikler" },
                  { name: "BRE XP175", type: "DCPD", description: "Pultrüzyon, yüksek reaktivite, iyi yüzey profili" },
                  { name: "BRE XP601", type: "Ortoftalik", description: "Standart pultrüzyon uygulamaları" }
                ]
              },
              {
                title: "Kimyasal maddelere dayanıklı",
                rows: [
                  { name: "BRE 311", type: "İzoftalik/NPG", description: "Kimyasal dayanımlı, denizcilik, depolama tankları" },
                  { name: "BRE 315", type: "İzoftalik/Özel", description: "Yüksek kimyasal dayanım, depolama tankları, yüksek HDT" },
                  { name: "BRE 538", type: "İzoftalik", description: "Kimyasal dayanım, denizcilik, depolama tankları" }
                ]
              },
              {
                title: "Alev ilerletmeyen",
                rows: [
                  { name: "BRE 181", type: "Halojensiz", description: "Alev ilerletmeyen halojensiz" },
                  { name: "BRE 326", type: "", description: "Kimyasal dayanımlı şeffaf" },
                  { name: "BRE 327", type: "Halojenli", description: "Işık geçirgen, eternit/oluklu levha tipi" },
                  { name: "BRE 328", type: "Halojenli", description: "Dolgulu, UL 94 V-0, yüksek derecede alev ilerletmeyen" },
                  { name: "BRE 329", type: "Halojenli", description: "Dolgulu, UL 94 V-0, orta derecede alev ilerletmeyen" }
                ]
              },
              {
                title: "Mobilya ve top coat tipi",
                rows: [
                  { name: "BRE 120", type: "Ortoftalik", description: "Mobilya kaplama, çok düşük viskozite" },
                  { name: "BRE 121", type: "", description: "Son kat parlak" },
                  { name: "BRE 122", type: "Özel", description: "Polyester dolgu verniği" },
                  { name: "BRE 122 B", type: "Özel", description: "Beyaz polyester dolgu verniği" },
                  { name: "BRE 123", type: "", description: "Son kat tam mat" }
                ]
              },
              {
                title: "Macun ve yapıştırıcı",
                rows: [
                  { name: "BRE 3010", type: "DCPD", description: "Mermer tamir macunu üretimi, iyi yapışma, uzun ömür" },
                  { name: "BRE 3020", type: "DCPD", description: "Otomotiv tamir macunu üretimi, uzun ömür, esneklik" },
                  { name: "BRE 3023", type: "DCPD", description: "Oto tamir macunu üretimi, galvanize mükemmel yapışma" }
                ]
              },
              {
                title: "Akrilik",
                rows: [
                  { name: "BRE 313RA", type: "Ortoftalik", description: "Akrilik takviye, MEK Peroksit indikatörlü" },
                  { name: "BRE 414", type: "Ortoftalik", description: "Akrilik takviye, dolgulu, MEK Peroksit indikatörlü" },
                  { name: "BRE 415", type: "Ortoftalik", description: "Akrilik takviye, yüksek dolgu oranı, MEK Peroksit indikatörlü" },
                  { name: "BRE 416", type: "Ortoftalik", description: "ABS ve akrilik levhaya mükemmel yapışma, dolgulu" },
                  { name: "BRE 513", type: "Ortoftalik", description: "Akrilik takviye, dolgusuz, yüksek dolgu kaldırma kapasitesi, MEK Peroksit indikatörlü" },
                  { name: "BRE 660", type: "Ortoftalik", description: "ABS ve akrilik levhaya mükemmel yapışma, dolgusuz" }
                ]
              },
              {
                title: "RTM, İnfüzyon",
                rows: [
                  { name: "BRE 322", type: "Ortoftalik", description: "Standart RTM reçinesi" },
                  { name: "BRE 3024", type: "Ortoftalik/DCPD", description: "İyi elyaf ıslatımı, RTM, infüzyon, denizcilik, otomotiv" },
                  { name: "BRE 4982", type: "DCPD", description: "Hızlı kürleşme, RTM, düşük viskozite" }
                ]
              },
              {
                title: "Sandviç panel",
                rows: [
                  { name: "BRE 400", type: "Özel", description: "Sandviç paneller, stiren köpüğünü (styrofoam) etkilemez" }
                ]
              },
              
            
            
          ]
        },
        {
          label: "Camelyaf-CE",
          accordions: [
            {
              title: "El yatırması, elyaf püskürtme",
              rows: [
                { name: "CE 92 N8", type: "Ortoftalik", description: "CTP" },
                { name: "CE 188 N8", type: "Ortoftalik", description: "CTP" },
                { name: "CE 266 N12", type: "İzoftalik", description: "CTP" }
              ]
            },
            {
              title: "Döküm (cast)",
              rows: [
                { name: "CE 70 N4", type: "Ortoftalik", description: "Döküm" },
                { name: "CE 80", type:"", description: "Döküm" }
              ]
            },
            {
              title: "SMC-BMC, Sıcak pres",
              rows: [
                { name: "CE BV8", type: "Ortoftalik", description: "SMC-BMC" }
              ]
            },
            {
              title: "Fitil sarma",
              rows: [
                { name: "CE 66 N4 (dökme)", type: "",  description: "Fitil sarma" },
                { name: "CE 95", type: "rPet",  description: "CTP boru tipi rPET" }
              ]
            },
            {
              title: "Akrilik arkası",
              rows: [
                { name: "CE 28-DTA-35 Yenilenmiş Varil", type: "",  description: "Akrilik, dolgulu, hızlandırıcılı" },
                { name: "CE 95 Yenilenmiş Varil",type: "",  description: "Akrilik, dolgulu, hızlandırıcılı" }
              ]
            },
            
          ]
        },
        {
          label: "İlkester",
          accordions: [
            {
              title: "Genel amaçlı",
              rows: [
                { name: "P-192", type: "Ortoftalik doymamış polyester reçine", description : "CTP uygulamaları" },
                { name: "P-192 TA", type: "Ortoftalik damlamayan doymamış polyester reçine", description : "CTP uygulamaları" },
                { name: "P-1920", type: "Ortoftalik doymamış polyester reçine", description : "CTP uygulamaları" },
                { name: "P-116", type: "Ortoftalik doymamış polyester reçine", description : "CTP seri üretim" },
                { name: "P-116", type: "Ortoftalik yüksek mukavemetli doymamış polyester reçine", description : "CTP seri üretim" },
                { name: "P-1016", type: "Ortoftalik doymamış polyester reçine", description : "CTP seri üretim" },
                { name: "P-1017 ETPS", type: "Tiksotropik düşük stren emisyonlu", description : "CTP uygulamaları" },
                { name: "P-192 BS", type: "Ortoftalik doymamış polyester reçine", description : "Kafes uygulamaları" },
                { name: "P-920", type: "Ortoftalik doymamış polyester reçine", description : "Modüler üretim" }
              ]
            },
            
             { 
              title:"Döküm (cast) tipi",
              rows: [
                  { name: "P-068", type: "Ortoftalik doymamış polyester reçine", description: "Döküm (cast) uygulamaları" },
                  { name: "P-060", type: "Ortoftalik doymamış polyester reçine", description: "Döküm (cast) uygulamaları" },
                  { name: "P-053", type: "Ortoftalik doymamış polyester reçine", description: "Döküm (cast) uygulamaları" },
                  { name: "P-6817", type: "Ortoftalik doymamış polyester reçine", description: "Döküm (cast) uygulamaları" },
                  { name: "P-104", type: "Ortoftalik doymamış polyester reçine", description: "Şeffaf Döküm (cast) uygulamaları" }
              ],
            },
            {
              title:"Kimyasallara dayanıklı",
              rows: [
                  { name: "IR-100", type: "İzoftalik doymamış polyester reçine", description: "CTP uygulamaları" },
                  { name: "IR-100 T", type: "İzoftalik tiksotropik doymamış polyester reçine", description: "CTP uygulamaları" },
                  { name: "IR-110 T", type: "İzoftalik tiksotropik doymamış polyester reçine", description: "CTP uygulamaları" },
                  { name: "PV-300", type: "Bisfenol-A epoksi vinilester reçine", description: "CTP uygulamaları" },
                  { name: "PV-300 T", type: "Bisfenol-A epoksi vinilester tiksotropik reçine", description: "CTP uygulamaları" },
                  { name: "PV-200", type: "Bisfenol-A epoksi vinilester reçine", description: "CTP uygulamaları" },
                  { name: "PV-290 PS", type: "Bisfenol-A epoksi vinilester reçine", description: "Kafes uygulamaları" },
                  { name: "IR-105 LMR", type: "İzoftalik doymamış polyester reçine", description: "RTM uygulamaları" },
                  { name: "IR-106 LMR", type: "İzoftalik doymamış polyester reçine", description: "Şeffaf uygulamalar" },
                  { name: "IR-3020 BS", type: "İzoftalik doymamış polyester reçine", description: "Kafes uygulamaları" },
                  { name: "IR-355 LMR", type: "İzoftalik doymamış polyester reçine", description: "Pultruzyon uygulamaları" }
              ]
            },
            {
            title : "RTM",
             rows : [
                  { name: "P-141 LMR", type: "Ortoftalik doymamış polyester reçine", description: "RTM uygulamaları" },
                  { name: "P-4150 LMR", type: "Ortoftalik doymamış polyester reçine", description: "RTM uygulamaları" },
                  { name: "P-2150 LMR", type: "İzoftalik doymamış polyester reçine", description: "RTM uygulamaları" },
                  { name: "IR-105 LMR", type: "İzoftalik doymamış polyester reçine", description: "RTM uygulamaları" },
                  { name: "IR-106 LMR", type: "İzoftalik doymamış polyester reçine", description: "Şeffaf RTM uygulamaları" }
              ]
            },
            {  title: "SMC - BMC",
              rows: [
                  { name: "P-1158", type: "",description: "Ortoftalik BMC tipi doymamış polyester reçine" },
                  { name: "P-1186 PLUS", type: "",description: "Ortoftalik SMC - BMC tipi doymamış polyester reçine" },
                  { name: "P-1158", type: "",description: "Ortoftalik SMC - BMC tipi doymamış polyester reçine" }
              ],
            },
            {
            title :  "Akrilik destek (backup)" ,
            rows : [
                  { name: "P-470", type: "Ortoftalik tiksotropik doymamış polyester reçine", description: "Akrilik destek uygulamaları" },
                  { name: "P-475", type: "Ortoftalik tiksotropik dolgulu doymamış polyester reçine", description: "Akrilik destek uygulamaları" }
              ]
            },
            {
              title: "Pultruzyon",
              rows: [
                  { name: "P-755", type: "Ortoftalik doymamış polyester reçine", description: "Pultruzyon uygulamaları" },
                  { name: "P-475", type: "İzoftalik doymamış polyester reçine", description: "Pultruzyon destek uygulamaları" }
              ]
          },
          {
              title: "Alev geciktirici",
              rows: [
                  { name: "FR-40 (EN 45545-2)", type: "Spesifik reçine bazlı tiksotropik halojensiz doymamış polyester", description: "EN 45545-2 (HL2) uyumlu uygulamalar" },
                  { name: "FR-40 (DIN 5510-2)", type: "Spesifik reçine bazlı tiksotropik halojensiz doymamış polyester", description: "EN 45545-2 (S4, SR2, ST2) uyumlu uygulamalar" },
                  { name: "FR-50", type: "Spesifik reçine bazlı tiksotropik halojensiz doymamış polyester", description: "Standart alev geciktirici uygulamalar" }
              ]
          },
          {
              title: "Düğme tipi",
              rows: [
                  { name: "P-104D", type: "Ortoftalik doymamış polyester reçine", description: "Santrifüjlü döküm (cast) düğme üretimi" },
                  { name: "P-405D", type: "Ortoftalik doymamış polyester reçine", description: "Santrifüjlü döküm (cast) düğme üretimi" },
                  { name: "P-128D", type: "Ortoftalik doymamış polyester reçine", description: "Santrifüjlü döküm (cast) düğme üretimi" },
                  { name: "P-1004D", type: "Ortoftalik tiksotropik doymamış polyester reçine", description: "Çubuk tip döküm (cast) düğme üretimi" },
                  { name: "P-1006D", type: "Ortoftalik tiksotropik doymamış polyester reçine", description: "Çubuk tip döküm (cast) düğme üretimi" },
                  { name: "P-4004D", type: "Ortoftalik tiksotropik doymamış polyester reçine", description: "Çubuk tip döküm (cast) düğme üretimi" }
              ]
          },
          {
            title: "Ahşap kaplama",
            rows: [
                { name: "P-1020", type: "Ortoftalik yüksek reaktif doymamış polyester reçine", description: "İlk kat vernik üretimi" },
                { name: "P-1002", type: "Doymamış polyester reçine bazlı dolgu verniği", description: "İlk kat vernik üretimi" },
                { name: "P-2002", type: "Doymamış polyester reçine bazlı beyaz astar", description: "İlk kat beyaz astar" }
            ]
        },
        {
            title: "Abrasiv - Akemi",
            rows: [
                { name: "PM-220", type: "Ortoftalik abrasiv tip doymamış polyester reçine", description: "Abrasiv taş imalatı" },
                { name: "PM-245", type: "Ortoftalik doymamış polyester reçine", description: "Mermer için yapıştırıcı pasta imalatı" },
                { name: "PM-246", type: "Ortoftalik doymamış polyester reçine", description: "Mermer için yapıştırıcı pasta imalatı" }
            ]
        },
        {
            title: "Spesifik",
            rows: [
                { name: "LAK-1030", type: "Parlak doymamış polyester reçine", description: "Hava kurutmalı finisaj, çatlak, delik kapatma, parlak cila" },
                { name: "LAK-1030 T", type: "Tiksotropik parlak doymamış polyester reçine", description: "Hava kurutmalı parlak CTP cila, mobilya finisaj, metal yüzey boşluk doldurma" },
                { name: "RP-92", type: "Ortoftalik plastikleştirici tip doymamış polyester", description: "Polyester reçine ve jelkotlar için esnekleştirici" },
                { name: "PVM-300", type: "Spesifik ürün", description: "" }
            ]
        }
          ]
        },
        {
          label: "Poliya",
          accordions: [
            {
              title: "El yatırması, elyaf püskürtme",
              rows: [
                  { name: "Polipol 3401", type: "Ortoftalik", description: "CTP" },
                  { name: "Polipol 3401-A", type: "Ortoftalik", description: "CTP-hızlandırıcılı" },
                  { name: "Polipol 3401-TA-H40", type: "Ortoftalik", description: "CTP-tiksotropik hızlandırıcılı" },
                  { name: "Polipol 3401-TA-LSE-H40", type: "Ortoftalik", description: "CTP-tiksotropik hızlandırıcılı LSE" },
                  { name: "Polipol 3401-TA-H20", type: "Ortoftalik", description: "CTP-tiksotropik hızlandırıcılı" },
                  { name: "Polipol 3401-TA-LSE-H20", type: "Ortoftalik", description: "CTP-tiksotropik hızlandırıcılı LSE" },
                  { name: "Polipol 3401-TA-LSE-H20-Beyaz", type: "Ortoftalik", description: "CTP-tiksotropik hızlandırıcılı LSE beyaz" },
                  { name: "Polipol 339-TA-LSE-H40", type: "Ortoftalik", description: "CTP-orta reaktif tiksotropik hızlandırıcılı LSE" },
                  { name: "Polipol 339-TA-LSE-H20", type: "Ortoftalik", description: "CTP-orta reaktif tiksotropik hızlandırıcılı LSE" },
                  { name: "Polipol 351", type: "Ortoftalik", description: "CTP-orta/yüksek reaktif" },
                  { name: "Polipol 351-TA-LSE-H40", type: "Ortoftalik", description: "CTP-orta/yüksek reaktif tiksotropik hızlandırıcılı LSE" },
                  { name: "Polipol 351-TA-LSE-H20", type: "Ortoftalik", description: "CTP-orta/yüksek reaktif tiksotropik hızlandırıcılı LSE" },
                  { name: "Polipol 3471-TA-LSE", type: "Ortoftalik", description: "CTP-yüksek reaktif tiksotropik hızlandırıcılı LSE" },
                  { name: "Polipol 3872", type: "İzoftalik", description: "Kimyasal dayanımlı" },
                  { name: "Polipol 3872-TA-LSE-H40", type: "İzoftalik", description: "CTP-tiksotropik hızlandırıcılı LSE" },
                  { name: "Polipol 383-T", type: "İzoftalik NPG", description: "CTP-tiksotropik marin" },
                  { name: "Polipol 344-T", type: "THPA", description: "CTP-tiksotropik" },
                  { name: "Polipol 3469-TA-LSE", type: "DCPD", description: "CTP-tiksotropik hızlandırıcılı LSE" }
              ]
          },
          {
              title: "Döküm (cast)",
              rows: [
                  { name: "Polipol 3455", type: "Ortoftalik", description: "Döküm" },
                  { name: "Polipol 353", type: "Ortoftalik", description: "Döküm-yüksek dolgu kapasiteli" },
                  { name: "Polipol 3813", type: "İzoftalik NPG", description: "Döküm-masif yüzey" },
                  { name: "Polipol 383-G", type: "İzoftalik NPG Akrilik", description: "Döküm-masif yüzey akrilik modifiye" }
              ]
          },
          {
            title: "RTM, İnfüzyon, Vakum Enjeksiyon",
            rows: [
                { name: "Polipol 332", type: "Ortoftalik", description: "RTM" },
                { name: "Polipol 335", type: "Ortoftalik", description: "RTM, İnfüzyon" },
                { name: "Polipol 336", type: "Ortoftalik", description: "RTM" },
                { name: "Polipol 334", type: "İzoftalik", description: "RTM, İnfüzyon" },
                { name: "Polipol 338-A", type: "İzoftalik", description: "RTM, İnfüzyon hızlandırıcılı" },
                { name: "Polipol 3382", type: "İzoftalik NPG", description: "RTM, İnfüzyon yüksek HDT" },
                { name: "Polipol 3567", type: "DCPD", description: "RTM, İnfüzyon düşük çekmeli" },
                { name: "Polipol 3387", type: "DCPD", description: "Zero Sistem, RTM, İnfüzyon sıfır çekmeli" },
                { name: "Polipol 3876", type: "HET - ISO", description: "Alev ilerletmeyen, şeffaf" }
            ]
        },
        {
            title: "Soğuk pres",
            rows: [
                { name: "Polipol 344", type: "THPA", description: "Soğuk pres" }
            ]
        },
        {
            title: "SMC-BMC, Sıcak pres",
            rows: [
                { name: "Polipol 347", type: "Ortoftalik", description: "SMC-BMC" },
                { name: "Polipol 3417", type: "Ortoftalik", description: "SMC-BMC, yüzey kalitesi yüksek" },
                { name: "Polipol 382", type: "İzoftalik NPG", description: "SMC-BMC, yüksek kimyasal dayanımlı" },
                { name: "Polipol 3418", type: "MA", description: "SMC-BMC, tam maleik" }
            ]
        },
        {
          title: "Pultrüzyon",
          rows: [
              { name: "Polipol 351-I", type: "Ortoftalik", description: "Orta/yüksek reaktif" },
              { name: "Polipol 3872-I", type: "İzoftalik", description: "Kimyasal dayanımlı" },
              { name: "Polipol 3870-I", type: "İzoftalik", description: "Pultrüzyon-yüksek reaktif kimyasal dayanımlı" },
              { name: "Polipol 3801", type: "İzoftalik", description: "Pultrüzyon-yüksek HDT kimyasal dayanımlı" }
          ]
      },
      {
          title: "Fitil sarma",
          rows: [
              { name: "Polipol 3562", type: "Ortoftalik", description: "Fitil sarma" },
              { name: "Polipol 3872-I", type: "İzoftalik", description: "Kimyasal dayanımlı" },
              { name: "Polipol 388", type: "İzoftalik", description: "Fitil sarma-iç yüzey liner" }
          ]
      },
      {
          title: "Sürekli çekme",
          rows: [
              { name: "Polipol 3403", type: "Ortoftalik", description: "Sürekli çekme" },
              { name: "Polipol 351-I", type: "Ortoftalik", description: "Orta/yüksek reaktif" },
              { name: "Polipol 361-L", type: "Ortoftalik Akrilik", description: "Sürekli çekme-akrilik modifiye UV dayanımlı ışık geçirgen" },
              { name: "Polipol 3872-I", type: "İzoftalik", description: "Kimyasal dayanımlı" },
              { name: "Polipol 763", type: "DCPD", description: "Sürekli çekme" },
              { name: "Polipol 765", type: "DCPD", description: "Sürekli çekme-yüzey kalitesi yüksek" },
              { name: "Polipol 309", type: "HET", description: "Yüksek performans alev ilerletmeyen şeffaf" }
          ]
      },
      {
          title: "Akrilik arkası",
          rows: [
              { name: "Polipol 341-TA-D30-Beyaz", type: "Ortoftalik", description: "Akrilik-tiksotropik hızlandırıcılı dolgulu beyaz" },
              { name: "Polipol 341-TA-D40-Beyaz", type: "Ortoftalik", description: "Akrilik-tiksotropik hızlandırıcılı dolgulu beyaz" },
              { name: "Polipol 341-TA-Beyaz", type: "Ortoftalik", description: "Akrilik-tiksotropik hızlandırıcılı dolgusuz beyaz" }
          ]
      },
      {
        title: "Düşük stiren emisyonlu-LSE",
        rows: [
            { name: "Polipol 3401-TA-LSE-H40", type: "Ortoftalik", description: "CTP-tiksotropik hızlandırıcılı LSE" },
            { name: "Polipol 3401-TA-LSE-H20-Beyaz", type: "Ortoftalik", description: "CTP-tiksotropik hızlandırıcılı LSE beyaz" },
            { name: "Polipol 339-TA-LSE-H40", type: "Ortoftalik", description: "CTP-orta reaktif tiksotropik hızlandırıcılı LSE" },
            { name: "Polipol 339-TA-LSE-H20", type: "Ortoftalik", description: "CTP-orta reaktif tiksotropik hızlandırıcılı LSE" },
            { name: "Polipol 351-TA-LSE-H40", type: "Ortoftalik", description: "CTP-orta/yüksek reaktif tiksotropik hızlandırıcılı LSE" },
            { name: "Polipol 351-TA-LSE-H20", type: "Ortoftalik", description: "CTP-orta/yüksek reaktif tiksotropik hızlandırıcılı LSE" },
            { name: "Polipol 3471-TA-LSE", type: "Ortoftalik", description: "CTP-yüksek reaktif tiksotropik hızlandırıcılı LSE" },
            { name: "Polipol 3872-TA-LSE-H40", type: "İzoftalik", description: "CTP-tiksotropik hızlandırıcılı LSE" },
            { name: "Polipol 3469-TA-LSE", type: "DCPD", description: "CTP-tiksotropik hızlandırıcılı LSE" }
        ]
    },
    {
        title: "Kimyasal maddelere dayanıklı",
        rows: [
            { name: "Polipol 3872", type: "İzoftalik", description: "Kimyasal dayanımlı" },
            { name: "Polipol 381", type: "İzoftalik NPG", description: "Yüksek kimyasal dayanımlı" },
            { name: "Polipol 391", type: "BPA", description: "Yüksek kimyasal dayanımlı bisfenolik" }
        ]
    },
    {
        title: "Alev ilerletmeyen",
        rows: [
            { name: "Polipol 311-DT", type: "Ortoftalik", description: "Alev ilerletmeyen tiksotropik dolgulu" },
            { name: "Polipol 312-FRT", type: "Ortoftalik", description: "Alev ilerletmeyen halojensiz tiksotropik dolgulu" },
            { name: "Polipol 340-FRT", type: "Ortoftalik", description: "Alev ilerletmeyen halojensiz tiksotropik dolgulu yüksek performans" },
            { name: "Polipol 313-DT", type: "HET", description: "Alev ilerletmeyen tiksotropik dolgulu" },
            { name: "Polipol 314-DT", type: "HET", description: "Alev ilerletmeyen tiksotropik dolgulu yüksek mekanik dayanımlı" },
            { name: "Polipol 3876", type: "HET/ISO", description: "Alev ilerletmeyen şeffaf" },
            { name: "Polipol 315-DT", type: "HET", description: "Alev ilerletmeyen tiksotropik dolgulu yüksek performans" },
            { name: "Polipol 305", type: "HET", description: "Alev ilerletmeyen şeffaf" },
            { name: "Polipol 308", type: "HET", description: "Alev ilerletmeyen şeffaf" },
            { name: "Polipol 309", type: "HET", description: "Alev ilerletmeyen yüksek performans şeffaf" }
        ]
    },
    {
        title: "Macun ve yapıştırıcı",
        rows: [
            { name: "Polipol 4344-ABP", type: "Ortoftalik", description: "Amin hızlandırıcılı hızlı kürlenen mermer yapıştırıcı" },
            { name: "Polipol 436-ABP", type: "DCPD", description: "Amin hızlandırıcılı macun tipi esnek" },
            { name: "Polipol 440-ABP", type: "DCPD", description: "Amin hızlandırıcılı macun tipi sert" }
      ]
    },
    {
        title: "Düğme tipi",
        rows: [
            { name: "Polipol 3541", type: "Ortoftalik", description: "Düğme polyesteri" },
            { name: "Polipol 3541-T", type: "Ortoftalik", description: "Çubuk tipi düğme polyesteri" },
            { name: "Polipol 3542", type: "Ortoftalik", description: "Yüksek performans düğme polyesteri" },
            { name: "Polipol 3542-T", type: "Ortoftalik", description: "Yuksek performans çubuk tipi düğme polyesteri" }
      ]
    },
    {
        title: "Mühendislik dökümleri",
        rows: [
            { name: "Polipol 357", type: "Ortoftalik", description: "Kuvars kompoze taş tipi" },
            { name: "Polipol 359", type: "Ortoftalik", description: "Kuvars kompoze taş tipi" },
            { name: "Polipol 3858-L", type: "Özel", description: "Kuvars kompoze taş tipi, dış ortama dayanıklı" }
        ]
    },
    {
        title: "Düşük stiren içeren / stirensiz",
        rows: [
            { name: "Polipol 3515-A", type: "Ortoftalik", description: "Polistiren köpük kaplama" }
        ]
    },
    {
        title: "Özel - Amin hızlandırıcı",
        rows: [
            { name: "Polipol 4344-ABP", type: "Ortoftalik", description: "Amin hızlandırıcılı hızlı kürlenen mermer yapıştırıcı" },
            { name: "Polipol 436-ABP", type: "DCPD", description: "Amin hızlandırıcılı macun tipi esnek" },
            { name: "Polipol 440-ABP", type: "DCPD", description: "Amin hızlandırıcılı macun tipi sert" }
        ]
    },
    {
        title: "Özel - UV kür yüzey",
        rows: [
            { name: "Polipol 3543-UV", type: "Ortoftalik", description: "UV Kürlenmeli" }
        ]
    },
    {
        title: "Özel - Boru rehabilitasyon",
        rows: [
            { name: "Polipol 3851-THV", type: "İzoftalik", description: "Boru rehabilitasyonu" }
        ]
    },
    {
        title: "Özel - Saydam döküm",
        rows: [
            { name: "Polipol 354", type: "Özel", description: "Saydam döküm" }
        ]
    },
    {
        title: "Özel - Plastifiyan, esnek",
        rows: [
            { name: "Polipol 373", type: "Ortoftalik", description: "Plastifiyan" },
            { name: "Polipol 372", type: "Ortoftalik", description: "Plastifiyan, açık renk" },
            { name: "Polipol 3883", type: "İzoftalik", description: "Plastifiyan" }
        ]
    },
    {
        title: "Özel - Parlak, cila tipi",
        rows: [
            { name: "Polipol 401", type: "Allilik", description: "Parafinsiz, hava kurumalı astar baz" },
            { name: "Polipol 402", type: "Allilik", description: "Parafinsiz, hava kurumalı astar baz" },
            { name: "Polipol 400", type: "Allilik", description: "Parafinsiz, hızlı hava kurumalı astar baz" },
            { name: "Polipol 240", type: "Allilik", description: "Parlak, kaplama tipi hava kurumalı astar baz" }
        ]
    },
        
        
          ]
        },
        {
          label: "Turkuaz",
          accordions: [
            {
              title: "Döküm Tipi",
              rows: [
                  { name: "TP100", type: "Ortoftalik", description: "Döküm Tipi" },
                  { name: "TP140", type: "Ortoftalik", description: "Döküm Tipi" },
                  { name: "TP80", type: "Tereftalik", description: "Döküm Tipi" },
                  { name: "TP160", type: "Ortoftalik", description: "Masif Yüzey Döküm Tipi" },
                  { name: "TP171", type: "İzoftalik / NPG (Neopentil Glikol)", description: "Masif Yüzey Döküm Tipi" }
              ]
          },
          {
              title: "Breton Tipi",
              rows: [
                  { name: "TP182", type: "Ortoftalik", description: "Kompoze Taş" },
                  { name: "TP963", type: "Ortoftalik", description: "Kompoze Taş" }
              ]
          },
          {
              title: "Fitil Sarma CTP Boru Uygulamaları",
              rows: [
                  { name: "TP1040", type: "Ortoftalik", description: "Fitil Sarma" },
                  { name: "TP1802", type: "Tereftalik", description: "Fitil Sarma" },
                  { name: "TP1984", type: "Tereftalik", description: "Fitil Sarma" },
                  { name: "TP1986", type: "İzoftalik", description: "Fitil Sarma" }
              ]
          },
          {
              title: "Genel Amaçlı",
              rows: [
                  { name: "TP220", type: "Ortoftalik", description: "El yatırması, Laminasyon" },
                  { name: "TP220 TICO", type: "Ortoftalik", description: "El yatırması, Püskürtme, Laminasyon" },
                  { name: "TP212", type: "Ortoftalik", description: "El yatırması, Laminasyon" },
                  { name: "TP212 TICO", type: "Ortoftalik", description: "El yatırması, Püskürtme, Laminasyon" },
                  { name: "TP200", type: "Ortoftalik", description: "El yatırması, Laminasyon" },
                  { name: "TP200 TICO", type: "Ortoftalik", description: "El yatırması, Püskürtme, Laminasyon" },
                  { name: "TP240", type: "Ortoftalik", description: "El yatırması, Laminasyon" },
                  { name: "TP240 TICO", type: "Ortoftalik", description: "El yatırması, Püskürtme, Laminasyon" },
                  { name: "TP250", type: "Ortoftalik", description: "El yatırması, Laminasyon" },
                  { name: "TP280", type: "Ortoftalik", description: "El yatırması, Laminasyon" },
                  { name: "TP290", type: "Ortoftalik", description: "El yatırması, Laminasyon" },
                  { name: "TP1071", type: "İzoftalik", description: "El yatırması, Laminasyon" },
                  { name: "TP1071 TICO", type: "İzoftalik", description: "El yatırması, Püskürtme, Laminasyon" },
                  { name: "TP56", type: "DCPD (Disiklopentadien)", description: "El yatırması, Laminasyon" },
                  { name: "TP725 TICO", type: "DCPD / Ortoftalik", description: "El yatırması, Püskürtme, Laminasyon" },
                  { name: "TP90", type: "Tereftalik", description: "El yatırması, Laminasyon" },
                  { name: "TP90 TICO", type: "Tereftalik", description: "El yatırması, Püskürtme, Laminasyon" }
              ]
          },
          
            {
                title: "Sürekli Çekme (Levha) Tipi",
                rows: [
                    { name: "TP800", type: "Ortoftalik", description: "Sürekli Çekme (Levha)" },
                    { name: "TP812", type: "Ortoftalik", description: "Sürekli Çekme (Levha)" },
                    { name: "TP2291", type: "Ortoftalik", description: "Sürekli Çekme (Levha)" },
                    { name: "TP56", type: "DCPD (Disiklopentadien)", description: "Sürekli Çekme (Levha)" }
                ]
            },
            {
                title: "SMC ve BMC Uygulamaları",
                rows: [
                    { name: "TP500", type: "Ortoftalik", description: "BMC" },
                    { name: "TP535", type: "Ortoftalik", description: "SMC-BMC" }
                ]
            },
            {
                title: "Akrilik Tipi",
                rows: [
                    { name: "TP340", type: "Ortoftalik", description: "Akrilik ABS Uygulamaları" },
                    { name: "TP341", type: "Ortoftalik", description: "Akrilik ABS Uygulamaları" },
                    { name: "TP1300", type: "Ortoftalik", description: "Akrilik ABS Uygulamaları" },
                    { name: "TP1301", type: "Ortoftalik", description: "Akrilik ABS Uygulamaları" },
                    { name: "TP1400", type: "Ortoftalik", description: "Akrilik ABS Uygulamaları" }
                ]
            },
            {
              title: "Düğme Tipi",
              rows: [
                  { name: "TP400", type: "Ortoftalik", description: "Santrifuj Döküm" },
                  { name: "TP400C", type: "Ortoftalik", description: "Çubuk Döküm" },
                  { name: "TP409", type: "Ortoftalik", description: "Santrifuj Döküm" },
                  { name: "TP409C", type: "Ortoftalik", description: "Çubuk Döküm" },
                  { name: "TP410", type: "Ortoftalik", description: "Santrifuj Döküm" }
              ]
          },
          {
              title: "RTM - İnfüzyon Uygulamaları",
              rows: [
                  { name: "TP260", type: "Ortoftalik", description: "RTM" },
                  { name: "TP911-CO", type: "Ortoftalik", description: "RTM" },
                  { name: "TP911-CO-I", type: "Ortoftalik", description: "İnfüzyon" },
                  { name: "TP976", type: "İzoftalik", description: "RTM" },
                  { name: "TP976-CO-I", type: "Ortoftalik", description: "İnfüzyon" }
              ]
          },
          {
              title: "Pultrüzyon Uygulamaları",
              rows: [
                  { name: "TP1075 PU", type: "İzoftalik", description: "Pultrüzyon" },
                  { name: "TP1071 PU", type: "İzoftalik", description: "Pultrüzyon" },
                  { name: "TP200 PU", type: "Ortoftalik", description: "Pultrüzyon" },
                  { name: "TP201 PU", type: "Ortoftalik", description: "Pultrüzyon" }
              ]
          },
          {
              title: "Macun Tipi",
              rows: [
                  { name: "TP55 AABP", type: "DCPD (Disiklopentadien)", description: "Macun Tipi" }
              ]
          },
          {
              title: "Kimyasal Maddelere Dayanıklı",
              rows: [
                  { name: "TP171", type: "İzoftalik / NPG (Neopentil Glikol)", description: "Yüksek Kimyasal, Su Korozyona Dirençli" },
                  { name: "TP1071", type: "İzoftalik", description: "El yatırması, Laminasyon" },
                  { name: "TP1412", type: "İzoftalik / NPG (Neopentil Glikol)", description: "Yüksek Kimyasal, Su Korozyona Dirençli" },
                  { name: "TP1986", type: "İzoftalik", description: "Yüksek Kimyasal Dirençli" }
              ]
          },
        
        
          
          ]
        }
      ]
    },
    {
      sectionLabel: "Vinilister Reçine Gurubu ",
      tabs: [
        {
          label: "Boytek",
          accordions: [
            {
              title: "El yatırması, elyaf püskürtme",
              rows: [
                  { name: "Polives 701",description: "Yüksek kimyasal dayanımlı performans" },
                  { name: "Polives 701-TA",description: "CTP, tiksotropik hızlandırıcılı performans" },
                  { name: "Polives 711",description: "Yüksek kimyasal dayanımlı yüksek performans" },
                  { name: "Polives 711-TA",description: "CTP, tiksotropik hızlandırıcılı yüksek performans" },
                  { name: "Polives 710",description: "CTP, alev ilerletmeyen" }
              ]
          },
          {
              title: "RTM, İnfüzyon, Vakum Enjeksiyon",
              rows: [
                  { name: "Polives 702",description: "RTM, İnfüzyon" },
                  { name: "Polives 711-I",description: "RTM, İnfüzyon yüksek performans" }
              ]
          },
          {
              title: "Pultrüzyon",
              rows: [
                  { name: "Polives 711",description: "Yüksek kimyasal dayanımlı yüksek performans" }
              ]
          },
          {
              title: "Kimyasal maddelere dayanıklı",
              rows: [
                  { name: "Polives 701",description: "Yüksek kimyasal dayanımlı performans" },
                  { name: "Polives 711",description: "Yüksek kimyasal dayanımlı yüksek performans" },
                  { name: "Polives 710",description: "CTP, alev ilerletmeyen" }
              ]
          },
          {
              title: "Alev ilerletmeyen",
              rows: [
                  { name: "Polives 710",description: "CTP, alev ilerletmeyen" }
              ]
          },
          {
              title: "Özel - Amin hızlandırıcı",
              rows: [
                  { name: "Polives 701-ABP",description: "Amin hızlandırıcılı yüksek kimyasal dayanımlı performans" },
                  { name: "Polives 711-ABP",description: "Amin hızlandırıcılı yüksek kimyasal dayanımlı yüksek performans" }
              ]
          }
          ]
        },
        {
          label: "İlkester",
          accordions: [
            {
              title: "Tam liste",
              rows: [
                  { name: "PV-300", type: "Bisfenol-A epoksi vinilester reçine", description: "CTP uygulamaları" },
                  { name: "PV-300 T", type: "Bisfenol-A epoksi vinilester tiksotropik reçine", description: "CTP uygulamaları" },
                  { name: "PV-200", type: "Bisfenol-A epoksi vinilester reçine", description: "CTP uygulamaları" },
                  { name: "PV-290 PS", type: "Bisfenol-A epoksi vinilester reçine", description: "Kafes uygulamaları" },
                  { name: "PV-290 PS", type: "Bisfenol-A epoksi vinilester reçine", description: "Kimyasal dübel (ankoraj) uygulamaları" }
              ]
          }
          ]
        },
        {
          label: "Poliya",
          accordions: [
            {
              title: "El yatırması, elyaf püskürtme",
              rows: [
                  { name: "Polives 701", description: "Yüksek kimyasal dayanımlı performans" },
                  { name: "Polives 701-TA", description: "CTP, tiksotropik hızlandırıcılı performans" },
                  { name: "Polives 711", description: "Yüksek kimyasal dayanımlı yüksek performans" },
                  { name: "Polives 711-TA", description: "CTP, tiksotropik hızlandırıcılı yüksek performans" },
                  { name: "Polives 710", description: "CTP, alev ilerletmeyen" }
              ]
          },
          {
              title: "RTM, İnfüzyon, Vakum Enjeksiyon",
              rows: [
                  { name: "Polives 702", description: "RTM, İnfüzyon" },
                  { name: "Polives 711-I", description: "RTM, İnfüzyon yüksek performans" }
              ]
          },
          {
              title: "Pultrüzyon",
              rows: [
                  { name: "Polives 711", description: "Yüksek kimyasal dayanımlı yüksek performans" }
              ]
          },
          {
              title: "Kimyasal maddelere dayanıklı",
              rows: [
                  { name: "Polives 701", description: "Yüksek kimyasal dayanımlı performans" },
                  { name: "Polives 711", description: "Yüksek kimyasal dayanımlı yüksek performans" },
                  { name: "Polives 710", description: "CTP, alev ilerletmeyen" }
              ]
          },
          {
              title: "Alev ilerletmeyen",
              rows: [
                  { name: "Polives 710", description: "CTP, alev ilerletmeyen" }
              ]
          },
          {
              title: "Özel - Amin hızlandırıcı",
              rows: [
                  { name: "Polives 701-ABP", description: "Amin hızlandırıcılı yüksek kimyasal dayanımlı performans" },
                  { name: "Polives 711-ABP", description: "Amin hızlandırıcılı yüksek kimyasal dayanımlı yüksek performans" }
              ]
          }
          ]
        },
        {
          label: "Turkuaz",
          accordions: [
            {
              title: "Tam liste",
              rows: [
                  {
                      name: "TV101",
                      type: "Bisfenol-A Epoksi",
                      description: "Laminasyon, Kontak Kalıplama, Fitil Sarma, Enjeksiyon Kalıplama"
                  },
                  {
                      name: "TV121",
                      type: "Bisfenol-A Epoksi",
                      description: "Laminasyon, Kontak Kalıplama, Fitil Sarma, Enjeksiyon Kalıplama"
                  }
              ]
          }
          ]
        }
      ]
    }
  ];
  
  export default data;
  