// src/components/PatriotSt.jsx
import React from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import SliderCards from "../../components/SliderCards";

import img1 from "../../img/tpolia/patci/400-logo-patriot.jpg";
import img2 from "../../img/tpolia/patci/600-patriot-standart-01.jpg";

const StyledContainer = styled(Container)({
  backgroundColor: "#f9f9f9",
  padding: "2rem",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const animationVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const AnimatedComponent = ({ children }) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={animationVariants}
    >
      {children}
    </motion.div>
  );
};

const PatriotSt = () => {
  return (
    <div className="mt-3">
      <StyledContainer>
        <AnimatedComponent>
          <h1 className="text-2xl bg-red-500 p-4 rounded-xl mt-2 text-white font-bold mb-6 text-center">
            Patriot™ Standart çıkış sistemi
          </h1>
        </AnimatedComponent>
        <div className="flex flex-col gap-8 mb-8">
          <AnimatedComponent>
            <div className="flex flex-col md:flex-row gap-8">
              <img
                src={img2}
                alt="Placeholder 1"
                className="rounded-lg w-[25rem] shadow-lg md:w-1/2"
              />
              <TableContainer component={Paper} className="md:w-1/2">
              <img
                  src={img1}
                  alt="Placeholder 1"
                  className="rounded-lg w-[36rem] h-32 shadow-lg ml-0 md:ml-36 md:w-1/2"
                />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Avantajlar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                      Kusursuz Patriot™ teknolojisi
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                      Yüksek hassasiyet ve verim
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                      %1’den az reçine - katalizör değişikliği
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                      İyileştirilmiş ürün kalitesi
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                      Artırılmış etkinlik
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                      Daha düşük üretim maliyetleri
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                      Çok yönlü modüler parçalar
                      </TableCell>
                    </TableRow>
                    {/* Add more rows as needed */}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </AnimatedComponent>
          
          <AnimatedComponent>
            <Typography variant="body1" component="p" className="mb-8">
            Patriot™ standart çıkış sistemi, kompozit üretimine güç, çok yönlülük ve benzersiz hassasiyet katar.
           </Typography>
          </AnimatedComponent>
          <AnimatedComponent>
            <Typography variant="body1" component="p" className="mb-8">
            Patriot’un yüksek hassasiyetinin sırrı, eşsiz pompalama sistemidir.
            </Typography>
          </AnimatedComponent>
          <AnimatedComponent>
            <Typography variant="body1" component="p" className="mb-8">
            Bu hassaslık, kompozit imalatçılarının önemli sorunları arasında yer alan gözeneklilik, tutarsız kalınlık vb. kalite sorunlarının çoğunu çözer.
            </Typography>
          </AnimatedComponent>
          <AnimatedComponent>
            <Typography variant="body1" component="p" className="mb-8">
            Patriot™ standart çıkış sisteminin sıvı bölümü, aşınma karakteristiklerini yükseltmek için endüskiyon ısıyla sertleştirilmiş, ayrıca kalın krom tabakayla kaplanmıştır. Bu sayede, silindir üstündeki aşınma büyük ölçüde azalır ve sistem daha uzun süre çalışır.
            </Typography>
          </AnimatedComponent>
          <AnimatedComponent>
            <Typography variant="body1" component="p" className="mb-8">
            Patriot’un sıvı bölümü ve katalizör pompasının contaları en düşük sürtünme katsayısına sahip, kendini iyileştiren contalardır. Bu contalar ayar gerektirmez, ısı birikmesi ve aşınma problemlerini en aza indirir.
            </Typography>
          </AnimatedComponent>
          <AnimatedComponent>
            <Typography variant="body1" component="p" className="mb-8">
            Patriot™ her ihtiyacı optimum şekilde karşımamak için modüler olarak tasarlanmıştır. Ana parçalar kısa sürede, kolayca değişir, böylece imalat en verimli şekilde devam eder.
            </Typography>
          </AnimatedComponent>
          
          <AnimatedComponent>
            <div className="flex flex-col md:flex-row gap-8">
              <TableContainer component={Paper} className="md:w-1/2">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} sx={{ p: 0 }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "24px",
                            textAlign: "center",
                            borderBottom: "2px solid #000",
                            padding: "16px 0",
                            backgroundColor: "#f0f0f0",
                          }}
                          variant="h6"
                          id="tableTitle"
                          component="div"
                        >
                          Teknik Özellikler
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                      >
                        Özellik
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                      >
                        Açıklama
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>Çıkış kapasitesi</TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>
                      6,8 kg/dakika (Malzemelere ve uygulamaya bağlı olarak)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>Katalizör</TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>Hacim olarak %0,75 ’ten - %2,5’a kadar
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>Hava tüketimi (100 psi’de)</TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>530 l / dakika</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>Pompa oranı</TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>15 : 1 ; 1.500 psi
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>Azami sıvı çıkış basıncı</TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>
                      1.500 psi (76 bar, 7,6 MPa)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "500" }}>Azami hava giriş basıncı</TableCell>
                      <TableCell sx={{ fontWeight: "500" }}>100 psi (76 bar, 0,7 MPa)</TableCell>
                    </TableRow>
                    
                    
                    {/* Add more rows as needed */}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="flex flex-col md:w-1/2">
                <Typography variant="h4" component="h2" className="my-4">
                Test bilgileri
                </Typography>
                <Typography variant="body1" component="p" className="mt-8">
                MVP tarafından iki rakip sistem ile Patriot™ arasında yapılan karşılaştırma testleri, rakip sistemlerin % 25 kadar yüksek akış varyansları ve % 30 oranında yüksek reçine - katalizör varyans oranlarına sahip olduklarını göstermiştir. Patriot™ değişim oranı ise % 1’den az olarak gerçekleşmektedir.
                </Typography>
                <Typography variant="body1" component="p" className="mt-8">
                Yapılan testler, kompozit üretiminin en önemli unsuru olan tek düze laminat elde etme konusunda en hassas sistemin Patriot™ olduğunu göstermektedir.
                </Typography>
              </div>
            </div>
          </AnimatedComponent>
        </div>
      </StyledContainer>
      <div>
        <SliderCards />
      </div>
    </div>
  );
};

export default PatriotSt;
