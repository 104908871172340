import React from 'react';
import AnimatedSection from './AnimatedSection';

const HeaderTable = ({ heading, rows }) => {
  return (
    <AnimatedSection>
      <div className="bg-white shadow-md rounded p-4 mb-8">
        <h2 className="text-lg font-bold mb-4 text-start">{heading}</h2>
        <table className="w-full border-collapse">
          <tbody>
            {rows.map((row, index) => (
              <tr key={index} className="border-t text-sm border-gray-200">
                <td className="p-2 text-start">{row}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AnimatedSection>
  );
};

export default HeaderTable;
