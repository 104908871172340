  const data = [
    {
      sectionLabel: "Sınıflandırılmış pigment pasta listeleri",
      tabs: [
        {
          label: "Boytek",
          accordions: [
            {
              title: "Örtücü renkler",
              rows: [
                { name: "BRE 035", description: "Açık kahverengi" },
                { name: "BRE 037", description: "Koyu kahverengi" },
                { name: "BRE 040", description: "Helio yeşil" },
                { name: "BRE 052", description: "Açık sarı" },
                { name: "BRE 055", description: "Koyu sarı" },
                { name: "BRE 057", description: "Oksit sarı" },
                { name: "BRE 058", description: "Açık turuncu" },
                { name: "BRE 059", description: "Koyu turuncu" },
                { name: "BRE 060", description: "Siyah" },
                { name: "BRE 070", description: "Violet" },
                { name: "BRE 075", description: "Helio mavi" },
                { name: "BRE 082", description: "Kırmızı" },
                { name: "BRE 090", description: "Beyaz" }
              ]
            },
            {
              title: "Şeffaf renkler",
              rows: [
                { name: "BRE 030", description: "Kızıl kahverengi" },
                { name: "BRE 040", description: "Helio yeşil" },
                { name: "BRE 070", description: "Violet" },
                { name: "BRE 073", description: "Lacivert" },
                { name: "BRE 075", description: "Helio mavi" },
                { name: "BRE 081", description: "Açık sarı" },
                { name: "BRE 083", description: "Borda" },
                { name: "BRE 500", description: "Koyu sarı" },
                { name: "BRE 507", description: "Koyu sarı" },
                { name: "BRE 540", description: "Turuncu" },
                { name: "BRE 855", description: "Kırmızı" }
              ]
            }
            

            
          ]
        },
        {
          label: "Camelyaf-CE",
          accordions: [
            {
              title: "Örtücü ana renkler",
              rows: [
                { name: "CE Siyah sıvı pigment", description: "0,5 kg" },
                { name: "CE Beyaz sıvı pigment", description: "1 kg" }
              ]
            }
            
          ]
        },
        {
          label: "İlkester",
          accordions: [
            {
              title: "Opak pigment pastalar",
              rows: [
                { name: "ILK-100", description: "Oksit sarı" },
                { name: "ILK-103", description: "Krom sarı" },
                { name: "ILK-217", description: "Portakal rengi" },
                { name: "ILK-315", description: "Bayrak kırmızı" },
                { name: "ILK-500", description: "Helio mavi" },
                { name: "ILK-600", description: "Helio yeşil" },
                { name: "ILK-726", description: "Siyah" },
                { name: "ILK-800", description: "Koyu kahverengi" },
                { name: "ILK-801", description: "Açık kahverengi" },
                { name: "ILK-912", description: "Kar beyaz" },
                { name: "ILK-926", description: "Beyaz" }
              ]
            },
            {
              title: "Şeffaf pigment pastalar",
              rows: [
                { name: "ILK-1001", description: "" },
                { name: "ILK-1002", description: "" },
                { name: "ILK-1003", description: "" },
                { name: "ILK-2001", description: "" },
                { name: "ILK-2002", description: "" },
                { name: "ILK-2003", description: "" },
                { name: "ILK-3001", description: "" },
                { name: "ILK-3002", description: "" },
                { name: "ILK-3003", description: "" },
                { name: "ILK-3005", description: "" },
                { name: "ILK-3006", description: "" },
                { name: "ILK-3007", description: "" },
                { name: "ILK-3008", description: "" },
                { name: "ILK-3009", description: "" },
                { name: "ILK-3010", description: "" },
                { name: "ILK-4001", description: "" },
                { name: "ILK-4002", description: "" },
                { name: "ILK-4003", description: "" },
                { name: "ILK-5001", description: "" },
                { name: "ILK-5002", description: "" }
              ]
            },
            {
              title: "Yüksek performans pigment pastalar",
              rows: [
                { name: "ILK-509", description: "Ada mavisi" },
                { name: "ILK-510", description: "Ultramarin mavi" },
                { name: "ILK-530", description: "Gece mavisi" },
                { name: "ILK-532", description: "Signal mavi" },
                { name: "ILK-536", description: "Soluk mavi" },
                { name: "ILK-538", description: "Pastel mavi" },
                { name: "ILK-548", description: "Açık mavi" },
                { name: "ILK-552", description: "Kobalt mavi" },
                { name: "ILK-553", description: "Trafik mavi" },
                { name: "ILK-563", description: "Gök mavisi" },
                { name: "ILK-566", description: "Yeşil mavi" }
              ]
            },
            {
              title: "Spesifik pigment pastalar (SMC - BMC üretim)",
              rows: [
                { description: "", name: "Sarı, turuncu, kırmızı, mavi, yeşil, kahverengi, gri renklerdeki 28 adet özel SMC - BMC pigment pasta için lütfen bilgi alınız."},
              ]
            },
            {
              title: "Spesifik pigment pastalar (Yüzme havuzu üretimi)",
              rows: [
                { description: "", name: "Ftalat, solvent ve monomer içermeyen; ısı, UV ve kimyasallara yüksek dayanımı olan, en çok açok mavi ve beyaz renkleri tercih edilen spesifik pigment pastalar için lütfen bilgi alınız."},
              ]
            },
            {
              title: "Uluslararası katalog renkleri",
              rows: [
                { description: "", name: "Tüm uluslararası renk kataloglarından seçim yapabileceğiniz; seçtiğiniz renge %100 uyumlu üretilen; 8 - 10 yıl raf ömrü olan; ftalat, solvent ve monomer içermeyen pigment pastalar için lütfen bilgi alınız."},
              ]
            },
            
            
            
          ]
        },








        {
          label: "Poliya",
          accordions: [
              {
                title: "Örtücü ana renkler",
                rows: [
                  { name: "Polipigment 01", description: "Beyaz" },
                  { name: "Polipigment 05", description: "Beyaz" },
                  { name: "Polipigment 15", description: "Koyu sarı" },
                  { name: "Polipigment 17", description: "Oksit sarı" },
                  { name: "Polipigment 21", description: "Kiremit" },
                  { name: "Polipigment 23", description: "Açık kahverengi" },
                  { name: "Polipigment 27", description: "Koyu kahverengi" },
                  { name: "Polipigment 33", description: "Helio mavi" },
                  { name: "Polipigment 43", description: "Siyah" },
                  { name: "Polipigment 53", description: "Turuncu" },
                  { name: "Polipigment 63", description: "Helio kahverengi" },
                  { name: "Polipigment 72", description: "Violet" },
                  { name: "Polipigment 74", description: "Violet" },
                  { name: "Polipigment 83", description: "Açık sarı" },
                  { name: "Polipigment 87", description: "Koyu sarı" },
                  { name: "Polipigment 85", description: "Koyu sarı" },
                  { name: "Polipigment 91", description: "Kırmızı" },
                  { name: "Polipigment 93", description: "Turuncu" },
                  { name: "Polipigment 97", description: "Kırmızı" },
                  { name: "Polipigment 130", description: "Açık sarı" },
                  { name: "Polipigment 531", description: "Kırmızı" },
                  { name: "Polipigment 535", description: "Kırmızı" },
                  { name: "Polipigment 571", description: "Kırmızı" },
                  { name: "Polipigment 575", description: "Kırmızı" },
                  { name: "Polipigment 579", description: "Kırmızı" },
                  { name: "Polipigment 590", description: "Bordo" }
                ]
              },
              {
                title: "Hazır ara renkler",
                rows: [
                  { name: "Polipigment 933", description: "Beyaz - RAL 9010" },
                  { name: "Polipigment A-2147", description: "Beyaz - RAL 9016" },
                  { name: "Polipigment A-1030", description: "Krem - RAL 9001" },
                  { name: "Polipigment 664", description: "Pergamon" },
                  { name: "Polipigment A-1074", description: "Gri - RAL 7035" },
                  { name: "Polipigment 407", description: "Manhattan gri" },
                  { name: "Polipigment A-1129", description: "Gri - RAL 7040" },
                  { name: "Polipigment 656", description: "Duman gri" },
                  { name: "Polipigment A-1993", description: "Vanilya" },
                  { name: "Polipigment 635", description: "Serel krem" },
                  { name: "Polipigment 636", description: "Bej - RAL 1015" },
                  { name: "Polipigment 374", description: "Fildişi" },
                  { name: "Polipigment 326", description: "Şampanya" },
                  { name: "Polipigment 319", description: "Bej" },
                  { name: "Polipigment 331", description: "Karamel" },
                  { name: "Polipigment 359", description: "Sütlü kahverengi" },
                  { name: "Polipigment 353", description: "Uçuk pembe" },
                  { name: "Polipigment 303", description: "Roza pembe" },
                  { name: "Polipigment 396", description: "Uçuk mavi" },
                  { name: "Polipigment 712", description: "Turkuaz - RAL 6027" },
                  { name: "Polipigment 638", description: "Sarı - RAL 1018" },
                  { name: "Polipigment 740", description: "Turuncu - RAL 2003" },
                  { name: "Polipigment 961", description: "Yeşil - RAL 6018" },
                  { name: "Polipigment 312", description: "Çimen yeşili" },
                  { name: "Polipigment 933", description: "Beyaz - RAL 9010" },
                  { name: "Polipigment A-1050", description: "Kırmızı - RAL 3020" },
                  { name: "Polipigment A-2362", description: "Signal kırmızı - RAL 3001" },
                  { name: "Polipigment 657", description: "Parliament mavi" },
                  { name: "Polipigment 707", description: "Mavi - RAL 5005" },
                  { name: "Polipigment 676", description: "Poliya lacivert" },
                  { name: "Polipigment A-1212", description: "Lacivert - RAL 5004" }
                ]
              },
              {
                title: "Şeffaf renkler",
                rows: [
                  { name: "Polipigment 02", description: "Beyaz" },
                  { name: "Polipigment 22", description: "Kızıl kahverengi" },
                  { name: "Polipigment 24", description: "Koyu kahverengi" },
                  { name: "Polipigment 33", description: "Helio mavi" },
                  { name: "Polipigment 36", description: "Lacivert" },
                  { name: "Polipigment 63", description: "Helio yeşil" },
                  { name: "Polipigment 72", description: "Violet" },
                  { name: "Polipigment 74", description: "Violet" },
                  { name: "Polipigment 120", description: "Açık sarı" },
                  { name: "Polipigment 160", description: "Koyu sarı" },
                  { name: "Polipigment 560", description: "Kırmızı" },
                  { name: "Polipigment 566", description: "Kırmızı" },
                  { name: "Polipigment 570", description: "Kırmızı" }
                ]
              },
              {
                title: "Tam şeffaf düğme renkleri",
                rows: [
                  { name: "Polipigment 34", description: "Mavi" },
                  { name: "Polipigment 126", description: "Açık sarı" },
                  { name: "Polipigment 128", description: "Koyu sarı" },
                  { name: "Polipigment 510", description: "Pembe" },
                  { name: "Polipigment 520", description: "Pembe" },
                  { name: "Polipigment 562", description: "Kırmızı" },
                  { name: "Polipigment 564", description: "Kırmızı" }
                ]
              },
              {
                title: "Özel efekt renkleri",
                rows: [
                  { name: "Polipigment A-1356", description: "Metalik altın" },
                  { name: "Polipigment A-1380", description: "Metalik gümüş" },
                  { name: "Polipigment A-1407", description: "Sedef" }
                ]
              },
              {
                title: "Özel yapım renkler",
                rows: [
                  { name: "Polipigment özel pastel", description: "Krem, bej, açık mavi, gri gibi pastel renklerin RAL®, Pantone® ve tüm özel yapım tonlarını içeren seri" },
                  { name: "Polipigment özel PRO", description: "Kırmızı, sarı, yeşil, mavi, kahverengi, lacivert gibi canlı ve baskın renklerin RAL®, Pantone® ve tüm özel yapım tonlarını içeren seri" },
                  { name: "Polipigment özel pastel Eco", description: "Krem, bej, açık mavi, gri gibi pastel renklerin çevre dostu RAL®, Pantone® ve tüm özel yapım tonlarını içeren seri" },
                  { name: "Polipigment özel PRO Eco", description: "Kırmızı, sarı, yeşil, mavi, kahverengi, lacivert gibi canlı ve baskın renklerin çevre dostu RAL®, Pantone® ve tüm özel yapım tonlarını içeren seri" }
                ]
              }
              

              
              
          ]
        },









        {
          label: "Turkuaz",
          accordions: [
            {
              title: "Genel liste",
              rows: [
                { name: "TC05", description: "Beyaz", type: "1, 5, 20 kg" },
                { name: "TC06", description: "Beyaz (Eko)", type: "1, 5, 20 kg" },
                { name: "TC04", description: "Bej", type: "1, 5, 20 kg" },
                { name: "TC23", description: "S. Krem", type: "1, 5, 20 kg" },
                { name: "TC24", description: "N. Krem", type: "1, 5, 20 kg" },
                { name: "TC01", description: "Açık Bej", type: "1, 5, 20 kg" },
                { name: "TC09", description: "Fildişi", type: "1, 5, 20 kg" },
                { name: "TC34", description: "Şeffaf Açık Sarı", type: "1, 5, 20 kg" },
                { name: "TC03", description: "Açık Sarı", type: "1, 5, 20 kg" },
                { name: "TC15", description: "Koyu Sarı", type: "1, 5, 20 kg" },
                { name: "TC35", description: "Şeffaf Koyu Sarı", type: "1, 5, 20 kg" },
                { name: "TC16", description: "Oksit Sarı", type: "1, 5, 20 kg" },
                { name: "TC20", description: "Turuncu", type: "1, 5, 20 kg" },
                { name: "TC08", description: "Fıstık Yeşili", type: "1, 5, 20 kg" },
                { name: "TC26", description: "Nil Yeşili", type: "1, 5, 20 kg" },
                { name: "TC07", description: "Çimen Yeşili", type: "1, 5, 20 kg" },
                { name: "TC12", description: "Helio Yeşil", type: "1, 5, 20 kg" },
                { name: "TC25", description: "G. Mavi", type: "1, 5, 20 kg" },
                { name: "TC11", description: "Helio Mavi", type: "1, 5, 20 kg" },
                { name: "TC28", description: "Lacivert", type: "1, 5, 20 kg" },
                { name: "TC19", description: "Siyah", type: "1, 5, 20 kg" },
                { name: "TC18", description: "Roza Pembe", type: "1, 5, 20 kg" },
                { name: "TC17", description: "Pembe", type: "1, 5, 20 kg" },
                { name: "TC21", description: "Viyolet", type: "1, 5, 20 kg" },
                { name: "TC22", description: "Viyolet", type: "1, 5, 20 kg" },
                { name: "TC37", description: "Kırmızı 70", type: "1, 5, 20 kg" },
                { name: "TC30", description: "Bayrak Kırmızı/td>", type: "1, 5, 20 kg" },
                { name: "TC13", description: "Kırmızı", type: "1, 5, 20 kg" },
                { name: "TC36", description: "Kırmızı 75", type: "1, 5, 20 kg" },
                { name: "TC27", description: "Kiremit", type: "1, 5, 20 kg" },
                { name: "TC29", description: "Oksit Kırmızı", type: "1, 5, 20 kg" },
                { name: "TC33", description: "Koyu Kırmızı", type: "1, 5, 20 kg" },
                { name: "TC31", description: "Bordo", type: "1, 5, 20 kg" },
                { name: "TC10", description: "M. Gri", type: "1, 5, 20 kg" },
                { name: "TC32", description: "Şeffaf Kahve", type: "1, 5, 20 kg" },
                { name: "TC02", description: "Açık Kahve", type: "1, 5, 20 kg" },
                { name: "TC14", description: "Koyu Kahve", type: "1, 5, 20 kg" }
              ]
            }
            
          ]
        }
      ]
    },







  
  ];

  export default data;
