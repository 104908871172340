import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Submit = () => {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    address: '',
    phone: '',
    email: '',
    website: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    console.log(formData);
  };

  return (
    <motion.form
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      onSubmit={handleSubmit}
      className="max-w-md mx-auto mt-8 mb-8 p-6 bg-white rounded-lg shadow-xl"
    >
      <h2 className="text-2xl font-bold mb-4">İletişim Bilgileriniz</h2>
      <div className="grid grid-cols-1 gap-4">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Adınız, Soyadınız (Gerekli)"
          required
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <input
          type="text"
          name="surname"
          value={formData.surname}
          onChange={handleChange}
          placeholder="Soyadınız"
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          placeholder="Adresiniz"
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Telefon Numaranız"
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email Adresiniz (Gerekli)"
          required
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <input
          type="text"
          name="website"
          value={formData.website}
          onChange={handleChange}
          placeholder="Websiteniz"
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Mesajınız (Gerekli)"
          required
          rows="4"
          className="w-full p-2 border border-gray-300 rounded-md"
        ></textarea>
      </div>
      <button
        type="submit"
        className="mt-4 w-full md:w-60 bg-red-500 hover:bg-blue-600 text-white py-2 rounded-md transition duration-300"
      >
        Gönder
      </button>
    </motion.form>
  );
};

export default Submit;
