import React from "react";
import HeaderTable from "../../components/HeaderTable";
import ImageWithHeading from "../../components/ImageWithHeading";
import MultiColumnTable from "../../components/MultiColumnTable";
import img from "../../img/tech/lantor/xm/640-lantor-coremat-xi.jpg";

const Xi = () => {
  const firstColRows = [
    "Açık kalıp uygulamaları için uygun maliyetli malzeme",
    "Kor nüve veya bariyer malzemesi olarak kullanılabilme",
    "Polyester dokumasız yapısıyla polyester, vinilester, fenolik ve epoksi gibi tüm standart reçinelere uyumluluk",
    "El yatırması ve püskürtme uygulamaya uygunluk",
    "Reçine uyarıcısıyla etkin kontrol",
    "Çevre dostu mikro kürecik teknolojisi",
    "mm başına 600 g/m2 reçine emme kapasitesi",
    "Karmaşık geometrik yapıdaki üretimler için üstün drapelendirilebilme",
  ];
  const secondColRows = [
    "Tekne ve yatların gövde ve güverteleri",
    "Otomobil, kamyon, treyler parça ve panelleri",
    "Otobüs, tren ve hafif raylı sistemlerin iç ve dış gövde ve panelleri",
    "Kano ve sörf tahtaları",
    "Küvet ve havuzlar",
  ];

  const firstMultiColRows = [
    ["Özellik", "Xi 1", "Xi 2", "Xi 3", "Xi 4", "Xi 5"],
    ["Kalınlık (mm)", "1,4", "2,0", "3,0", "4,0", "5,0"],
    ["Rulo uzunluğu (m)", "130", "80", "50", "50", "30"],
    ["Rulo genişliği (m)", "1", "1", "1", "1", "1"],
    ["Reçine emme kapasitesi (kg/m2)", "0,8", "1,2", "1,8", "2,4", "3,0"],
    ["Kuru ağırlık (g/m2)", "45", "55", "80", "105", "125"],
    ["Emprenye edilmiş dansite (kg/m3)", "630", "630", "630", "630", "630"],
  ];
  const secMultiColRows = [
    ["Mekanik özellik", "Birim", "Değer", "Test metodu"],
    ["Eğilme dayanımı", "MPa", "11", "ASTM D790"],
    ["Eğilme modülü", "MPa", "1.100", "ASTM D790"],
    ["Katmanlar arası gerilme dayanımı", "MPa", "4", "ASTM C297"],
    ["Basınç dayanımı (%10 stren)", "MPa", "10", "ISO 844"],
    ["Kopma mukavemeti", "MPa", "5", "ASTM C273-61"],
    ["Kopma modülü", "MPa", "35", "ASTM C273-61"],
  ];

  return (
    <div className="container mx-auto p-4">
      <h1 className="bg-red-500 text-white mb-3 h-auto px-6 py-2 rounded-2xl text-2xl md:text-3xl text-center flex items-center justify-center md:px-10 lg:px-20 xl:px-32">
        Lantor Coremat ® Xİ
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="md:order-2 mt-24">
          <HeaderTable heading="Avantajlar" rows={firstColRows} />
        </div>
        <div className="md:order-1">
          <ImageWithHeading
            heading="Uygun maliyet, yüksek performans"
            src={img}
            alt="Sample"
          />
        </div>
        <div className="md:order-4 ">
          <HeaderTable heading="Uygulamalar" rows={secondColRows} />
        </div>
        <div className="md:order-3">
          <MultiColumnTable
            heading="Boyut Özellikleri"
            rows={firstMultiColRows}
            description="2.500 m2 üzeri siparişlerde isteğe özel genişlikte üretim"
          />
        </div>
        <div className="md:order-5">
          <MultiColumnTable
            heading="Doymamış polyester reçine ile emprenye edilmiş Lantor Coremat ® XM’in (XM3) tipik mekanik özellikleri"
            rows={secMultiColRows}
          />
        </div>
        <div className="md:order-3 p-4 md:p-8">
          <h1 className="text-lg md:text-xl font-semibold mb-2">
            Saklama bilgileri
          </h1>
          <p className="text-sm md:text-base">
            Lantor Coremat ® Xi, direkt gün ışığından korunmalı ve kendi
            orijinal ambalajında saklanmalıdır. Böylece reçine indikatörünün
            güvenilir olarak çalışması sağlanabilir.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Xi;
