import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import img1 from "../../img/tpolia/blok/600-ultramax-blok-01.jpg";
import img2 from "../../img/tpolia/blok/600-ultramax-blok-uygulama-01.jpg";
import img3 from "../../img/tpolia/blok/600-ultramax-blok-pompa-01.jpg";

const Blok = () => {
  const { ref: image1Ref, inView: image1InView } = useInView({
    triggerOnce: true,
  });
  const { ref: image2Ref, inView: image2InView } = useInView({
    triggerOnce: true,
  });
  const { ref: tableRef, inView: tableInView } = useInView({
    triggerOnce: true,
  });
  const { ref: paragraphRef, inView: paragraphInView } = useInView({
    triggerOnce: true,
  });

  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="p-8 max-w-5xl mx-auto">
      <h1 className="text-3xl md:text-4xl bg-red-500 p-4 rounded-xl mt-2 text-white font-bold mb-6 text-center shadow-lg">
        UltraMAX™
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <motion.div
          ref={image1Ref}
          initial="hidden"
          animate={image1InView ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6 }}
          className="flex justify-center"
        >
          <div className="w-full">
            <h2 className="text-xl md:text-2xl font-bold text-center mb-4">
              Blok güçlendirmede yenilikçi çözümler
            </h2>
            <img
              src={img1}
              alt="img1"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </motion.div>

        <motion.div
          ref={image2Ref}
          initial="hidden"
          animate={image2InView ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6 }}
          className="flex justify-center"
        >
          <div className="w-full">
            <h2 className="text-xl md:text-2xl font-bold text-center mb-4">
              Daha yüksek üretim, daha düşük maliyet
            </h2>
            <img
              src={img2}
              alt="img2"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </motion.div>
      </div>

      <motion.div
        ref={tableRef}
        initial="hidden"
        animate={tableInView ? "visible" : "hidden"}
        variants={fadeInUp}
        transition={{ duration: 0.6 }}
        className="mb-8 flex flex-col md:flex-row"
      >
        <div className="w-full md:w-1/2 md:pr-8">
          <p className="text-base mb-4">
            UltraMAX™ kırpma ve püskürtme sistemi katrak kesiminden önce mermer
            blokların polyester ve cam elyafı takviyesiyle sarılıp
            sağlamlaştırılmasını güçlendirilmesini sağlar.
          </p>
          <p className="text-base mb-4">
            Böylece kesim sırasında bloklar dağılmaz, kırılmaz, kesim kayıpları
            azalır.
          </p>
          <p className="text-base mb-4">
            UltraMAX™ sistemiyle bir bloktan daha fazla sağlam plaka üretilir ve
            blok kesim verimi artar.
          </p>
          <p className="text-base mb-4">
            UltraMAX™ pompası çok amaçlı bir sistemdir. Kendi sınıfında en düşük
            basınçla en yüksek reçine transfer kapasitesini sağlar. Kırpmanın
            yanısıra farklı üretim teknikleri (Jelkot püskürtme, RTM uygulaması
            vb.) teknikleri ile de kullanılabilir. Düşük basınçla çalıştığı için
            daha uzun ömürlüdür.
          </p>
        </div>
        <div className="w-full md:w-1/2 mt-4 md:mt-0">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Avantajlar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Blok kesim verimini artırır</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Kesim kayıplarını azaltır</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Bir bloktan daha fazla sağlam plaka üretilir
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Değişik taş türlerine uygulanabilir</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Kürlenme süresi kısadır</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Zaman, işçilik ve enerji tasarrufu sağlar
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Üretim maliyetlerini düşürür</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Sistemin parçaları modüler ve çok yönlüdür
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </motion.div>

      <motion.div
        ref={tableRef}
        initial="hidden"
        animate={tableInView ? "visible" : "hidden"}
        variants={fadeInUp}
        transition={{ duration: 0.6 }}
        className="mb-8 flex flex-col md:flex-row"
      >
        <div className="w-full md:w-1/2 mt-4 md:mt-0">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Özellikler</TableCell>
                  <TableCell>Açıklama</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Çıkış kapasitesi</TableCell>
                  <TableCell>
                    3,63 - 4,54 kg/dakika (Reçine özellikleri ve ortam
                    sıcaklığına göre değişir)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>MEK-P kapasitesi</TableCell>
                  <TableCell>
                    Hacim olarak %0,75 - %3 (Çalışma ve ortam sıcaklığına göre)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hava tüketimi</TableCell>
                  <TableCell>510 l / dakika</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Pompa oranları</TableCell>
                  <TableCell> 6 : 1 (Standart) 11 : 1 (Opsiyonel)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hortum uzunluğu</TableCell>
                  <TableCell>9 m</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Opsiyonlar</TableCell>
                  <TableCell>
                    VRC - 100 Kırpıcı sistemi 50090 - 1 RTM sistemi (hortumlu
                    transfer) 52300 - 1 RTM sistemi (sabit transfer) VPA - 1000
                    Jelkot sistemi
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

        </div>
        <div className="w-full md:w-1/2 mx-3 md:px-8">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Özellikler</TableCell>
                  <TableCell>Yararlar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Düşük basınç</TableCell>
                  <TableCell>
                    {" "}
                    UltraMAX™ pompa sistemiyle MVP FIT kırpıcı ünitesi,
                    piyasadaki en düşük basınçlı elyaf kırpıcılı polyester
                    püskürtme makinasıdır (15 - 30 psi).
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Düşük emisyon</TableCell>
                  <TableCell>
                    {" "}
                    Sistem, en gelişmiş emisyon teknolojisi olan FIT (Fluid
                    Impingment Technology) ile en düşük emisyon oranına
                    sahiptir.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ürün kapasitesinde süreklilik</TableCell>
                  <TableCell>
                    FIT Chopper ünitesi, düşük basınçla hiç sıçratmadan daha
                    güçlü ve sürekli laminasyon sağlar.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Düzgün püskürtme</TableCell>
                  <TableCell>
                    FIT teknolojisi, gelişmiş yapısı sayesinde, püskürtme
                    esnasında oluşan tozlaşmaları ortadan kaldırarak sürekli
                    standart püskürtme sağlar.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </motion.div>
      <img
              src={img3}
              alt="img3"
              className="w-72 h-96 rounded-lg shadow-lg"
            />
      
    </div>
  );
};

export default Blok;
