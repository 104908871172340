const data = [
  {
    sectionLabel:
      "Granit ve metal efektleri, mikronize dolgu ve toz pigment çeşitleri",
    tabs: [
      {
        label: "Poliya",
        accordions: [
          {
            title: "Granit efektleri (metalik dahil) ",
            rows: [
              {
                name: "Poliflake Karışım Renkler",
                description:
                  "Milano, Patagonia, Roma, Petra, Sakura, Alaska, Dalmatia, Goldstone, Stargalaxy, African Red, Lava, Spring",
              },
              {
                name: "Poliflake Düz Renkler",
                description:
                  "Antartica, Grey, Carbona, Cream, Caramel, Terra, Pastel Rosa, Grena, Aqua, Lilac, Marin, Pink, Carmen, Canary",
              },
              {
                name: "Poliflake Özel Efekt Renkler",
                description: "Gold (altın), Silver (gümüş)",
              },
              {
                name: "Poligranul Düz Renkler I (24-120 mesh)",
                description:
                  "Siyah, Gri, Beyaz, Kırık Beyaz, Krem, Sarı, Açık Kahve, Koyu Kahve, Kırmızı, Açık Yeşil, Koyu Yeşil, Mavi, Lacivert",
              },
              {
                name: "Poligranul Düz Renkler K (16-120 mesh)",
                description:
                  "Siyah, Gri, Beyaz, Kırık Beyaz, Krem, Sarı, Açık Kahve, Koyu Kahve, Kırmızı, Açık Yeşil, Koyu Yeşil, Mavi, Lacivert",
              },
              {
                name: "Poligranul Kalın Renkler KSK/Kalın Seri Kalın (4-8 mesh)",
                description:
                  "Siyah, Beyaz, Kırık Beyaz, Krem, Sarı, Açık Kahve, Koyu Kahve, Kırmızı, Lacivert",
              },
              {
                name: "Poligranul Natura Karışım Renkler",
                description:
                  "PN-104, PN-118, PN-125- PN-139, PN-146, PN-160, PN-167, PN-181, PN-188, PN-202, PN-303, PN-305, PN-307, PN-309, PN-311, PN-313, PN-323, PN-325, PN-327, PN-329, PN-331",
              },
            ],
          },
          {
            title: "Metalize ve sedef pigment pastalar",
            rows: [
              {
                name: "Polipigment A-1380",
                description: "Metalik gümüş",
              },
              {
                name: "Polipigment A-1356",
                description: "Metalik altın",
              },
              {
                name: "Polipigment A-1407",
                description: "Sedef",
              },
            ],
          },
        ],
      },
      {
        label: "Diğer",
        accordions: [
          {
            title: "Tiksotropik katkılar",
            rows: [
              {
                name: "Wacker HDK N20",
                description: "Tiksotropi sağlayıcı işlenmiş silika",
              },
              {
                name: "Cabosil - Aerosil",
                description: "Tiksotropi sağlayıcı işlenmiş silika",
              },
            ],
          },
          {
            title: "Toz pigmentler",
            rows: [
              {
                name: "Titan R-902 Plus",
                description: "Beyaz titanyum dioksit",
              },
              {
                name: "Carbon Black",
                description: "Karbon siyah",
              },
            ],
          },
          {
            title: "Mikronize dolgular",
            rows: [
              {
                name: "Kalsit",
                description: "Kalsit - 5 mikron",
              },
              {
                name: "Dolomit",
                description: "Dolomit - 5 mikron",
              },
              {
                name: "Talk Powder",
                description: "Talk - 5 mikron",
              },
              {
                name: "Talk Powder Extra",
                description: "Talk - 5 mikron",
              },
              {
                name: "AL-100",
                description: "Alüminyum tozu - 100 mikron",
              },
              {
                name: "AL-200",
                description: "Alüminyum tozu - 200 mikron",
              },
              {
                name: "AL-300",
                description: "Alüminyum tozu - 300 mikron",
              },
              {
                name: "Alolt 2AF",
                description: "ATH alüminyum trihidroksit - 15-25 mikron",
              },
              {
                name: "Apyral 16",
                description: "ATH alüminyum trihidroksit - 10-12 mikron",
              },
              {
                name: "Coathylene HA 1682",
                description:
                  "Sıcak kalıplama sistemleri için LD polietilen toz",
              },
              {
                name: "Çinko Stearat",
                description: "Sıcak kalıplama sistemleri için iç kalıp ayırıcı",
              },
              {
                name: "Luvatol",
                description:
                  "Sıcak kalıplama sistemleri için MgO kalınlaştırıcı",
              },
              {
                name: "Expancel 551",
                description: "Plastik hafifletici dolgu",
              },
              {
                name: "Microsphere",
                description: "Cam hafifletici dolgu",
              },
            ],
          },
        ],
      },
    ],
  },
];

export default data;
