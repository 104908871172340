const data = [
    {
      sectionLabel: "Sınıflandırılmış Jelkod Seçenekleri ",
      tabs: [
        {
          label: "Boytek",
          accordions: [
            {
                title: "Genel amaçlı jelkotlar",
                rows: [
                  {
                    name: "BRE 333 F/P",
                    type: "Ortoftalik",
                    description: "Genel amaçlı endüstriyel"
                  },
                  {
                    name: "BRE 333 F/P Beyaz",
                    type: "Ortoftalik",
                    description: "Genel amaçlı, endüstriyel, beyaz"
                  },
                  {
                    name: "BRE 334 F/P",
                    type: "Ortoftalik",
                    description: "Dış şartlara dayanımı arttırılmış, genel amaçlı endüstriyel"
                  },
                  {
                    name: "BRE 334 F/P Beyaz",
                    type: "Ortoftalik",
                    description: "Dış şartlara dayanımı arttırılmış, genel amaçlı endüstriyel, beyaz"
                  }
                ]
              },
              {
                title: "Yüksek dayanımlı jelkotlar",
                rows: [
                  {
                    name: "BRE 315 F/P",
                    type: "İzoftalik/Özel",
                    description: "Yüksek HDT, mükemmel hidrolitik, dış şart ve kimyasal dayanımı"
                  },
                  {
                    name: "BRE 335 F/P",
                    type: "İzoftalik/NPG",
                    description: "Mükemmel hidrolitik ve dış şart dayanımı"
                  },
                  {
                    name: "BRE 335 F/P Beyaz",
                    type: "İzoftalik/NPG",
                    description: "Mükemmel hidrolitik ve dış şart dayanımı, beyaz"
                  },
                  {
                    name: "BRE 336 F/P",
                    type: "İzoftalik/NPG",
                    description: "Akrilik modifiyeli, mükemmel şeffaflık, üstün dayanım"
                  },
                  {
                    name: "BRE 328 F",
                    type: "",
                    description: "Alev ilerletmeyen şeffaf"
                  },
                  {
                    name: "BRE 328 FD",
                    type: "",
                    description: "Alev ilerletmeyen dolgulu"
                  }
                ]
              },
              {
                title: "Parlak jelkotlar",
                rows: [
                  {
                    name: "BRE 125 P",
                    type: "",
                    description: "Parlak son kat"
                  }
                ]
              },
              {
                title: "Yüksek performans tipi jelkotlar",
                rows: [
                  {
                    name: "Yeni - HYDRATEK EZ",
                    type: "İzoftalik/NPG",
                    description: "Marin"
                  },
                  {
                    name: "Yeni - HYDRATEK PLUS",
                    type: "İzoftalik/NPG",
                    description: "Yüksek performanslı"
                  },
                  {
                    name: "PROMAX 9211",
                    type: "İzoftalik/NPG",
                    description: "Yüksek performanslı"
                  },
                  {
                    name: "PROMAX 3541",
                    type: "İzoftalik",
                    description: "Yüksek performanslı"
                  },
                  {
                    name: "Yeni - RAPITEK",
                    type: "Ortoftalik",
                    description: "Endüstriyel"
                  }
                ]
              },
              {
                title: "Zımparalanabilir jelkotlar",
                rows: [
                  {
                    name: "BRE 337 F/P",
                    type: "Ortoftalik",
                    description: "Zımparalanabilir, gri renk, pinholsüz uygulama"
                  },
                  {
                    name: "Yeni - BRE 338 F/P",
                    type: "Ortoftalik",
                    description: "Zımparalanabilir, gri, esnek, çatlama yapmaz"
                  },
                  {
                    name: "Yeni - BRE 920 F",
                    type: "Özel",
                    description: "Dış şartlarda bekleyen kalıpları koruma amaçlı"
                  }
                ]
              },
          ]
        },
        {
          label: "Camelyaf-CE",
          accordions: [
            {
                title: "Standart uygulama için genel amaçlı jelkotlar",
                rows: [
                  {
                    name: "CE Optima SF",
                    type: "Ortoftalik",
                    description: "Genel amaçlı"
                  },
                  {
                    name: "CE Optima SP",
                    type: "Ortoftalik",
                    description: "Genel amaçlı"
                  }
                ]
              },
              {
                title: "Beyaz renkli jelkotlar",
                rows: [
                  {
                    name: "CE Optima BF",
                    type: "Ortoftalik",
                    description: "Genel amaçlı, beyaz"
                  },
                  {
                    name: "CE Optima BP",
                    type: "Ortoftalik",
                    description: "Genel amaçlı, beyaz"
                  }
                ]
              }
              
              




            
          ]
        },
        {
          label: "İlkester",
          accordions: [
            {
                title: "Genel amaçlı jelkotlar",
                rows: [
                  {
                    name: "G-7",
                    type: "Ortoftalik jelkot",
                    description: "Genel elyaf püstürtme uygulamaları"
                  },
                  {
                    name: "G-307",
                    type: "Ortoftalik jelkot",
                    description: "Genel fırça uygulamaları"
                  }
                ]
              },
              {
                title: "Performans tipi jelkotlar",
                rows: [
                  {
                    name: "GN-417",
                    type: "Ortoftalik / NPG akrilik modifiye jelkot",
                    description: "Elyaf püstürtme uygulamaları"
                  },
                  {
                    name: "GN-317",
                    type: "Ortoftalik / NPG akrilik modifiye jelkot",
                    description: "Genel fırça uygulamaları"
                  }
                ]
              },
              {
                title: "Yüksek performans tipi jelkotlar",
                rows: [
                  {
                    name: "GİN-4013",
                    type: "İzoftalik / NPG akrilik modifiye jelkot",
                    description: "Elyaf püstürtme uygulamaları"
                  },
                  {
                    name: "GİN-3013",
                    type: "İzoftalik / NPG akrilik modifiye jelkot",
                    description: "Fırça uygulamaları"
                  },
                  {
                    name: "GİN-413",
                    type: "İzoftalik / NPG akrilik modifiye jelkot",
                    description: "Elyaf püskürtme uygulamaları"
                  },
                  {
                    name: "GİN-313",
                    type: "İzoftalik / NPG akrilik modifiye jelkot",
                    description: "Fırça uygulamaları"
                  }
                ]
              },
              {
                title: "Kimyasallara dirençli jelkotlar",
                rows: [
                  {
                    name: "GVE-5",
                    type: "Epoksi vinilester jelkot",
                    description: "Elyaf püstürtme uygulamaları"
                  },
                  {
                    name: "GVE-305",
                    type: "Epoksi vinilester jelkot",
                    description: "Fırça uygulamaları"
                  },
                  {
                    name: "GIN-3013",
                    type: "İzoftalik / NPG akrilik modifiye jelkot",
                    description: "Fırça uygulamaları"
                  },
                  {
                    name: "GIN-4013",
                    type: "İzoftalik / NPG akrilik modifiye jelkot",
                    description: "Elyaf püskürtme uygulamaları"
                  },
                  {
                    name: "GIN-313",
                    type: "İzoftalik / NPG akrilik modifiye jelkot",
                    description: "Fırça uygulamaları"
                  },
                  {
                    name: "GIN-413",
                    type: "İzoftalik / NPG akrilik modifiye jelkot",
                    description: "Elyaf püskürtme uygulamaları"
                  }
                ]
              },
              {
                title: "Zımparalanabilir son kat jelkotlar",
                rows: [
                  {
                    name: "G-18 Renkli",
                    type: "Ortoftalik zımparalanabilir sonkat jelkot",
                    description: "Püstürtme uygulamaları"
                  },
                  {
                    name: "G-318 Renkli",
                    type: "Ortoftalik zımparalanabilir sonkat jelkot",
                    description: "Fırça uygulamaları"
                  }
                ]
              },
              {
                title: "Alev geciktirici jelkotlar",
                rows: [
                  {
                    name: "GFR-4 Renkli",
                    type: "Özel reçine bazlı halojensiz alev geciktirici jelkot",
                    description: "Püstürtme uygulamaları"
                  },
                  {
                    name: "GFR-40 Renkli",
                    type: "Özel reçine bazlı halojensiz alev geciktirici jelkot",
                    description: "Fırça uygulamaları"
                  },
                  {
                    name: "GFR-445 Renkli",
                    type: "Özel reçine bazlı halojensiz alev geciktirici jelkot",
                    description: "Püstürtme uygulamaları"
                  },
                  {
                    name: "GFR-345 Renkli",
                    type: "Özel reçine bazlı halojensiz alev geciktirici jelkot",
                    description: "Fırça uygulamaları"
                  }
                ]
              },
              {
                title: "Bariyerkotlar",
                rows: [
                  {
                    name: "GVE-5",
                    type: "Epoksi vinilester bariyerkot",
                    description: "Püstürtme uygulamaları"
                  },
                  {
                    name: "GVE-305",
                    type: "Epoksi vinilester bariyerkot",
                    description: "Fırça uygulamaları"
                  },
                  {
                    name: "GVE-344",
                    type: "Epoksi vinilester bariyerkot",
                    description: "Fırça uygulamaları"
                  }
                ]
              }
          ]
        },








        {
          label: "Poliya",
          accordions: [
            {
                title: "Topkotlar",
                rows: [
                  {
                    name: "Polijel Topkot 208",
                    type: "Ortoftalik",
                    description: "Genel amaçlı"
                  },
                  {
                    name: "Polijel Topkot 210",
                    type: "İzoftalik NPG",
                    description: "Performans"
                  },
                  {
                    name: "Polijel Topkot 215",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans"
                  },
                  {
                    name: "Polijel 240",
                    type: "Allilik",
                    description: "Parlak"
                  }
                ]
              },
              {
                title: "Zımparalanabilir astar",
                rows: [
                  {
                    name: "Polijel 209-Z",
                    type: "Ortoftalik dolgu",
                    description: "Zımparalanabilir"
                  },
                  {
                    name: "Polijel P-2089-Z",
                    type: "Ortoftalik dolgu",
                    description: "Yüksek ısıl dayanımlı zımparalanabilir"
                  }
                ]
              },
              {
                title: "Standart uygulama için genel amaçlı",
                rows: [
                  {
                    name: "Polijel 206",
                    type: "Ortoftalik",
                    description: "Genel amaçlı"
                  },
                  {
                    name: "Polijel 208",
                    type: "Ortoftalik",
                    description: "Genel amaçlı"
                  }
                ]
              },
              {
                title: "Performans",
                rows: [
                  {
                    name: "Polijel 211",
                    type: "Ortoftalik NPG",
                    description: "Performans"
                  },
                  {
                    name: "Polijel 212",
                    type: "Ortoftalik NPG Akrilik",
                    description: "Performans"
                  },
                  {
                    name: "Polijel 210",
                    type: "İzoftalik NPG",
                    description: "Performans"
                  }
                ]
              },
              {
                title: "Yüksek performans",
                rows: [
                  {
                    name: "Polijel 213",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans"
                  },
                  {
                    name: "Polijel 214",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans ısıl dayanımlı"
                  },
                  {
                    name: "Polijel 215",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans marin"
                  }
                ]
              },
              {
                title: "Beyaz renkli",
                rows: [
                  {
                    name: "Polijel 206 Beyaz",
                    type: "Ortoftalik",
                    description: "Genel amaçlı, beyaz"
                  },
                  {
                    name: "Polijel 208 Beyaz",
                    type: "Ortoftalik",
                    description: "Genel amaçlı, beyaz"
                  },
                  {
                    name: "Polijel 211 Beyaz",
                    type: "Ortoftalik NPG",
                    description: "Performans, beyaz"
                  },
                  {
                    name: "Polijel 212 Beyaz",
                    type: "Ortoftalik NPG Akrilik",
                    description: "Performans, beyaz"
                  },
                  {
                    name: "Polijel 210 Beyaz",
                    type: "İzoftalik NPG",
                    description: "Performans, beyaz"
                  },
                  {
                    name: "Polijel 213 Beyaz",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans, beyaz"
                  },
                  {
                    name: "Polijel 215 Beyaz",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans, beyaz, marin"
                  }
                ]
              },
              {
                title: "Pastel renk serisi",
                rows: [
                  {
                    name: "Polijel 206 pastel",
                    type: "Ortoftalik",
                    description: "Genel amaçlı pastel renk"
                  },
                  {
                    name: "Polijel 208 pastel",
                    type: "Ortoftalik",
                    description: "Genel amaçlı pastel renk"
                  },
                  {
                    name: "Polijel 211 pastel",
                    type: "Ortoftalik NPG",
                    description: "Performans pastel renk"
                  },
                  {
                    name: "Polijel 212 pastel",
                    type: "Ortoftalik NPG Akrilik",
                    description: "Performans pastel renk"
                  },
                  {
                    name: "Polijel 210 pastel",
                    type: "İzoftalik NPG",
                    description: "Performans pastel renk"
                  },
                  {
                    name: "Polijel 213 pastel",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans pastel renk"
                  },
                  {
                    name: "Polijel 214 pastel",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans pastel renk"
                  },
                  {
                    name: "Polijel 215 pastel",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans pastel renk, marin"
                  }
                ]
              },
              {
                title: "Pastel renk - Eco",
                rows: [
                  {
                    name: "Polijel 206 pastel - eco",
                    type: "Ortoftalik",
                    description: "Genel amaçlı pastel"
                  },
                  {
                    name: "Polijel 208 pastel - eco",
                    type: "Ortoftalik",
                    description: "Genel amaçlı pastel"
                  },
                  {
                    name: "Polijel 211 pastel - eco",
                    type: "Ortoftalik NPG",
                    description: "Performans pastel"
                  },
                  {
                    name: "Polijel 212 pastel - eco",
                    type: "Ortoftalik NPG Akrilik",
                    description: "Performans pastel"
                  },
                  {
                    name: "Polijel 210 pastel - eco",
                    type: "İzoftalik NPG",
                    description: "Performans pastel"
                  },
                  {
                    name: "Polijel 213 pastel - eco",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans pastel"
                  },
                  {
                    name: "Polijel 214 pastel - eco",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans pastel"
                  },
                  {
                    name: "Polijel 215 pastel - eco",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans pastel, marin"
                  }
                ]
              },
              {
                title: "Pro renk serisi",
                rows: [
                  {
                    name: "Polijel 206 pastel - pro",
                    type: "Ortoftalik",
                    description: "Genel amaçlı pastel"
                  },
                  {
                    name: "Polijel 208 pastel - pro",
                    type: "Ortoftalik",
                    description: "Genel amaçlı pastel"
                  },
                  {
                    name: "Polijel 211 pastel - pro",
                    type: "Ortoftalik NPG",
                    description: "Performans pastel"
                  },
                  {
                    name: "Polijel 212 pastel - pro",
                    type: "Ortoftalik NPG Akrilik",
                    description: "Performans pastel"
                  },
                  {
                    name: "Polijel 210 pastel - pro",
                    type: "İzoftalik NPG",
                    description: "Performans pastel"
                  },
                  {
                    name: "Polijel 213 pastel - pro",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans pastel"
                  },
                  {
                    name: "Polijel 214 pastel - pro",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans pastel"
                  },
                  {
                    name: "Polijel 215 pastel - pro",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans pastel, marin"
                  }
                ]
              },
              {
                title: "Pro renk - Eco",
                rows: [
                  {
                    name: "Polijel 206 pastel - pro - eco",
                    type: "Ortoftalik",
                    description: "Genel amaçlı pastel"
                  },
                  {
                    name: "Polijel 208 pastel - pro - eco",
                    type: "Ortoftalik",
                    description: "Genel amaçlı pastel"
                  },
                  {
                    name: "Polijel 211 pastel - pro - eco",
                    type: "Ortoftalik NPG",
                    description: "Performans pastel"
                  },
                  {
                    name: "Polijel 212 pastel - pro - eco",
                    type: "Ortoftalik NPG Akrilik",
                    description: "Performans pastel"
                  },
                  {
                    name: "Polijel 210 pastel - pro - eco",
                    type: "İzoftalik NPG",
                    description: "Performans pastel"
                  },
                  {
                    name: "Polijel 213 pastel - pro - eco",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans pastel"
                  },
                  {
                    name: "Polijel 214 pastel - pro - eco",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans pastel"
                  },
                  {
                    name: "Polijel 215 pastel - pro - eco",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans pastel, marin"
                  }
                ]
              },
              {
                title: "Kimyasal maddelere dayanıklı",
                rows: [
                  {
                    name: "Polijel 213",
                    type: "İzoftalik NPG Akrilik",
                    description: "Yüksek performans"
                  },
                  {
                    name: "Polijel 271",
                    type: "Vinilester",
                    description: "Yüksek kimyasal dayanımlı"
                  },
                  {
                    name: "Polijel 291",
                    type: "BPA",
                    description: "Yüksek ısıl ve kimyasal dayanımlı"
                  }
                ]
              },
              {
                title: "Bariyerkot",
                rows: [
                  {
                    name: "Polijel BC",
                    type: "Vinilester",
                    description: "Yüksek hidrolitik dayanımlı"
                  },
                  {
                    name: "Polijel BC-782",
                    type: "Vinilester",
                    description: "Yüzey kalitesi yüksek, esnek"
                  }
                ]
              },
              {
                title: "Alev ilerletmeyen",
                rows: [
                  {
                    name: "Polijel 250",
                    type: "HET",
                    description: "Alev ilerletmeyen dolgulu gri"
                  },
                  {
                    name: "Polijel 254 Gri",
                    type: "HET",
                    description: "Alev ilerletmeyen dolgulu gri"
                  },
                  {
                    name: "Polijel 340-FR",
                    type: "Ortoftalik",
                    description: "Alev ilerletmeyen halojensiz dolgulu yüksek performans"
                  },
                  {
                    name: "Polijel 258",
                    type: "HET",
                    description: "Alev ilerletmeyen dolgulu yüksek performans gri"
                  }
                ]
              }
          ]
        },









        {
          label: "Turkuaz",
          accordions: [
            {
                title: "Genel amaçlı jelkotlar",
                rows: [
                  {
                    name: "TP600 S-T",
                    type: "Ortoftalik",
                    description: "Püskürtme"
                  },
                  {
                    name: "TP600 S-W",
                    type: "Ortoftalik",
                    description: "Püskürtme"
                  },
                  {
                    name: "TP600 H-T",
                    type: "Ortoftalik",
                    description: "Fırça ile"
                  },
                  {
                    name: "TP600 H-W",
                    type: "Ortoftalik",
                    description: "Fırça ile"
                  }
                ]
              },
              
              {
                title: "Performans tipi jelkotlar",
                rows: [
                  {
                    name: "TP900 S-T",
                    type: "Ortoftalik / Neopentil glikol (NPG)",
                    description: "Püskürtme"
                  },
                  {
                    name: "TP900 S-W",
                    type: "Ortoftalik / Neopentil glikol (NPG)",
                    description: "Püskürtme"
                  },
                  {
                    name: "TP900 H-T",
                    type: "Ortoftalik / Neopentil glikol (NPG)",
                    description: "Fırça"
                  },
                  {
                    name: "TP900 H-W",
                    type: "Ortoftalik / Neopentil glikol (NPG)",
                    description: "Fırça"
                  },
                  {
                    name: "TP986 S-T",
                    type: "İzoftalik",
                    description: "Püskürtme"
                  },
                  {
                    name: "TP986 S-W",
                    type: "İzoftalik",
                    description: "Püskürtme"
                  },
                  {
                    name: "TP986 H-T",
                    type: "İzoftalik",
                    description: "Fırça ile"
                  },
                  {
                    name: "TP986 H-W",
                    type: "İzoftalik",
                    description: "Fırça ile"
                  }
                ]
              },
              {
                title: "Yüksek performans tipi jelkotlar",
                rows: [
                  {
                    name: "TP700 S-T",
                    type: "İzoftalik / Neopentil glikol (NPG)",
                    description: "Püskürtme"
                  },
                  {
                    name: "TP700 S-W",
                    type: "İzoftalik / Neopentil glikol (NPG)",
                    description: "Püskürtme"
                  },
                  {
                    name: "TP700 H-T",
                    type: "İzoftalik / Neopentil glikol (NPG)",
                    description: "Fırça ile"
                  },
                  {
                    name: "TP700 H-W",
                    type: "İzoftalik / Neopentil glikol (NPG)",
                    description: "Fırça ile"
                  },
                  {
                    name: "TP1000 S-T",
                    type: "İzoftalik / Neopentil glikol (NPG)",
                    description: "Püskürtme"
                  },
                  {
                    name: "TP1000 S-W",
                    type: "İzoftalik / Neopentil glikol (NPG)",
                    description: "Püskürtme"
                  },
                  {
                    name: "TP1000 H-T",
                    type: "İzoftalik / Neopentil glikol (NPG)",
                    description: "Fırça ile"
                  },
                  {
                    name: "TP1000 H-W",
                    type: "İzoftalik / Neopentil glikol (NPG)",
                    description: "Fırça ile"
                  },
                  {
                    name: "TP1080 S-T",
                    type: "İzoftalik / Neopentil glikol (NPG)",
                    description: "Püskürtme"
                  },
                  {
                    name: "TP1080 S-W",
                    type: "İzoftalik / Neopentil glikol (NPG)",
                    description: "Püskürtme"
                  },
                  {
                    name: "TP1000 H-T",
                    type: "İzoftalik / Neopentil glikol (NPG)",
                    description: "Fırça ile"
                  },
                  {
                    name: "TP1000 H-W",
                    type: "İzoftalik / Neopentil glikol (NPG)",
                    description: "Fırça ile"
                  }
                ]
              }
           ]
        }
      ]
    },







   
  ];
  
  export default data;
  