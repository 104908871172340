const data = [
    {
      sectionLabel: "Standart, esnek, performans tipi ve takviyeli yapıştırıcı pastalar",
      tabs: [
        {
          label: "Boytek",
          accordions: [
            {
              title: "Yapıştırıcı pastalar",
              rows: [
                {
                  name: "Politix Standart",
                  type: "Ortoftalik",
                  description: "Rijit yapıştırıcı"
                },
                {
                  name: "Politix Flekso",
                  type: "İzoftalik",
                  description: "Esnek yapıştırıcı"
                },
                {
                  name: "Politix VE",
                  type: "Vinilester",
                  description: "Yüksek performans yapıştırıcı"
                },
                {
                  name: "Politix Fibro",
                  type: "İzoftalik Fiber",
                  description: "Elyaf takviyeli yapıştırıcı"
                },
                {
                  name: "Politix Fibro Marin",
                  type: "Ortoftalik Fiber",
                  description: "Esnek, elyaf takviyeli yapıştırıcı"
                }
              ]
            }
          ]
          
        },
        {
            label: "İlkester",
            accordions: [
                {
                  title: "Yapıştırıcı pastalar",
                  rows: [
                    {
                      name: "TK-501BP",
                      description: "Mikronize cam elyaf takviyeli yüksek performans yapıştırıcı pasta"
                    },
                    {
                      name: "TIX-081",
                      description: "Cam elyaf takviyeli sıvı laminat yapıştırıcı pasta"
                    },
                    {
                      name: "TK-TIX",
                      description: "Ortoftalik doymamış polyester reçine bazlı tix"
                    }
                  ]
                }
              ]
          },
          {
            label: "Poliya",
            accordions: [
                {
                  title: "Yapıştırıcı pastalar",
                  rows: [
                    {
                      name: "Politix Standart",
                      type: "Ortoftalik",
                      description: "Rijit yapıştırıcı"
                    },
                    {
                      name: "Politix Flekso",
                      type: "İzoftalik",
                      description: "Esnek yapıştırıcı"
                    },
                    {
                      name: "Politix VE",
                      type: "Vinilester",
                      description: "Yüksek performans yapıştırıcı"
                    },
                    {
                      name: "Politix Fibro",
                      type: "İzoftalik Fiber",
                      description: "Elyaf takviyeli yapıştırıcı"
                    },
                    {
                      name: "Politix Fibro Marin",
                      type: "Ortoftalik Fiber",
                      description: "Esnek, elyaf takviyeli yapıştırıcı"
                    }
                  ]
                }
              ]
              
          },
      ]
    },







  ];
  
  export default data;
  