import React, { useEffect } from 'react';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


import image1 from '../img/usage/1.jpg';
import image2 from '../img/usage/2.jpg';
import image3 from '../img/usage/3.jpg';
import image4 from '../img/usage/4.jpg';
import image5 from '../img/usage/5.jpg';
import image6 from '../img/usage/6.jpg';
import image7 from '../img/usage/7.jpg';
import image8 from '../img/usage/8.jpg';
import image9 from '../img/usage/9.jpg';
import image10 from '../img/usage/10.jpg';
import image11 from '../img/usage/11.jpg';
import image12 from '../img/usage/12.jpg';
import image13 from '../img/usage/13.jpg';
import image14 from '../img/usage/14.jpg';
import image15 from '../img/usage/15.jpg';
import image16 from '../img/usage/16.jpg';
import image17 from '../img/usage/17.jpg';
import image18 from '../img/usage/18.jpg';
import image19 from '../img/usage/19.jpg';
import image20 from '../img/usage/20.jpg';
import image21 from '../img/usage/21.jpg';
import imge from '../img/33.jpg'


const Subpage1 = ({ img }) => {
  const cardGroups = [
    {
      title: "Otomotiv",
      cards: [
        {
          id: 1,
          title: "Karavan üretimi",
          content: `50 yılı aşkın süredir rekreasyon aracı (RV) sektöründe CTP kompozit malzemeler kullanılıyor.\n \n Kolay şekil verilen CTP levhalar tasarım ve imalatta esneklik avantajı sunuyor. Hafiflik imalatta ve kullanımda büyük enerji tasarrufu sağlıyor. Çarpma enerjisini çelikten 7 - 8 kat daha iyi emen termoplastik kompozitler güvenliği de yeniden tanımlıyor.`,
          image: image1,
        },
        {
          id: 2,
          title: "Treyler ve kamyon kasaları",
          content: `Treyler ve diğr ticari araç kasalarında metal levhaların yerini hızla CTP kompozit levhalar aldı.\n \n Kolay işlenebilen kompozit malzemeler imalat süresini kısaltıyor, kârlılığı artırıyor. Kasaların hafif ve dayanıklı olması sayesinde taşımacı firmalar daha verimli çalışıyor. Panellerin iç yüzünde kullanılan hijyenik CTP levhalar en yüksek gıda güvenliği standartlarına uyum sağlıyor.`,
          image: image2,
        },
        {
          id: 3,
          title: "Yolcu taşımacılığı",
          content: `Otobüslerle yaygınlaşan CTP levha kullanımı yolcu taşımacılığındaki diğer araçlara da yayılıyor.\n \n Yüksek dayanım, hafiflik ve boya tutma kalitesi dış tavanda CTP levhaları cazip kılıyor. CTP kompozitlerdeki gelişmeler yeni nesil yolcu taşıma araçlarının kaportalarında kullanmaya olanak sağlıyor; yolcu ve bagaj kabini uygulamaları da günden güne yaygınlaşıyor.`,
          image: image3,
        },
      ],
    },
    {
      title: "İnşaat, altyapı, mimarlık",
      cards: [
        {
          id: 4,
          title: "Dış ve iç mimarlık",
          content: `CTP kompozit malzemeler dış ve iç mimarlık için tasarım sınırlarını ortadan kaldırıyor.\n \nSınırsız renk ve çok geniş doku seçenekleri olan CTP levhalar ışık geçirebiliyor. Hafiflik, güvenlik ve yüksek dayanım gibi özellikleriyle öne çıkan CTP levhalar dünyanın ünlü iç ve dış mimarlık projelerinin malzemeleri arasında yer alıyor.`,
          image: image4,
        },
        {
          id: 5,
          title: "Kalıplama",
          content: `CTP kompozit kalıplar, modern inşaat şantiyelerinin aranan malzemeleri arasına girdi.\n \nKorozyona, kimyasal reaksiyonlara çok dirençli CTP kalıplar hafif ama yüksek mukavemetli yapılarıyla lojistik ve işçilikte büyük avantaj sağlıyor. Çimentoya yapışmayan kalıplar çok kolay tamir ediliyor. Karmaşık şekilleri kusursuz çıkartan CTP kalıplar birkaç yüz kez kullanılıp her defasında pürüzsüz brüt beton yüzey kalitesi sağlıyor.`,
          image: image5,
        },
        {
          id: 6,
          title: "Donatılar, takviyeler, altyapı ürünleri",
          content: `Üstyapı ve altyapının inşa edilmesinde CTP kompozitler her geçen gün artarak kullanılıyor.\n \nKorozyona ve kimyasal etkilere dayanıklı, su geçirmeyen, yapı yükünü ağırlaştırmayan, kolay işlenen CTP kompozitler betonarme filizi, temel ve taşıyıcı kolon güçlendiricisi gibi önemli işlevler üstleniyor. CTP kompozitlerle komple köprüler, geçitler veya yapı bölümleri de inşa ediliyor.`,
          image: image6,
        }
      ],
    },
    {
      title: "Havuz, tank",
      cards: [
        {
          id: 7,
          title: "Endüstriyel havuzlar",
          content: `Endüstriyel havuzların yapımında CTP kompozitler; vinil liner, beton vb. seçeneklere göre önemli avantajları nedeniyle tercih ediliyor.\n \nJelkotlu yüzeyin pürüzsüzlüğü yosun ve diğer mikro organizmaların üremesini baskılayarak çok daha kolay dezenfeksiyon sağlıyor. Hafif, dayanıklı ve büyütülebilen CTP kompozit havuzlar çok düşük işletme masrafıyla yıllar boyu hizmet veriyor. Gerektiğinde çok kolay onarılabilen CTP havuzlara işletmenin ihtiyacı olan her türlü tesisat ve donanım kolayca uygulanıyor.`,
          image: image7,
        },
        {
          id: 8,
          title: "Endüstriyel tanklar",
          content: `CTP kompozitler kimya endüstrisinde agresif kimyasalların depolanması için kullanılıyor.\n \n Gıda endüstrisinde sirke, salamura, şarap vb. depolama ve dinlendirme ihtiyaçlarını karşılayan CTP kompozit tanklar hafif ve güvenli yapıları, pürüzsüz jelkot iç yüzeyleriyle kolay işletme avantajı sağlıyor. \n \nCTP kompozit tanklar büyük hacimde su depolama ihtiyacı için de çok tercih ediliyor.`,
          image: image8,
        },
        {
          id: 9,
          title: "Ev havuzları",
          content: `En çabuk kurulum ve en düşük işletme maliyetli çözüm olan CTP kompozit havuzlar yosun barındırmayan yapılarıyla kimyasal kullanmadan havuz keyfi yaşatıyor.\n \nBasamak, oturma yeri, mağara, güneşlenme bölümü gibi istekler de eklenerek kalıplanan CTP kompozit havuzlar korozyon ve iklim koşullarından etkilenmiyor. Bakım ve yenileme gerektirmeyen CTP havuzlar, istenen renk ve desenlerde tasarlanabiliyor.`,
          image: image9,
        }
      ],
    },
    {
      title: "Denizcilik",
      cards: [
        {
          id: 10,
          title: "Tekne yapımı",
          content: `CTP kompozitler 1942’den beri tekne yapımında çok güvenilir malzemeler arasında yer alıyor.\n \nDünya denizlerinde dolaşan yüzbinlerce CTP kompozit tekne su geçirmez, monokok, hafif, ahşaptan dayanıklı, ucuz ve kolay bakım yapılan özellikleriyle tercih ediliyorlar. Her geçen gün tekne yapım sektöründe ağırlığını artıran CTP kompozitler, rekreasyon amaçlı dünya tekne pazarının % 82’sini ele geçirmiş bulunuyor.`,
          image: image10,
        },
        {
          id: 11,
          title: "İç ve dış donatılar, radomlar",
          content: `CTP kompozitler metal ya da ahşaptan inşa edilen teknelerde bile çeşitli iç ve dış donatılarda kullanılıyor. \n \n Ticari veya rekreasyon amaçlı teknelerin iletişim ve navigasyon antenleri CTP kompozit radomlarla korunuyor. \n \n Hibrid köprüler ve radomlar gibi sürekli göz önünde olan unsurlar dışında birçok iç ve dış parça ve komple bölümlerde CTP kompozitlerin avantajlarından yararlanılıyor.`,
          image: image11,
        },
        {
          id: 12,
          title: "Askeri gemiler ve donatılar",
          content: `CTP kompozitlerin anti manyetik ve anti radar uygunlukları, düşük veya sıfır bakım gerektirmeleri, kolay onarılabilmeleri, hafif ama çok dayanıklı olmaları, onları askeri denizcilikte vazgeçilmez kılıyor. \n \n Modüler, monokok üretime çok yatkın olan CTP kompozitler askeri tekne donatılarında büyük avantaj sağlıyor. \n \n Yeni dönemde hafif ve orta tonajlı birçok askeri tekne bütünüyle CTP kompozitlerle imal ediliyor.`,
          image: image12,
        }
      ],
    },
    {
      title: "Enerji",
      cards: [
        {
          id: 13,
          title: "Rüzgar türbinleri",
          content: `1980’lerden sonraki gelişmeler rüzgar türbini endüstrisinde CTP kompozitlerin yerini vazgeçilmez kılıyor.\n \n2012’den itibaren dünya çapında CTP kompozitlerin hacmen %62’den fazlası rüzgar türbini üretiminde kullanılmaya başladı. CTP kompozit türbin kanatları hafif ve dayanıklı yapılarıyla büyük kapasite verimliliği sağlıyor. Tuz ve asit korozyonuna dirençli, tüm doğal artık ve kalıntılarından kolayca arındırılabilen CTP türbinler büyük tasarım özgürlüğüyle üretilebiliyor.`,
          image: image13,
        },
        {
          id: 14,
          title: "Santral boru ve baca sistemleri",
          content: `Termoelektrik santrallerinde CTP kompozitlerin kullanımı her geçen gün artıyor.\n \n Kimyasal, termal ve mekanik stres testleri CTP kompozit baca, boru ve kanal sistemlerinin ideal özellikler taşıdığını gösteriyor. Avrupa ve Amerika’nın yeni nesil termoelektrik tesislerinin hemen tümünde CTP kompozitlerden yararlanılıyor.\n \n Desülfürizasyon gibi süreçlerde de asit, klor, flor gibi agresif kimyasallara dirençli, hafif, uzun ömürlü CTP kompozit boru, baca ve kanallar kullanılıyor.`,
          image: image14,
        },
        {
          id: 15,
          title: "Soğutma kuleleri",
          content: `Orta büyüklükteki enerji tesisleri için yapılan dikme kombine soğutma kulelerinde avantajları ortaya çıkan CTP kompozitler paket soğutma kulelerinde ve büyük kulelerde de yoğunlukla kullanılmaya başlandı.\n \nYüksek korozyon direnci dolayısıyla ilgi çeken CTP kompozitler kurulum ve bakım maliyetleri açısından betonarme ve diğer tekniklere göre büyük avantajlar sağlıyor.`,
          image: image15,
        }
      ],
    },
    {
      title: "Demiryolu, raylı sistemler",
      cards: [
        {
          id: 16,
          title: "Ana hat trenleri",
          content: `Yeni nesil ana hat trenlerinin enerji verimliliği ve balistik, aerodinamik ihtiyaçları, gelişmiş CTP ve diğer kompozitleri çok önemli malzemeler arasına sokuyor.\n \nBakım masraflarını, katar ağırlığını ve enerji ihtiyacını önemli ölçüde düşüren gelişmiş CTP kompozitler tavan, iç bölmeler, yan kaporta gibi alanlarda her geçen gün artan yoğunlukta kullanılıyor.`,
          image: image16,
        },
        {
          id: 17,
          title: "Demiryolu hat ve sistemleri",
          content: `Ana ve tâli demiryolu hatları ve sistemleri CTP kompozitlere geniş uygulama alanları sunmaya başladı.\n \n CTP kompozitlerden yapılan veya CTP ile takviye edilen traversler, izolatörler, sinyalizasyon levhaları gibi hat ekipmanlarının yanısıra komple köprüler, yolcu platformları, peronlar gibi büyük üniteler de CTP kompozitlerle üretiliyor.`,
          image: image17,
        },
        {
          id: 18,
          title: "Hafif raylı sistemler, metrolar",
          content: `Şehir yerüstü ve yeraltı raylı sistemlerinde CTP kompozitlerin üstün özellikleri çok işe yarıyor. \n \n Tren ve vagonların kaporta, tavan ve iç tasarımında kullanılan CTP kompozitler ayrıca hat ve tünel altyapılarında da önemli işlevler üstleniyor. Yüksek mukavemetli, anti korozif CTP kompozitler yeraltı ve yerüstü takviyelerde, travers ve izolatörlerde, köprülerde, tünel takviyelerinde ve tünel duvar kaplamalarında da kullanılıyor.`,
          image: image18,
        }
      ],
    },
    {
      title: "Demiryolu, raylı sistemler",
      cards: [
        {
          id: 19,
          title: "Uçak gövde ve kanatları",
          content: `Küçük uçakların performans ve güvenliklerini artırırken maliyetlerini düşürmek için CTP kompozitler kullanılıyor.\n \n Dengeli yük dağılımı, hafiflik, yüksek mukavemet, yıldırım güvenliği, yüksek yalıtım gibi özellikleriyle metallerden ayrılan CTP kompozitler sadece gövde ve kanatlarda değil seperasyonlarda, yakıt tanklarında, bagaj bölmelerinde de kullanılıyor. \n \n CTP kompozitlerdeki ilerlemeler büyük ticari uçak gövdelerinde de kullanım için önemli fırsatlar hazırlıyor.`,
          image: image19,
        },
        {
          id: 20,
          title: "Uçak kabinlerinin içi",
          content: `2017 itibarıyla dünya havacılık endüstrisinde kabin parça ve bölmelerinin yaklaşık %52’si CTP kompozitlerden imal ediliyor. \n \n İç kaplamalar, başüstü dolapları, tuvaletler, evcil hayvan ve bagaj bölmeleri vb. birçok alanda CTP kompozit kullanılıyor. Yüksek mukavemet, hafiflik ve dengeli yük dağılımının yanısıra yangın güvenliği, yalıtım, renk ve şekil esnekliği gibi özellikler CTP kompozitleri kabin ekipmanlarının en önemli malzemeleri arasında bulunduruyor.`,
          image: image20,
        },
        {
          id: 21,
          title: "Helikopter ve iş jetleri",
          content: `CTP kompozitlerdeki yenilik ve ilerlemeler iş jeti ve helikopter üretiminde CTP payını gitgide artırıyor. \n \n Menzil ve güvenlik artışının yanısıra işletme maliyetlerinde de önemli düşüşler sağlayan CTP kompozitler gövde, kanopi ve radom, motor kaportası, kanat, kuyruk ve iç kabin malzemesi olarak artan oranlardo kullanılıyor. \n \n CTP kompozitler geliştikçe yüksek maliyetli karbon fiber takviyeli kompozitlerin kullanımı da giderek azalıyor.`,
          image: image21,
        }
      ],
    },
  ];
  return (
    <div className="relative cursor-default w-full">
      <div className="relative">
      <motion.img
        src={imge}
        alt="Resim açıklaması"
        className="w-full h-4/5 object-cover"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      />
      <div className="absolute inset-0 flex items-center justify-center">
        <motion.div
          className="text-center bg-black bg-opacity-50 p-4 sm:p-8 text-white"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <h1 className="text-2xl sm:text-4xl font-bold mb-3">CTP Kompozit</h1>
          <h2 className="text-xl sm:text-3xl">Hayatın vazgeçilmez parçası</h2>
        </motion.div>
      </div>
    </div>

    
      <div className="container mx-auto mt-20 px-5 ">
        {cardGroups.map((group, index) => (
          <div key={index} className="mb-20">
            <h2 className="text-2xl bg-red-500 p-4 rounded-xl text-white font-bold mb-6 text-center">
              {group.title}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {group.cards.map((card) => (
                <Card key={card.id} card={card} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Card = ({ card }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      className="text-black w-auto h-auto bg-slate-300 rounded-3xl p-4 mt-9  shadow-lg transform transition duration-300 hover:scale-105"
    >
      <img
        src={card.image}
        alt={card.title}
        className="w-96 h-80 object-fill rounded-2xl mb-3"
      />
      <h3 className="text-xl font-bold mt-6 mb-2">{card.title}</h3>
      <p className="text-base">
        {card.content.split('\n').map((str, index) => (
          <React.Fragment key={index}>
            {str}
            <br />
          </React.Fragment>
        ))}
      </p>
    </motion.div>
  );
};

export default Subpage1;