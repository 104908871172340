import React from "react";


import TableSlider1 from "../../components/TableSlider1";
import TableSlider2 from "../../components/TableSlider2";
import TableSlider3 from "../../components/TableSlider3";
import TableSlider4 from "../../components/TableSlider4";
import SliderCards from "../../components/SliderCards";

const ElyafYatmasi = () => {

  return (
    <div className="relative w-full p-8 max-w-[88rem]  mx-auto h-full font-sans overflow-hidden">
      <h1 className="text-2xl bg-red-500 p-4 rounded-xl text-white font-bold mb-6 text-center">
        Poliya - El yatırması ve elyaf püskürtme için - Polyester Reçineler
      </h1>
     

      <TableSlider1 />
      <TableSlider2 />
      <TableSlider3 />
      <TableSlider4 />

      <SliderCards />
    </div>
  );
};

export default ElyafYatmasi;
